export class Storage {
  public static set(key: string, value: any) {
    switch (typeof value) {
      case "string":
        localStorage.setItem(key, value);
        break;
      default:
        localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public static get(key: string): any {
    const item = localStorage.getItem(key);
    if (item !== null) {
      try {
        return JSON.parse(item);
      } catch (err) {
        return item;
      }
    }
    return null;
  }

  public static remove(keys: string[]) {
    for (const key of keys) {
      localStorage.removeItem(key);
    }
  }
}
