import { Grid } from "@mui/material";
import FormInputView from "components/FormInput";
import FormPasswordInputView from "components/FormPasswordInput";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import {
  StyledFormGrid,
  StyledFormHeaderContainer,
  StyledFormHeaderGrid,
  StyledLink,
  StyledLinkGrid,
} from "../styles";
import { LoginFormProps } from "./types";

const LoginForm = ({ register, errors, control }: LoginFormProps) => {
  const theme = useTheme();

  return (
    <StyledFormGrid>
      <StyledFormHeaderContainer container direction="column">
        <StyledFormHeaderGrid item xs data-test="heading">
          <Typography
            variant={theme.typography.heading[1].regular}
            color={theme.color.grayscale[11]}
          >
            Faça suas melhores vendas com a <b>Dank!</b>
          </Typography>
        </StyledFormHeaderGrid>
      </StyledFormHeaderContainer>

      <Grid container direction="column">
        <FormInputView
          field="email"
          title="Email"
          placeholder="Digite seu email"
          register={register}
          control={control}
          errors={errors}
        />

        <FormPasswordInputView
          field="password"
          title="Senha"
          placeholder="Digite sua senha"
          register={register}
          control={control}
          errors={errors}
        />
      </Grid>

      <StyledLinkGrid container spacing={1}>
        <Grid item>
          <Typography
            variant={theme.typography.body[3].regular}
            color={theme.color.grayscale[7]}
          >
            Esqueceu sua senha?
          </Typography>
        </Grid>
        <Grid item>
          <StyledLink href="/recover-password" data-test="recover-password">
            <Typography
              variant={theme.typography.body[3].regular}
              color={theme.color.primary.default}
            >
              <b>Recuperar senha</b>
            </Typography>
          </StyledLink>
        </Grid>
      </StyledLinkGrid>
    </StyledFormGrid>
  );
};

export default LoginForm;
