import { useHistory } from "react-router";

const useCartSuccessController = () => {
  const history = useHistory();
  const handleOnClick = () => {
    history.push("/orders");
  };

  const handleGoBack = () => {
    history.replace("/stores");
  };

  return { handleOnClick, handleGoBack };
};

export default useCartSuccessController;
