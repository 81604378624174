import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
/* Theme variables */
import React from "react";
import { DefaultThemeProvider } from "theme/DefaultThemeProvider";
import "./theme/variables.css";
import "./theme/global.css";
import { DefaultMuiThemeProvider } from "theme/material/DefaultMuiThemeProvider";

import { AuthProvider } from "./contexts/auth";
import Routes from "./routes";

setupIonicReact({
  mode: "ios",
});

const App: React.FC = () => (
  <AuthProvider>
    <DefaultThemeProvider>
      <DefaultMuiThemeProvider>
        <IonApp>
          <IonReactRouter>
            <Routes />
          </IonReactRouter>
        </IonApp>
      </DefaultMuiThemeProvider>
    </DefaultThemeProvider>
  </AuthProvider>
);

export default App;
