import { useIonAlert } from "@ionic/react";
import circleCheck from "assets/svgs/icons/circle-check.svg";
import {
  SEND_MAIL_ERROR,
  SEND_MAIL_SUCCESS,
} from "commons/constants/message.constants";
import { GENERATE_PDF_ERROR } from "commons/constants/order.constants";
import { SendMailPayload } from "components/ModalSendMail/types";
import { useConfiguration } from "contexts/configuration";
import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import CartService from "services/cart.service";
import OrderService from "services/order.service";

export const useOrderCardDetailController = () => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isOpenSendMailModal, setIsOpenSendMailModal] = useState(false);
  const [isLoadingSendMailSubmit, setIsLoadingSendMailSubmit] = useState(false);

  const [presentAlert] = useIonAlert();
  const { presentToast } = useConfiguration();

  const showError = (message: string) => {
    presentAlert({
      header: "Alerta",
      message,
      buttons: ["OK"],
    });
  };

  const handleGeneratePdfToOrder = async (orderId: number) => {
    try {
      setIsLoadingSubmit(true);
      const response = await OrderService.generatePdf(orderId);

      if (response.status !== StatusCodes.OK) {
        throw new Error(GENERATE_PDF_ERROR);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const handleGeneratePdfToCart = async (cartId: number) => {
    try {
      setIsLoadingSubmit(true);
      const response = await CartService.generatePdf(cartId);

      if (response.status !== StatusCodes.OK) {
        throw new Error(GENERATE_PDF_ERROR);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const handleGeneratePdf = async (isDraft: boolean, id: number) => {
    if (isDraft) {
      await handleGeneratePdfToCart(id);
    } else {
      await handleGeneratePdfToOrder(id);
    }
  };

  const handleSendPdfOrderMail = async (id: number, data: SendMailPayload) => {
    try {
      setIsLoadingSendMailSubmit(true);
      const { to } = data;

      const response = await OrderService.sendMailPdf(id, to);

      if (response.status === StatusCodes.OK) {
        presentToast({
          message: SEND_MAIL_SUCCESS,
          duration: 1500,
          color: "success",
          icon: circleCheck,
          position: "top",
          mode: "md",
        });
      } else {
        throw new Error(SEND_MAIL_ERROR);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      handleShowModal(false);
      setIsLoadingSendMailSubmit(false);
    }
  };

  const handleSendPdfCartMail = async (id: number, data: SendMailPayload) => {
    try {
      setIsLoadingSendMailSubmit(true);
      const { to } = data;

      const response = await CartService.sendMailPdf(id, to);

      if (response.status === StatusCodes.OK) {
        presentToast({
          message: SEND_MAIL_SUCCESS,
          duration: 1500,
          color: "success",
          icon: circleCheck,
          position: "top",
          mode: "md",
        });
      } else {
        throw new Error(SEND_MAIL_ERROR);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      handleShowModal(false);
      setIsLoadingSendMailSubmit(false);
    }
  };

  const handleShowModal = (open: boolean) => {
    setIsOpenSendMailModal(open);
  };

  return {
    handleGeneratePdf,
    isLoadingSubmit,
    isOpenSendMailModal,
    handleShowModal,
    handleSendPdfOrderMail,
    isLoadingSendMailSubmit,
    handleSendPdfCartMail,
  };
};
