import { api } from "./api.service";

export default class PaymentMethodService {
  static async getMany() {
    return api.get("/payment_method/");
  }

  static async getBySupplier({
    paymentMethodId,
    supplierId,
  }: GetOneBySupplierProps) {
    const options = {
      params: {
        payment_method: paymentMethodId,
        supplier: supplierId,
      },
    };
    return api.get("/supplier_payment_method/", options);
  }
}

interface GetOneBySupplierProps {
  paymentMethodId?: number;
  supplierId?: number;
}
