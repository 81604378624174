import { IonFooter, IonLoading, IonPage } from "@ionic/react";
import { CircularProgress, Grid } from "@mui/material";
import { MASK } from "commons/utils/format";
import BackButtonHeader from "components/BackButtonHeader";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import DialogWithButtons from "components/DialogWithButtons";
import FormInputView from "components/FormInput";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import FormNumberInputView from "../../../components/FormNumberInput";
import FormSelectView from "../../../components/FormSelect";
import { StyledIonContent } from "../styles";
import StoreFormInfoView from "./components/StoreFormInfo";
import { useStoreCreationController } from "./controller";
import { storeCreateStyles, StyledContent, StyledGrid } from "./styles";
import { brazilStates } from "./util";

const StoreCreateView: React.FC = () => {
  const theme = useTheme();
  const { gridStyle, footerStyle, progress } = storeCreateStyles(theme);
  const {
    isLoading,
    isDialogOpen,
    isLoadingZipcode,
    cities,
    storeTypes,
    pageTotal,
    page,
    formTitle,
    basicForm,
    addressForm,
    contactForm,
    handleClickBack,
    handleClickNext,
    handleOnSubmit,
    handleCloseDialog,
    handleGoToDetails,
    handleGoToProducts,
    handleZipcodeChange,
  } = useStoreCreationController();

  const StoreBasicForm = () => {
    const {
      control,
      formState: { errors },
      register,
    } = basicForm;
    return (
      <Grid container direction="column" marginBottom="10px">
        <FormInputView
          field="company_name"
          title="Razão social*"
          placeholder="Insira a razão social"
          register={register}
          errors={errors}
          control={control}
        />

        <FormInputView
          field="fantasy_name"
          title="Nome fantasia*"
          placeholder="Insira o nome fantasia"
          register={register}
          control={control}
          errors={errors}
        />

        <FormInputView
          field="state_registration"
          title="Inscrição estadual*"
          placeholder="Insira a inscrição estadual"
          register={register}
          control={control}
          errors={errors}
        />

        <FormNumberInputView
          field="registration_number"
          title="CNPJ*"
          placeholder="Insira o CNPJ"
          format={MASK.CNPJ}
          register={register}
          control={control}
          errors={errors}
        />

        <FormSelectView
          field="store_type"
          title="Ramo da atividade*"
          placeholder="Selecione"
          selectOptions={storeTypes}
          register={register}
          control={control}
          errors={errors}
          isSelectingObject
        />
      </Grid>
    );
  };

  const StoreAddressForm = () => {
    const {
      control,
      formState: { errors },
      register,
    } = addressForm;
    return (
      <Grid container direction="column" marginBottom="10px">
        {isLoadingZipcode ? (
          <IonLoading isOpen={isLoadingZipcode} message={"Carregando..."} />
        ) : null}
        <FormNumberInputView
          field="zipcode"
          title="CEP*"
          placeholder="Insira o CEP da loja"
          format={MASK.ZIPCODE}
          register={register}
          control={control}
          errors={errors}
          onChange={handleZipcodeChange}
        />

        <FormSelectView
          field="state"
          title="Estado*"
          placeholder="Insira o estado onde a loja se encontra"
          selectOptions={brazilStates}
          register={register}
          control={control}
          errors={errors}
        />

        <FormSelectView
          field="city"
          title="Cidade*"
          placeholder="Insira a cidade onde a loja se encontra"
          selectOptions={cities}
          register={register}
          control={control}
          errors={errors}
        />
        <FormInputView
          field="neighborhood"
          title="Bairro*"
          placeholder="Insira o bairro da loja"
          register={register}
          control={control}
          errors={errors}
        />
        {/* TODO is required in the back end, check it */}
        <FormInputView
          field="street"
          title="Logradouro*"
          placeholder="Insira o logradouro da loja"
          register={register}
          control={control}
          errors={errors}
        />
        <Grid container spacing={1}>
          <Grid item xs>
            <FormNumberInputView
              field="number"
              title="Número*"
              placeholder="Insira o número"
              format={MASK.NUMBER}
              register={register}
              control={control}
              errors={errors}
            />
          </Grid>

          <Grid item xs>
            <FormInputView
              field="complement"
              title="Complemento"
              placeholder="Insira piso, apto"
              register={register}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const StoreContactForm = () => {
    const {
      control,
      formState: { errors },
      register,
    } = contactForm;
    return (
      <Grid container direction="column" marginBottom="10px">
        <FormInputView
          field="contact_name"
          title="Nome do contato*"
          placeholder="Insira o nome do contato da loja"
          register={register}
          control={control}
          errors={errors}
        />

        <FormNumberInputView
          field="whatsapp"
          title="Whatsapp*"
          placeholder="Insira um número de WhatsApp"
          format={MASK.CELLPHONE}
          register={register}
          control={control}
          errors={errors}
        />

        <FormNumberInputView
          field="phone"
          title="Telefone*"
          placeholder="Insira um número de telefone"
          format={MASK.CELLPHONE}
          register={register}
          control={control}
          errors={errors}
        />

        <FormInputView
          field="email"
          title="Email comercial*"
          placeholder="Insira um email"
          register={register}
          control={control}
          errors={errors}
        />

        <FormInputView
          field="admin_email"
          title="Email administrativo (opcional)"
          placeholder="Insira um email"
          register={register}
          control={control}
          errors={errors}
        />

        <FormInputView
          field="billing_email"
          title="Email financeiro (opcional)"
          placeholder="Insira um email"
          register={register}
          control={control}
          errors={errors}
        />
      </Grid>
    );
  };

  // TODO move to component, but check how to make validation works
  const StoreForms = () => {
    switch (page) {
      case 0:
        return <StoreBasicForm />;
      case 1:
        return <StoreAddressForm />;

      case 2:
        return <StoreContactForm />;
    }
    return null;
  };

  return (
    <IonPage>
      <BackButtonHeader handleCustomClick={handleClickBack} />
      <StyledIonContent>
        <StyledContent container>
          <StyledGrid item xs={12} sm={8} md={4} lg={4} xl={2}>
            <StoreFormInfoView
              title={formTitle()}
              page={page}
              pageTotal={pageTotal}
            />

            <DialogWithButtons
              showIcon
              hasAutoSizedBtns
              isOpen={isDialogOpen}
              onClose={handleCloseDialog}
              primaryButtonText="Ver loja"
              secondaryButtonText="Iniciar pedido"
              onClickPrimaryButton={handleGoToDetails}
              onClickSecondaryButton={handleGoToProducts}
              message={
                <>
                  <Typography
                    variant={theme.typography.heading[2].highlight}
                    color={theme.color.success.default}
                  >
                    <b>Loja cadastrada</b>
                  </Typography>
                  <br />
                  <Typography
                    variant={theme.typography.heading[2].regular}
                    color={theme.color.success.default}
                  >
                    com sucesso
                  </Typography>
                </>
              }
            />
            <StoreForms />
          </StyledGrid>
        </StyledContent>
      </StyledIonContent>
      <IonFooter collapse="fade" style={footerStyle}>
        {page === pageTotal - 1 ? (
          <Grid style={gridStyle}>
            <ButtonPrimaryFilled disabled={isLoading} onClick={handleOnSubmit}>
              {isLoading ? (
                <CircularProgress style={progress} size={25} />
              ) : (
                "Finalizar cadastro"
              )}
            </ButtonPrimaryFilled>
          </Grid>
        ) : (
          <Grid style={gridStyle}>
            <ButtonPrimaryOutlined onClick={handleClickNext}>
              {isLoading ? (
                <CircularProgress style={progress} size={25} />
              ) : (
                "Seguir"
              )}
            </ButtonPrimaryOutlined>
          </Grid>
        )}
      </IonFooter>
    </IonPage>
  );
};

export default StoreCreateView;
