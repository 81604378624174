import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const errorMessageStyles = (theme: DefaultTheme): MaterialStyles => ({
  mainContainer: {
    color: `${theme.color.error.default}`,
    fontSize: "12px",
    padding: "5px",
  },
});
