import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const accountHeaderStyles = (theme: DefaultTheme): MaterialStyles => ({
  cardStyle: {
    border: "none",
    boxShadow: "none",
    width: "100%",
    padding: 3,
    marginBottom: 3,
    borderBottom: `2.5px solid ${theme.color.secondary.default}`,
    borderRadius: 0,
  },
  avatar: {
    height: 80,
    width: 80,
  },
  subText: {
    lineHeight: 0.5,
  },
  textContainer: {
    width: 210,
    "@media (min-width: 400px)": {
      width: 270,
    },
  },
});
