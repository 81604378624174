import { format, isDate } from "date-fns";

export const createDateFromSimpleDate = (date: string) => {
  const [year, month, day] = date.split("-");
  return new Date(`${month}/${day}/${year} 12:00:00`);
};

export const formatDateWithoutTime = (date: string, formatter?: string) => {
  const _date = createDateFromSimpleDate(date);
  return isDate(_date) ? formatDate(_date, formatter) : null;
};

export const formatDate = (date: Date, formatter = "yyyy-MM-dd") => {
  return format(date, formatter);
};

export const formatCnpj = (cnpj: string) => {
  if (!cnpj) return;
  // Validates if provided cnpj string conforms to number string format
  if (cnpj.length !== 14 || cnpj.match(/^\d{14}$/gm) === null) return cnpj;

  const formattedCnpj = cnpj
    .replace(/\D+/g, "")
    // Identifies each group of digits and templates them with ".", "/" and "-"
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
  return formattedCnpj.length !== 18 ||
    formattedCnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/) === null
    ? cnpj
    : formattedCnpj;
};

export const formatCPForCNPJ = (identification: string) => {
  if (identification.length === 11)
    // CPF formatting
    return identification.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
  return formatCnpj(identification);
};

export const formatCurrency = (unformattedValue: number | string) => {
  const value: number | typeof NaN =
    typeof unformattedValue === "string"
      ? parseFloat(unformattedValue)
      : unformattedValue;

  return value !== NaN
    ? new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value)
    : null;
};

export const MASK = {
  CNPJ: "##.###.###/####-##",
  CPF: "###.###.###-###", // The extra digit is intentional, it lets the field change between cpf/cnpj masks depending on input length.
  ZIPCODE: "#####-###",
  CELLPHONE: "(##) #####-####",
  NUMBER: "########",
};
