import { Card, Grid } from "@mui/material";
import defaultImage from "assets/svgs/img_placeholder.svg";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { listHeaderBigStyles } from "./styles";
import { ListHeaderBigProps } from "./types";

const ListHeaderBig = ({
  imageUrl = defaultImage,
  title,
  subtitle,
  subtexts = [],
}: ListHeaderBigProps) => {
  const theme = useTheme();
  const {
    containerStyle,
    cardStyle,
    imageStyle,
    titleStyle,
    subtitleStyle,
    textAreaSyle,
  } = listHeaderBigStyles(theme);

  return (
    <Grid container sx={containerStyle}>
      <Grid item xs={12}>
        <Card sx={cardStyle}>
          <Grid container spacing={0.5} direction="row">
            <Grid item>
              <img src={imageUrl} style={imageStyle} />
            </Grid>
            <Grid item sx={textAreaSyle}>
              <Grid item>
                <Typography
                  style={titleStyle}
                  variant={theme.typography.body[1].highlight}
                >
                  {title}
                </Typography>

                <Typography
                  style={subtitleStyle}
                  variant={theme.typography.body[4].regular}
                >
                  {subtitle}
                </Typography>
              </Grid>
              {subtexts.length ? (
                <Grid item mt={1.5}>
                  {subtexts.map((subtext, key) =>
                    subtext ? (
                      <Typography
                        key={key}
                        style={subtitleStyle}
                        variant={theme.typography.body[4].regular}
                      >
                        {subtext}
                      </Typography>
                    ) : null
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ListHeaderBig;
