import { Grid } from "@mui/material";
import CustomStepper from "components/CustomStepper";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { storeFormInfoStyles } from "./styles";
import { StoreFormInfo } from "./types";

const StoreFormInfoView = ({ title, page, pageTotal }: StoreFormInfo) => {
  const theme = useTheme();
  const styles = storeFormInfoStyles();

  return (
    <Grid style={styles.mainContainer}>
      <Grid container style={styles.headerContainer}>
        <Grid item xs>
          <Typography
            variant={theme.typography.subheading[3].regular}
            color={theme.color.grayscale[7]}
          >
            Nova Loja
          </Typography>
        </Grid>
        <Grid item xs style={styles.stepperContainer}>
          <CustomStepper activeStep={page} numberOfSteps={pageTotal} showInfo />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item xs>
          <Typography
            variant={theme.typography.heading[2].highlight}
            color={theme.color.grayscale[9]}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography
            variant={theme.typography.body[4].regular}
            color={theme.color.grayscale[7]}
          >
            Os campos marcados com (*) são obrigatórios
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreFormInfoView;
