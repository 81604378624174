import ChubbsNotFound from "../../assets/svgs/chubbs/not-found.svg";
import ChubbsSelected from "../../assets/svgs/chubbs/selected.svg";

export const ChubbsVariants = Object.freeze({
  "not-found": {
    message: "Não encontramos resultados para sua busca.",
    image: ChubbsNotFound,
  },
  selected: {
    message:
      "Os resultados de produtos irão aparecer depois de selecionar uma categoria",
    image: ChubbsSelected,
  },
});

type ChubbsVariantOptions = keyof typeof ChubbsVariants;

export interface ChubbsProps {
  variant: ChubbsVariantOptions;
  customMessage?: string;
  isCentered?: boolean;
}
