import Grid from "@mui/material/Grid";
import refreshIcon from "assets/svgs/icons/refresh.svg";
import DialogWithButtons from "components/DialogWithButtons";
import Typography from "components/Typography";
import { Fragment } from "react";
import { useTheme } from "styled-components";
import { globalStyles } from "theme/global.styles";

import { StoreInfoBlockProps } from "../../types";
import { useStoreInfoController } from "./controller";
import { storeInfoBlockStyles, StyledLink } from "./styles";

const StoreInfoBlockView = ({
  store,
  isOutFocus = false,
}: StoreInfoBlockProps) => {
  const theme = useTheme();
  const {
    isChangeClientDialogOpen,
    isSubmiting,
    handleChangeClient,
    handleDoChangeClient,
    handleCloseChangeClientDialog,
  } = useStoreInfoController();
  const { textAlignRight } = globalStyles();
  const styles = storeInfoBlockStyles(theme, isOutFocus);

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={styles.mainContainer}
      >
        <Grid container item direction="column" xs={8}>
          <Grid item>
            <Typography
              color={theme.color.grayscale[10]}
              variant={theme.typography.body[2].highlight}
            >
              {store.fantasy_name}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              variant={theme.typography.body[5].regular}
              color={theme.color.grayscale[8]}
            >
              Código: {store.registration_number}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} style={textAlignRight}>
          <StyledLink href="" onClick={handleChangeClient}>
            Alterar cliente
          </StyledLink>
        </Grid>
      </Grid>

      <DialogWithButtons
        isSubmiting={isSubmiting}
        showIcon
        isOpen={isChangeClientDialogOpen}
        onClose={handleCloseChangeClientDialog}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, alterar"
        onClickPrimaryButton={handleCloseChangeClientDialog}
        onClickSecondaryButton={handleDoChangeClient}
        icon={<img src={refreshIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].highlight}
              color={theme.color.information.default}
            >
              <b>Você quer alterar o cliente?</b>
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              O seu carrinho atual será salvo como rascunho na aba de pedidos.
            </Typography>
          </>
        }
      />
    </Fragment>
  );
};

export default StoreInfoBlockView;
