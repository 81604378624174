import { REQUIRED_FIELD } from "commons/constants/form.message.constants";
import { NOTIFICATION_TYPES } from "commons/types/auth.enums";
import { SelectedSupplier } from "contexts/cart/types";
import * as Yup from "yup";

export const saveCartNotification = {
  message:
    "Não esqueça de salvar o pedido como rascunho para evitar perda de itens.",
  type: NOTIFICATION_TYPES.WARNING,
};

export const cartValidationSchema = Yup.object().shape({
  paymentDate: Yup.string().trim().required(REQUIRED_FIELD),
  observation: Yup.string().trim().required(REQUIRED_FIELD),
});

export const validateSelectedSuppliers = (
  selectedSuppliers: SelectedSupplier[]
) => {
  let isValid = true;

  selectedSuppliers.every((supplier) => {
    if (!supplier.selectedPaymentMethod) {
      throw new Error(
        `Fabrica "${supplier.fantasy_name}" não possui forma de pagamento selecionada`
      );
    }
    if (supplier.supplierTotal < Number(supplier.minimum_order_total)) {
      isValid = false;
      return false;
    }
    return true;
  });
  return isValid;
};
