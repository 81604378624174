export const ORDER_CREATE_ERROR =
  "Ocorreu um erro ao enviar o pedido, revise os dados do carrinho.";
export const ORDER_UPDATE_SUCCESS = "Pedido alterado com sucesso!";

export const ORDER_STATUS_CHANGE_SUCCESS = "Status alterado com sucesso!";
export const ORDER_STATUS_CHANGE_ERROR =
  "Ocorreu um erro ao alterar o status do pedido, revise os dados do carrinho.";

export const ORDER_CANCEL_ERROR =
  "Ocorreu um erro ao alterar cancelar o pedido, entre em contato com o adminsitrador.";

export const GENERATE_PDF_ERROR =
  "Ocorreu um erro ao gerar o pdf, tente novamente.";
