import { MaterialStyles } from "commons/types/generics/materialStyles";

export const productDescriptionStyles = (): MaterialStyles => ({
  descriptionCard: {
    width: "100%",
    padding: "20px",
    boxShadow: "unset",
  },
  descriptionContent: { padding: "11px 0" },
});
