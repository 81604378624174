import { IonIcon, IonToolbar } from "@ionic/react";
import styled from "styled-components";

import { BackButtonLinkProps } from "./types";

export const StyledIonToolbar = styled(IonToolbar)`
  padding: 4px 4px;
  --min-height: 48px;
`;

export const StyledIonIcon = styled(IonIcon)`
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 4px;
`;

export const BackButtonLink = styled.a<BackButtonLinkProps>`
  font-size: ${({ variant }) => variant.fontSize}px;
  font-weight: ${({ variant }) => variant.fontWeight};
  line-height: ${({ variant }) => variant.lineHeight}px;
  color: ${({ color }) => color};
  text-decoration: none;
`;
