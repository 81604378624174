import styled from "@emotion/styled";
import { FormControl, InputBase } from "@mui/material";
import { DefaultTheme, useTheme } from "styled-components";

export const StoreCitySelectInput = styled(InputBase)(() => {
  const theme = useTheme();

  return {
    "& .MuiInputBase-input": {
      backgroundColor: theme.color.neutralLight.default,
      borderRadius: "4px",
      color: theme.color.grayscale[6],
      ...theme.typography.body[2].regular,
      lineHeight: "30px",
      padding: "6px 55px !important",
      width: "100%",
      textAlign: "left",
      boxShadow:
        "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.color.neutralLight.default,
    },
    "& .MuiSelect-nativeInput": {
      minWidth: "0px !important",
    },
    "&.Mui-focused": {
      backgroundColor: theme.color.neutralLight.default,
    },
  };
});

export const StyledFormControlContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const StoreCitySelectFormControlStyled = styled(FormControl)`
  display: flex;
  padding: 8px 18px;
  width: 100%;
`;

export const useStyles = (theme: DefaultTheme) => ({
  input: {
    "&::placeholder": {
      color: "gray",
    },
  },

  inputTextAutocomplete: {
    backgroundColor: "#FFF",
    borderRadius: "4px",
    outline: "none",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  },

  sx: {
    height: 44,
    "& .MuiOutlinedInput-root": {
      paddingX: "40px",
      paddingY: "4px",
      color: theme.color.grayscale[8],
    },
    input: {
      "&::placeholder": {
        color: theme.color.grayscale[8],
        fontWeight: "400px",
        opacity: 0.7,
      },
    },
  },
});
