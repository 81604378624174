import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { Button, Grid } from "@mui/material";
import removeIcon from "assets/svgs/icons/trash.svg";
import trashBinIcon from "assets/svgs/trash_bin.svg";
import Chubbs from "components/Chubbs";
import DialogWithButtons from "components/DialogWithButtons";
import Typography from "components/Typography";
import { supplierCardStyles } from "pages/cart/components/SupplierCard/styles";
import { orderStyles, StyledSearchbar } from "pages/orders/styles";
import { SEGMENT_OPTIONS } from "pages/orders/types";
import { Fragment } from "react";
import { useTheme } from "styled-components";

import OrderCardView from "../OrderCard";
import { useDraftController } from "./controller";
import { DraftListProps } from "./types";

const DraftListView = ({ handleGoToDetails }: DraftListProps) => {
  const theme = useTheme();
  const { section } = orderStyles();
  const { removeButton, icon } = supplierCardStyles();
  const {
    drafts,
    search,
    isLoading,
    isInfiniteDisabled,
    isModalOpen,
    handleOpenDeleteModal,
    handleLoadData,
    handleSearchText,
    handleClearText,
    handleCloseDeleteModal,
    handleDeleteDraft,
  } = useDraftController();

  if (isLoading) {
    return null;
  }
  return (
    <Fragment>
      <DialogWithButtons
        showIcon
        isDangerAction
        hasAutoSizedBtns
        isOpen={isModalOpen}
        onClose={handleCloseDeleteModal}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, excluir"
        onClickPrimaryButton={handleCloseDeleteModal}
        onClickSecondaryButton={handleDeleteDraft}
        icon={<img src={removeIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].regular}
              color={theme.color.error.default}
            >
              Deseja <b>excluir</b> o rascunho?
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              Você não conseguirá recuperar os itens e dados do pedido do
              carrinho.
            </Typography>
          </>
        }
      />

      <Grid container sx={section}>
        <Grid container sx={section}>
          <Grid item>
            <StyledSearchbar
              mode="md"
              value={search}
              onIonChange={(e) => handleSearchText(e.detail.value || "")}
              onIonClear={() => handleClearText()}
              placeholder="Buscar loja"
              debounce={2000}
            />
          </Grid>
        </Grid>

        {drafts.length ? (
          drafts.map((order) => (
            <Grid key={order.id} item m={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <OrderCardView
                    key={order.id}
                    {...order}
                    handleGoToDetails={() => handleGoToDetails(order.id)}
                  />
                </Grid>
                <Grid
                  item
                  my={1}
                  onClick={() => handleOpenDeleteModal(order.id)}
                >
                  <Grid container alignItems="center">
                    <Grid item sx={icon}>
                      <img src={trashBinIcon} />
                    </Grid>
                    <Grid item>
                      <Button color="error" style={removeButton}>
                        <Typography
                          variant={theme.typography.body[4].highlight}
                        >
                          Excluir rascunho
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <Chubbs variant="not-found" />
        )}

        <IonInfiniteScroll
          onIonInfinite={handleLoadData}
          threshold="1%"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Carregando..."
          />
        </IonInfiniteScroll>
      </Grid>
    </Fragment>
  );
};

export default DraftListView;
