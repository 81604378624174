import Grid from "@mui/material/Grid";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { RecoverSectionTitleProps } from "./types";

const RecoverSectionTitleView = ({
  shouldAddSpace = false,
}: RecoverSectionTitleProps) => {
  const theme = useTheme();
  return (
    <Grid item xs width="100%" mt={shouldAddSpace ? "15px" : 0}>
      <Typography
        variant={theme.typography.subheading[3].regular}
        color={theme.color.grayscale[7]}
      >
        Alteração de senha
      </Typography>
    </Grid>
  );
};

export default RecoverSectionTitleView;
