import styled from "@emotion/styled";
import Link from "@mui/material/Link";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme, useTheme } from "styled-components";

export const StyledLink = styled(Link)`
  color: ${() => useTheme().color.primary.default};
  text-decoration-color: ${() => useTheme().color.primary.default};
  font-weight: 600;
  font-size: "12px";
`;

export const storeInfoBlockStyles = (
  theme: DefaultTheme,
  isOutFocus: boolean
): MaterialStyles => ({
  mainContainer: {
    padding: "15px 20px 15px 20px",
    backgroundColor: isOutFocus
      ? theme.color.grayscale[2]
      : theme.color.neutralLight.default,
    border: `solid 1px ${
      isOutFocus ? theme.color.grayscale[4] : theme.color.grayscale[6]
    }`,
  },
});
