import { useCart } from "contexts/cart";
import { useMemo } from "react";
import { useHistory } from "react-router";

export const useCartButtonController = () => {
  const history = useHistory();
  const {
    cartData: { currentOrder },
  } = useCart();

  const buttonTitle = useMemo(() => {
    return currentOrder?.id ? "Ver pedido" : "Ver carrinho";
  }, [currentOrder?.id]);

  const handleClick = () => {
    if (currentOrder?.id) {
      history.push(`/orders/${currentOrder?.id}`);
    } else {
      history.push("/stores/cart");
    }
  };

  return { handleClick, buttonTitle };
};
