import { IonButton } from "@ionic/react";
import { StyledTypographyComponent } from "components/Typography/styles";
import { StyledTypographyProps } from "components/Typography/types";
import styled from "styled-components";

export const StyledIonButton = styled(IonButton)`
  transform: translate(-12px, 0);
`;

export const StyledTypography = styled(
  StyledTypographyComponent
)<StyledTypographyProps>`
  font-size: ${({ variant }) => variant.fontSize};
  line-height: ${({ variant }) => variant.lineHeight};
  font-weight: ${({ variant }) => variant.fontWeight};
  text-decoration: ${({ variant }) => variant.decoration || "none"};
  text-transform: none;
`;
