import Grid from "@mui/material/Grid";
import alertIcon from "assets/svgs/icons/shield-alert.svg";
import { formatCurrency } from "commons/utils/format";
import Typography from "components/Typography";
import { Fragment } from "react";
import { useTheme } from "styled-components";

import { supplierInfoStyles } from "./styles";
import { SupplierCardInfoProps } from "./types";

const SupplierInfoView = ({ supplier, total }: SupplierCardInfoProps) => {
  const theme = useTheme();
  const styles = supplierInfoStyles(theme);
  const isMinimumAchieved = total >= Number(supplier.minimum_order_total);
  const minimumPriceColor = isMinimumAchieved
    ? theme.color.success.default
    : theme.color.grayscale[7];

  return (
    <Fragment>
      <Grid
        container
        item
        xs
        alignItems="center"
        spacing={2}
        style={styles.mainInfoContainer}
      >
        <Grid item>
          <img src={supplier.logo} style={styles.supplierImg} />
        </Grid>
        <Grid container item xs direction="column">
          <Grid item>
            <Typography
              variant={theme.typography.body[1].highlight}
              color={theme.color.grayscale[10]}
            >
              {supplier.fantasy_name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={theme.typography.body[4].highlight}
              color={minimumPriceColor}
            >
              Pedido mínimo {formatCurrency(supplier.minimum_order_total)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs
        justifyContent="space-between"
        style={styles.totalContainer}
      >
        <Grid item xs textAlign="left">
          <Typography
            variant={theme.typography.body[3].regular}
            color={theme.color.grayscale[10]}
          >
            Total da fábrica:
          </Typography>
        </Grid>

        <Grid item xs textAlign="right">
          <Typography
            variant={theme.typography.body[2].highlight}
            color={theme.color.grayscale[9]}
          >
            {formatCurrency(total)}
          </Typography>
        </Grid>
      </Grid>

      {!isMinimumAchieved ? (
        <Grid container alignItems="center" style={styles.alertContainer}>
          <Grid item width={50} pt={0.5} textAlign="center">
            <img src={alertIcon} />
          </Grid>
          <Grid item xs>
            <Typography
              variant={theme.typography.body[3].highlight}
              color={theme.color.grayscale[10]}
            >
              O pedido mínimo ainda não foi atingido, adicione mais produtos
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </Fragment>
  );
};

export default SupplierInfoView;
