import { StoreFormModel } from "pages/stores/StoreCreate/types";

import { api } from "./api.service";

const MODULE_PATH = "store";

interface StoreGetMany {
  page: number;
  search?: string;
  id?: string;
  microregion?: string;
  microregions: Array<number>;
  cityName?: string;
}

export default class StoreService {
  static async getMany({
    page = 1,
    search = "",
    id,
    cityName = "",
    microregions,
  }: StoreGetMany) {
    const options = {
      params: {
        page,
        microregions,
        search,
        city_name: cityName,
        id,
      },
    };
    return api.get(`${MODULE_PATH}/`, options);
  }
  static async getOne(id: string) {
    return api.get(`${MODULE_PATH}/${id}/`);
  }

  static async create(payload: Partial<StoreFormModel>) {
    return api.post(`${MODULE_PATH}/`, payload);
  }

  static async getStoreTypes() {
    return api.get(`store-type/`);
  }

  static async verifyRegistrationStore(registrationNumber: string) {
    try {
      const response = await api.post(`${MODULE_PATH}/verify_registration/`, {
        registration_number: registrationNumber,
      });

      return response;
    } catch (error) {
      return error;
    }
  }
}
