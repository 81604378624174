import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectInputIconLeft } from "components/SelectInputIcon";
import { Fragment } from "react";

import { FormControlContainer, SelectMultipleInput } from "./styles";
import { SelectMultipleProps } from "./types";

const SelectInputMultipleView = ({
  items,
  selectedValues,
  handleSelectedValues,
  placeholder,
}: SelectMultipleProps) => {
  return (
    <FormControlContainer>
      <Select
        value={selectedValues}
        onChange={handleSelectedValues}
        IconComponent={SelectInputIconLeft}
        input={<SelectMultipleInput />}
        variant="standard"
        displayEmpty
        multiple
      >
        <MenuItem value={0}>
          <Fragment>
            {placeholder} {<SelectInputIconLeft />}
          </Fragment>
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id} className="text-break-line">
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControlContainer>
  );
};

export default SelectInputMultipleView;
