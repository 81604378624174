import { IonGrid, IonRow } from "@ionic/react";
import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SelectInputIconLeft } from "components/SelectInputIcon";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { inlineStyles, StorePaymentMehotdStyled } from "./styles";
import { StorePaymentMethodProps } from "./types";

const StorePaymentMethod: React.FC<StorePaymentMethodProps> = ({
  paymentMethods,
  selectedPaymentMethod,
  handleSelectPaymentMethod,
}) => {
  const theme = useTheme();

  const { contentStyle, paymentMethodText, formControlStyle } =
    inlineStyles(theme);

  return (
    <IonGrid style={contentStyle}>
      <IonRow>
        <Typography
          variant={theme.typography.body[2].regular}
          style={paymentMethodText}
        >
          Condição de Pagamento
        </Typography>
      </IonRow>
      <IonRow>
        <FormControl fullWidth sx={formControlStyle}>
          <Select
            value={selectedPaymentMethod}
            onChange={handleSelectPaymentMethod}
            input={<StorePaymentMehotdStyled />}
            IconComponent={SelectInputIconLeft}
            displayEmpty
          >
            <MenuItem value="">Selecione uma forma de pagamento</MenuItem>
            {paymentMethods.map((method) => (
              <MenuItem key={method.id} value={method.id}>
                {method.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </IonRow>
    </IonGrid>
  );
};

export default StorePaymentMethod;
