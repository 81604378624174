import { yupResolver } from "@hookform/resolvers/yup";
import { useIonAlert } from "@ionic/react";
import { LOGIN_401_ERROR } from "commons/constants/form.message.constants";
import { UNEXPECTED_ERROR } from "commons/constants/message.constants";
import { NotificationModel } from "commons/types/auth.enums";
import { UserLoginModel } from "commons/types/user.model";
import { useAuth } from "contexts/auth";
import { StatusCodes } from "http-status-codes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import AuthService from "services/auth.service";

import { loginValidationSchema } from "./utils";

export const useLoginController = () => {
  const { signIn } = useAuth();
  const [presentAlert] = useIonAlert();

  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation<{ notification: NotificationModel }>();

  const [notification, setNotification] = useState<NotificationModel>(
    ({} as NotificationModel) || state?.notification
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UserLoginModel>({
    resolver: yupResolver(loginValidationSchema),
  });

  const onSubmit = async ({ email, password }: UserLoginModel) => {
    try {
      setIsLoading(true);
      const response = await AuthService.signIn({ email, password });
      await signIn(email, response.data);
    } catch (error) {
      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        setError("email", { message: LOGIN_401_ERROR });
        setError("password", { message: LOGIN_401_ERROR });
      } else {
        presentAlert({
          header: "Erro",
          message: UNEXPECTED_ERROR,
          buttons: ["OK"],
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state?.notification) {
      setNotification(state.notification);
    }
  }, [state]);

  return {
    notification,
    control,
    register,
    handleSubmit,
    errors,
    onSubmit,
    isLoading,
  };
};
