import { yupResolver } from "@hookform/resolvers/yup";
import { useIonLoading } from "@ionic/react";
import circleCheck from "assets/svgs/icons/circle-check.svg";
import {
  CART_SAVE_ERROR,
  CART_SAVE_SUCCESS,
} from "commons/constants/cart.constants";
import { SUPPLIER_LOAD_ERROR } from "commons/constants/message.constants";
import { ORDER_CREATE_ERROR } from "commons/constants/order.constants";
import { CartGetModel } from "commons/types/cart.model";
import { OrderPostModel } from "commons/types/order.model";
import { SupplierModel } from "commons/types/supplier.model";
import { formatDate } from "commons/utils/format";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { mapSuppliersToOrder } from "contexts/cart/utils";
import { useConfiguration } from "contexts/configuration";
import { parseISO } from "date-fns";
import { StatusCodes } from "http-status-codes";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import CartService from "services/cart.service";
import OrderService from "services/order.service";
import SupplierService from "services/supplier.service";

import {
  cartValidationSchema,
  saveCartNotification,
  validateSelectedSuppliers,
} from "./utils";

export const useCartController = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const { presentToast } = useConfiguration();

  // Context
  const {
    // Props
    isCartInitiated,
    selectedSuppliers,
    paymentMethods,
    cartData,
    cartTotal,
    shouldLoadCart,
    paymentMethodBundle,
    // Actions
    setShouldLoadCart,
    handleRemoveSupplier: handleCartRemoveSupplier,
    loadSupplierPaymentMethods,
    handleSelectStore,
    updateSuppliers,
    handleChangeSuppliersPaymentMethod,
    handleChangeSupplierPaymentMethod,
    showError,
    clearAllData,
    handleSaveCart: handleDoSaveCart,
    handleUpdateObservation,
    observation,
    paymentDate,
    handleUpdatePaymentDate,
    setSelectedPaymentMethod,
  } = useCart();

  // Form
  const {
    control,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    trigger,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(cartValidationSchema),
  });

  // Dialog states
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [supplierToRemove, setSupplierToRemove] = useState<number | null>(null);
  const [isMinOrderDialogOpen, setIsMinOrderDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isFormInitiated, setIsFormInitiated] = useState(false);
  const [present, dismiss] = useIonLoading();

  const isEditingCart = useMemo(() => !!cartData.cartId, [cartData.cartId]);

  const isButtonDisabled = useMemo(() => {
    return !selectedSuppliers.length;
  }, [selectedSuppliers?.length]);

  const storeEmail = useMemo(() => {
    return cartData.selectedStore ? cartData.selectedStore?.email : "";
  }, [cartData.selectedStore]);

  const loadCart = useCallback(async () => {
    try {
      if (!cartData.cartId) return;
      const response = await CartService.getOne(cartData.cartId);
      const data: CartGetModel = response?.data;

      if (data.payment_date) {
        handleUpdatePaymentDate(parseISO(data.payment_date));
        setValue("paymentDate", parseISO(data.payment_date));
      }
      if (data.observation) {
        handleUpdateObservation(data.observation);
        setValue("observation", data.observation);
      }
      if (data.suppliers) {
        handleSelectStore(data.store, false);
        updateSuppliers(data.suppliers);
      }
      if (data.payment_method_general) {
        setSelectedPaymentMethod(
          paymentMethods.find(
            (paymentMethod) => paymentMethod.id === data.payment_method_general
          ) || null
        );
      }
    } catch (error) {
      showError(SUPPLIER_LOAD_ERROR);
    } finally {
      setShouldLoadCart(false);
      setIsLoading(false);
      dismiss();
    }
  }, [cartData.cartId]);

  const loadSuppliers = useCallback(async () => {
    try {
      const ids = selectedSuppliers.map((sup) => sup.id);
      const response = await SupplierService.getMany({ ids });
      const results: SupplierModel[] = response?.data?.results;
      const _selectedSupliers = selectedSuppliers.map((supplier) => {
        const updatedSupplier = results.find((res) => res.id === supplier.id);
        if (updatedSupplier) {
          return {
            ...supplier,
            minimum_order_total: updatedSupplier.minimum_order_total,
            logo: updatedSupplier.logo,
            fantasy_name: updatedSupplier.fantasy_name,
          };
        }
        return supplier;
      });
      updateSuppliers(_selectedSupliers);
    } catch (error) {
      showError(SUPPLIER_LOAD_ERROR);
    } finally {
      setIsLoading(false);
      dismiss();
    }
  }, [selectedSuppliers]);

  const loadPaymentMethods = useCallback(async () => {
    for (const supplier of selectedSuppliers) {
      await loadSupplierPaymentMethods(supplier.id);
    }
    setIsFormInitiated(true);
  }, [selectedSuppliers]);

  const handleKeepShopping = () => {
    history.push(`/stores/products#fabricas`);
  };

  const handleGoToSupplierProducts = (supplierId: number) => {
    const _supplier = selectedSuppliers.find((sup) => sup.id === supplierId);
    history.push(`/stores/product/supplier/${supplierId}`, _supplier);
  };

  const handleGoToSupplierDetails = (supplierId: number) => {
    history.push(`/stores/cart/supplier/${supplierId}`);
  };

  // Dialog actions
  const handleRemoveSupplier = async (orderId: number) => {
    setSupplierToRemove(orderId);
  };

  const handleDoRemoveSupplier = async (id: number) => {
    try {
      handleCartRemoveSupplier(id);
      setSupplierToRemove(null);
      if (location.pathname !== "/stores/cart") {
        history.goBack();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDoChangeClient = async () => {
    try {
      if (auth?.user?.id) {
        setIsSubmiting(true);
        const response: any = await handleDoSaveCart(auth.user.id, getValues);
        if (response) {
          history.replace("/stores");
        }
      }
    } catch (error) {
      showError(error.message);
      setIsSubmiting(false);
    }
  };

  // Submit actions
  const handleOnSubmit = async () => {
    try {
      const shouldPass = await trigger();
      const isValid = validateSelectedSuppliers(selectedSuppliers);

      if (shouldPass) {
        clearErrors();
        if (isValid) {
          setIsSubmitDialogOpen(true);
        } else {
          setIsMinOrderDialogOpen(true);
        }
      }
    } catch (error) {
      showError(error.message);
    }
  };
  const handleDoSubmit = async () => {
    try {
      const data: any = getValues();
      const suppliers = mapSuppliersToOrder(selectedSuppliers);

      setIsSubmiting(true);
      if (!cartData.selectedStore || !auth.user) return;
      const payload: OrderPostModel = {
        user_id: auth.user.id,
        store_id: cartData.selectedStore.id,
        payment_date: formatDate(data.paymentDate),
        observation: data.observation || null,
        suppliers,
        cart_id: cartData.cartId ? cartData.cartId : null,
      };

      const response = await OrderService.create(payload);
      if (response.status !== StatusCodes.OK) {
        throw new Error(ORDER_CREATE_ERROR);
      } else {
        clearAllData();
        history.push("/stores/cart/success");
      }
    } catch (error) {
      const message = error?.message || ORDER_CREATE_ERROR;
      showError(message);
    } finally {
      setIsSubmiting(false);
      setIsSubmitDialogOpen(false);
    }
  };

  const handleSaveCart = async () => {
    try {
      setIsSubmiting(true);
      if (auth?.user?.id) {
        const response: any = await handleDoSaveCart(auth.user.id, getValues);
        if (response?.status !== StatusCodes.OK) {
          throw new Error(CART_SAVE_ERROR);
        } else {
          presentToast({
            message: CART_SAVE_SUCCESS,
            duration: 1500,
            color: "success",
            icon: circleCheck,
            position: "top",
            mode: "md",
          });
        }
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsSubmiting(false);
      setIsSubmitDialogOpen(false);
    }
  };

  // Close dialogs actions
  const handleCloseSubmitDialog = () => {
    setIsSubmitDialogOpen(false);
  };

  const handleCloseRemoveDialog = () => {
    setSupplierToRemove(null);
  };

  const handleCloseMinOrderDialog = () => {
    setIsMinOrderDialogOpen(false);
  };

  useLayoutEffect(() => {
    if (isLoading) {
      present({
        message: "Carregando...",
        duration: 30000,
      });
    } else {
      dismiss();
    }
  }, [isLoading]);

  const handleUpdateSupplierPaymentMethod = async (
    name: string | undefined,
    obj: any
  ) => {
    const supplierId = Number(name);
    if (supplierId) {
      const paymentMethodId = obj[supplierId];

      await handleChangeSupplierPaymentMethod(
        selectedSuppliers,
        supplierId,
        paymentMethodId,
        paymentMethodBundle
      );
    }
  };

  // Set the initial value of the supplier payment method
  const handleSetSupplierPaymentMethod = (
    supplierId: number,
    supplierMethodId?: number
  ) => {
    if (!supplierMethodId && !cartData?.selectedPaymentMethod?.id) {
      setValue(`${supplierId}`, cartData.selectedPaymentMethod?.id);
    } else {
      setValue(`${supplierId}`, supplierMethodId);
    }
  };

  useEffect(() => {
    if (cartData.cartId && shouldLoadCart) {
      loadCart();
    }
  }, [cartData.cartId]);

  useEffect(() => {
    if (!shouldLoadCart && isCartInitiated && isLoading) {
      loadSuppliers();
    }
  }, [shouldLoadCart, isCartInitiated, isLoading]);

  useEffect(() => {
    if (!isLoading && selectedSuppliers.length) {
      loadPaymentMethods();
    }
  }, [isLoading, selectedSuppliers]);

  /**
   * Initiate form data
   */
  useEffect(() => {
    if (cartData.cartId) {
      if (observation) {
        setValue("observation", observation);
      }
      if (paymentDate) {
        setValue("paymentDate", paymentDate);
      }
    } else {
      setValue("paymentDate", new Date());
    }
  }, []);

  useEffect(() => {
    if (cartData.cartId) {
      setIsFormInitiated(true);
    }
  }, [isLoading, selectedSuppliers, cartData.selectedPaymentMethod]);

  /**
   * Update cart context based in the useForm changes
   */
  useEffect(() => {
    if (!isLoading && isFormInitiated) {
      const subscription = watch((obj, { name }) => {
        handleUpdateSupplierPaymentMethod(name, obj);
      });
      return () => subscription.unsubscribe();
    }
  }, [isLoading, isFormInitiated, watch()]);

  /**
   * Set supplier selectedPaymentMethod ( if needed )
   */
  useEffect(() => {
    if (
      !cartData.cartId &&
      !isLoading &&
      isFormInitiated &&
      cartData.selectedPaymentMethod
    ) {
      handleChangeSuppliersPaymentMethod(
        selectedSuppliers,
        cartData.selectedPaymentMethod.id,
        paymentMethodBundle
      );
    }
  }, [isLoading, isFormInitiated]);

  return {
    isLoading,
    isSubmiting,
    isButtonDisabled,
    saveCartNotification: saveCartNotification,
    isEditingCart,
    storeEmail,
    // Cart context
    selectedSuppliers,
    paymentMethods,
    cartTotal,
    cartData,
    handleChangeSupplierPaymentMethod,
    // form
    control,
    register,
    setValue,
    getValues,
    errors,
    handleSubmit,
    handleSetSupplierPaymentMethod,
    // Actions
    handleOnSubmit,
    handleKeepShopping,
    handleGoToSupplierProducts,
    handleGoToSupplierDetails,
    handleRemoveSupplier,
    handleSaveCart,
    handleDoChangeClient,
    // Dialogs
    isSubmitDialogOpen,
    handleCloseSubmitDialog,
    handleDoSubmit,
    supplierToRemove,
    handleCloseRemoveDialog,
    handleDoRemoveSupplier,
    isMinOrderDialogOpen,
    handleCloseMinOrderDialog,
  };
};
