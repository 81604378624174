import Grid from "@mui/material/Grid";
import submitIcon from "assets/svgs/icons/question.svg";
import DialogWithButtons from "components/DialogWithButtons";
import Typography from "components/Typography";
import { Fragment } from "react";
import { useTheme } from "styled-components";

import { CartDialogsProps } from "./types";

const CartDialogsView = ({
  isSubmiting,
  isSubmitDialogOpen,
  handleCloseSubmitDialog,
  handleDoSubmit,
}: CartDialogsProps) => {
  const theme = useTheme();

  return (
    <Fragment>
      <DialogWithButtons
        isSubmiting={isSubmiting}
        showIcon
        isOpen={isSubmitDialogOpen}
        onClose={handleCloseSubmitDialog}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, finalizar"
        onClickPrimaryButton={handleCloseSubmitDialog}
        onClickSecondaryButton={handleDoSubmit}
        icon={<img src={submitIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[4].highlight}
              color={theme.color.information.default}
            >
              <b>Tudo certo para finalizar o pedido?</b>
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              Revise todos os itens antes de realizar o seu pedido, ele não
              poderá ser editado depois dessa ação.
            </Typography>
          </>
        }
      />
    </Fragment>
  );
};

export default CartDialogsView;
