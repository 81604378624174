import {
  INVALID_EMAIL,
  INVALID_IDENTIFICATION_NUMBER,
  REQUIRED_FIELD,
} from "commons/constants/form.message.constants";
import { UserLoggedInModel } from "commons/types/user.model";
import { isValidCNPJ, isValidCPF } from "commons/utils/general";
import * as Yup from "yup";

export const accountValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required(REQUIRED_FIELD),
  identification_number: Yup.string()
    .trim()
    .required(REQUIRED_FIELD)
    .test("is-valid", INVALID_IDENTIFICATION_NUMBER, (value) => {
      if (!value) return false;
      if (value.length !== 11 && value.length !== 14) return false;
      if (value.length === 11) return isValidCPF(value);
      return isValidCNPJ(value);
    }),
  whatsapp: Yup.string().trim().required(REQUIRED_FIELD),
  phone: Yup.string().trim(),
  email: Yup.string().trim().required(REQUIRED_FIELD).email(INVALID_EMAIL),
});

export const userDefaultValues = (user: UserLoggedInModel) => {
  return {
    name: user.name || "",
    identification_number: user.identification_number || "",
    email: user.email || "",
    whatsapp: user.whatsapp || "",
    phone: user.phone || "",
  };
};
