import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  color: {
    primary: {
      default: "#4305f2",
      contrast: "#ffffff",
      shade: "#3b04d5",
      tint: "#561ef3",
    },
    secondary: {
      default: "#9ab3fc",
      contrast: "#000000",
      shade: "#889ede",
      tint: "#a4bbfc",
    },
    tertiary: {
      default: "#190259",
      contrast: "#ffffff",
      shade: "#16024e",
      tint: "#301b6a",
    },
    neutralLight: {
      default: "#ffffff",
      contrast: "#000000",
      shade: "#e0e0e0",
      tint: "#ffffff",
    },
    neutralDark: {
      default: "#15181f",
      contrast: "#ffffff",
      shade: "#12151b",
      tint: "#2c2f35",
    },
    success: {
      default: "#28a534",
      contrast: "#ffffff",
      shade: "#23912e",
      tint: "#3eae48",
    },
    information: {
      default: "#021859",
      contrast: "#ffffff",
      shade: "#02154e",
      tint: "#1b2f6a",
    },
    error: {
      default: "#c93030",
      contrast: "#ffffff",
      shade: "#b12a2a",
      tint: "#ce4545",
    },
    alert: {
      default: "#f2b705",
      contrast: "#000000",
      shade: "#d5a104",
      tint: "#f3be1e",
    },
    grayscale: {
      1: "#f3f5f7",
      2: "#e0e4eb",
      3: "#d1d7e1",
      4: "#c1c9d7",
      5: "#a3afc2",
      6: "#8494ae",
      7: "#667a99",
      8: "#51617b",
      9: "#3d495c",
      10: "#29313d",
      11: "#15181F",
    },
  },
  typography: {
    heading: {
      0: {
        regular: { fontSize: "40px", fontWeight: 500, lineHeight: "52px" },
        highlight: { fontSize: "40px", fontWeight: 700, lineHeight: "52px" },
      },
      1: {
        regular: { fontSize: "30px", fontWeight: 500, lineHeight: "39px" },
        highlight: { fontSize: "30px", fontWeight: 700, lineHeight: "39px" },
      },
      2: {
        regular: { fontSize: "24px", fontWeight: 500, lineHeight: "31px" },
        highlight: { fontSize: "24px", fontWeight: 700, lineHeight: "31px" },
      },
      3: {
        regular: { fontSize: "20px", fontWeight: 500, lineHeight: "26px" },
        highlight: { fontSize: "20px", fontWeight: 700, lineHeight: "26px" },
      },
      4: {
        regular: { fontSize: "18px", fontWeight: 500, lineHeight: "23px" },
        highlight: { fontSize: "18px", fontWeight: 700, lineHeight: "23px" },
      },
    },
    subheading: {
      1: {
        regular: { fontSize: "24px", fontWeight: 300, lineHeight: "31px" },
        highlight: { fontSize: "24px", fontWeight: 600, lineHeight: "31px" },
      },
      2: {
        regular: { fontSize: "18px", fontWeight: 300, lineHeight: "23px" },
        highlight: { fontSize: "18px", fontWeight: 600, lineHeight: "23px" },
      },
      3: {
        regular: { fontSize: "16px", fontWeight: 300, lineHeight: "23px" },
        highlight: { fontSize: "16px", fontWeight: 600, lineHeight: "23px" },
      },
    },
    body: {
      1: {
        regular: { fontSize: "18px", fontWeight: 400, lineHeight: "22px" },
        highlight: { fontSize: "18px", fontWeight: 600, lineHeight: "22px" },
      },
      2: {
        regular: { fontSize: "16px", fontWeight: 400, lineHeight: "20px" },
        highlight: { fontSize: "16px", fontWeight: 600, lineHeight: "20px" },
      },
      3: {
        regular: { fontSize: "14px", fontWeight: 400, lineHeight: "18px" },
        highlight: { fontSize: "14px", fontWeight: 600, lineHeight: "18px" },
      },
      4: {
        regular: { fontSize: "12px", fontWeight: 400, lineHeight: "16px" },
        highlight: { fontSize: "12px", fontWeight: 600, lineHeight: "16px" },
      },
      5: {
        regular: { fontSize: "10px", fontWeight: 400, lineHeight: "16px" },
        highlight: { fontSize: "10px", fontWeight: 600, lineHeight: "16px" },
      },
    },
    link: {
      1: {
        regular: {
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "21px",
          decoration: "underline",
        },
        highlight: {
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "21px",
          decoration: "underline",
        },
      },
      2: {
        regular: {
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          decoration: "underline",
        },
        highlight: {
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "16px",
          decoration: "underline",
        },
      },
    },
  },
};
