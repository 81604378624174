import { useIonAlert } from "@ionic/react";
import { Divider, Grid } from "@mui/material";
import refreshIcon from "assets/svgs/icons/refresh.svg";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import DialogWithButtons from "components/DialogWithButtons";
import ImageSlider from "components/ImageSlider";
import InfoCard from "components/InfoCard";
import Typography from "components/Typography";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import {
  bagOutline,
  callOutline,
  clipboardOutline,
  imageOutline,
  locationOutline,
  logoWhatsapp,
  mailOutline,
  personOutline,
} from "ionicons/icons";
import { useStoreInfoController } from "pages/products/components/StoreInfoBlock/controller";
import { useHistory } from "react-router";
import { useTheme } from "styled-components";

import { buttonsDividerStyle, storeInfosStyles } from "./styles";
import { StoreCardProps } from "./types";

const StoreInfos: React.FC<StoreCardProps> = ({ store }) => {
  const history = useHistory();
  const theme = useTheme();
  const {
    isSubmiting,
    isChangeClientDialogOpen,
    handleCloseChangeClientDialog,
    handleOpenChangeClienteDialog,
  } = useStoreInfoController();
  const { primaryButtonStyle, secondaryButtonStyle } = storeInfosStyles();
  const {
    selectedSuppliers,
    cartData,
    handleSelectStore,
    handleSaveCart,
    selectedPaymentMethod,
  } = useCart();

  const [presentAlert] = useIonAlert();

  const { user } = useAuth();

  const {
    address,
    store_type: storeType,
    contact_name: contactName,
    whatsapp,
    phone,
    email,
    images,
    observation,
  } = store;

  const handleClick = () => {
    if (!selectedPaymentMethod) {
      presentAlert({
        header: "Alerta",
        message: "Selecione uma forma de pagamento.",
        buttons: ["OK"],
      });

      return;
    }
    if (selectedSuppliers.length && !cartData.currentOrder) {
      handleOpenChangeClienteDialog();
    } else {
      handleGotoProducts();
    }
  };

  const handleGotoProducts = () => {
    handleSelectStore(store);
    history.push("/stores/products#fabricas");
  };

  const submitModal = () => {
    if (user) {
      handleSaveCart(user.id);
      handleCloseChangeClientDialog();
      handleGotoProducts();
    }
  };

  return (
    <Grid container spacing={1} direction="column">
      <DialogWithButtons
        isSubmiting={isSubmiting}
        showIcon
        isOpen={isChangeClientDialogOpen}
        onClose={handleCloseChangeClientDialog}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, alterar"
        onClickPrimaryButton={handleCloseChangeClientDialog}
        onClickSecondaryButton={submitModal}
        icon={<img src={refreshIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].highlight}
              color={theme.color.information.default}
            >
              <b>Você quer alterar o cliente?</b>
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              O seu carrinho atual será salvo como rascunho na aba de pedidos.
            </Typography>
          </>
        }
      />

      <Grid item xs={8} mt={2} ml={3} mb={1}>
        <Typography
          variant={theme.typography.body[2].highlight}
          color={theme.color.grayscale[7]}
        >
          Informações da loja
        </Typography>
      </Grid>
      <Grid item xs={8} ml={3} mr={3}>
        <Divider />
      </Grid>
      <InfoCard
        icon={locationOutline}
        title={"Localização"}
        value={
          <>
            {`${address.street} ${address.number}, ${address.neighborhood}`}
            <br />
            {`CEP ${address.zipcode}`}
            <br />
            {`${address.city} (${address.state})`}
            {address.complement && (
              <>
                <br />
                {`${address.complement}`}
              </>
            )}
          </>
        }
      />
      <InfoCard
        icon={personOutline}
        title={"Nome do contato"}
        value={contactName}
      />
      <InfoCard icon={logoWhatsapp} title={"WhatsApp"} value={whatsapp} />
      <InfoCard icon={callOutline} title={"Telefone"} value={phone} />
      <InfoCard icon={mailOutline} title={"Email"} value={email} />
      <Grid mt={1} />
      <InfoCard
        icon={imageOutline}
        title={"Imagens"}
        value={
          images.length ? (
            <ImageSlider imageList={images} />
          ) : (
            "Esta loja não possui imagens."
          )
        }
        unwraped={!!images.length}
      />
      <Grid mb={1} />
      <Grid />
      <InfoCard
        icon={clipboardOutline}
        title={"Observações"}
        value={observation}
      />
      <InfoCard
        icon={bagOutline}
        title={"Ramo da atividade"}
        value={storeType}
      />
      <Grid
        item
        xs={12}
        pt={"45px !important"}
        mb={"5px"}
        sx={buttonsDividerStyle}
      >
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        {/* TODO: create context to modify product prices */}
        <Grid item xs style={primaryButtonStyle}>
          <ButtonPrimaryFilled onClick={() => handleClick()}>
            Selecionar cliente
          </ButtonPrimaryFilled>
        </Grid>

        <Grid item xs style={secondaryButtonStyle}>
          <ButtonPrimaryOutlined onClick={() => history.goBack()}>
            Voltar para a lista de lojas
          </ButtonPrimaryOutlined>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreInfos;
