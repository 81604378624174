import { Grid } from "@mui/material";
import { useTheme } from "styled-components";

import BannerImg from "../../../../assets/svgs/banner_img.svg";
import {
  bannersStyles,
  ProductBannerContainer,
  ProductBannerTypography,
  StyledBannerImg,
} from "./styles";

const ProductsBannerView = () => {
  const theme = useTheme();
  const styles = bannersStyles(theme);
  return (
    <ProductBannerContainer container>
      <Grid item xs={8} md={6} style={styles.bannerGridStyle}>
        <ProductBannerTypography>
          Encontre os melhores produtos <b>na Dank!</b>
        </ProductBannerTypography>
      </Grid>
      <Grid item xs style={styles.bannerGridImageStyle}>
        <StyledBannerImg src={BannerImg} />
      </Grid>
    </ProductBannerContainer>
  );
};

export default ProductsBannerView;
