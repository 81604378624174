import { yupResolver } from "@hookform/resolvers/yup";
import { useIonAlert } from "@ionic/react";
import {
  DJANGO_PASSWORD_RECOVER_ERROR,
  INVALID_EMAIL,
} from "commons/constants/auth.constants";
import { UNEXPECTED_ERROR } from "commons/constants/message.constants";
import { UserRecoverPasswordModel } from "commons/types/user.model";
import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import AuthService from "services/auth.service";

import { recoverPassworValidationSchema } from "../utils";

export const useRecoverPasswordController = () => {
  const [presentAlert] = useIonAlert();

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UserRecoverPasswordModel>({
    resolver: yupResolver(recoverPassworValidationSchema),
  });

  const goTo = (path: string, state?: any) => {
    history.push(path, state);
  };

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      setIsLoading(true);
      const response = await AuthService.recoverPassword(email);

      if (response.status === StatusCodes.OK) {
        goTo("/recover-password/resend", { email });
      }
    } catch (error) {
      const status = error.response.status;
      const data = error.response.data;

      if (status === StatusCodes.BAD_REQUEST) {
        if (data["email"]) {
          let message = data["email"][0];
          if (DJANGO_PASSWORD_RECOVER_ERROR.WRONG_EMAIL === message) {
            message = INVALID_EMAIL;
          }
          setError("email", {
            message: message,
          });
        }
      } else {
        presentAlert({
          header: "Erro",
          message: UNEXPECTED_ERROR,
          buttons: ["OK"],
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    control,
    register,
    handleSubmit,
    errors,
    onSubmit,
    isLoading,
  };
};
