import { IonPage } from "@ionic/react";
import { Grid, useMediaQuery } from "@mui/material";
import refreshIcon from "assets/svgs/icons/refresh.svg";
import removeIcon from "assets/svgs/icons/trash.svg";
import { tabletMinWidth } from "commons/constants/configuration.constant";
import { ORDER_STATUS } from "commons/types/order.model";
import { formatDateWithoutTime } from "commons/utils/format";
import DialogWithButtons from "components/DialogWithButtons";
import FormInputView from "components/FormInput";
import FormSelectView from "components/FormSelect";
import HeaderWithButton from "components/HeaderWithButton";
import FormDateInputView from "components/inputs/FormDateInput";
import Typography from "components/Typography";
import CartOrderDetailsView from "pages/cart/components/CartOrderDetails";
import SupplierCardView from "pages/cart/components/SupplierCard";
import { cartStyles } from "pages/cart/styles";
import { RouteComponentProps } from "react-router";
import { useTheme } from "styled-components";
import { StyledIonContent } from "theme/global.styles";

import OrderCardDetailsView from "../components/OrderCardDetails";
import { useOrderDetailsController } from "./controller";

const OrderDetailsView = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(tabletMinWidth);
  const styles = cartStyles(theme, isTablet);
  const orderStatusOptions = Object.values(ORDER_STATUS);

  const {
    isAdmin,
    isLoading,
    isSubmiting,
    isEditing,
    isButtonDisabled,
    canEdit,
    canCancel,
    order,
    orderCard,
    // Cart context
    selectedSuppliers,
    paymentMethods,
    cartTotal,
    // form
    control,
    register,
    getValues,
    errors,
    handleSubmit,
    // Actions
    handleOnSubmit,
    handleCancelOrder,
    handleGoToSupplierProducts,
    handleGoToSupplierDetails,
    handleRemoveSupplier,
    handleDoRemoveSupplier,
    handleKeepShopping,
    handleToggleEdit,
    handleDoChangeOrderStatus,
    handleGoBackListOrders,
    handleSetSupplierPaymentMethod,
    // Dialogs
    handleOpenCancelDialog,
    isCancelDialogOpen,
    handleCloseCancelDialog,
    isStatusDialogOpen,
    handleCloseStatusDialog,
    supplierToRemove,
    handleCloseRemoveDialog,
    isMinOrderDialogOpen,
    handleCloseMinOrderDialog,
  } = useOrderDetailsController(Number(match.params.id));

  if (isLoading || !order?.id)
    return (
      <IonPage>
        <HeaderWithButton handleCustomBackClick={handleGoBackListOrders} />
        <StyledIonContent />
      </IonPage>
    );

  return (
    <IonPage>
      <DialogWithButtons
        showIcon
        isDangerAction
        hasAutoSizedBtns
        isOpen={isCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, cancelar"
        onClickPrimaryButton={handleCloseCancelDialog}
        onClickSecondaryButton={handleCancelOrder}
        icon={<img src={removeIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].regular}
              color={theme.color.error.default}
            >
              Deseja <b>cancelar</b> o pedido?
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              Você não conseguirá abrir novamente o pedido depois de cancelado.
            </Typography>
          </>
        }
      />
      <DialogWithButtons
        showIcon
        hasAutoSizedBtns
        isOpen={isStatusDialogOpen}
        onClose={handleCloseStatusDialog}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, alterar"
        onClickPrimaryButton={handleCloseStatusDialog}
        onClickSecondaryButton={handleDoChangeOrderStatus}
        icon={<img src={refreshIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].regular}
              color={theme.color.information.shade}
            >
              Deseja <b>alterar o estado do pedido</b>?
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              O estado do pedido passará de "{order.status}" para "
              {getValues("status")}".
            </Typography>
          </>
        }
      />
      <HeaderWithButton handleCustomBackClick={handleGoBackListOrders} />
      {!orderCard ? null : <OrderCardDetailsView {...orderCard} />}
      <StyledIonContent>
        <Grid container direction="column" spacing={2}>
          {isAdmin && isEditing ? (
            <Grid item xs mx={3} mt={3} mb={-2}>
              <FormSelectView
                title="Estado do pedido"
                placeholder="Selecione..."
                field="status"
                register={register}
                disabled={!canEdit || !isEditing}
                control={control}
                errors={errors}
                selectOptions={orderStatusOptions}
              />
            </Grid>
          ) : null}

          <Grid item xs>
            {selectedSuppliers.map((supplier, index) => {
              return (
                <Grid key={supplier.id} mt="15px">
                  <Grid style={styles.supplierCounterTitle}>
                    <Typography
                      variant={theme.typography.subheading[3].highlight}
                      color={theme.color.tertiary.default}
                    >
                      Fábrica {index + 1}:
                    </Typography>
                  </Grid>
                  <SupplierCardView
                    paymentMethods={paymentMethods}
                    supplier={supplier}
                    control={control}
                    addProducts={handleGoToSupplierProducts}
                    viewDetails={handleGoToSupplierDetails}
                    removeSupplier={handleRemoveSupplier}
                    supplierToRemove={supplierToRemove}
                    handleCloseRemoveDialog={handleCloseRemoveDialog}
                    handleDoRemove={handleDoRemoveSupplier}
                    isMinOrderDialogOpen={isMinOrderDialogOpen}
                    handleCloseMinOrderDialog={handleCloseMinOrderDialog}
                    isEditingOrder={isEditing}
                    handleSetSupplierPaymentMethod={
                      handleSetSupplierPaymentMethod
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs style={styles.detailsTitle}>
            <Typography
              variant={theme.typography.heading[4].highlight}
              color={theme.color.neutralDark.default}
            >
              Detalhes do pedido
            </Typography>
          </Grid>
          {isEditing ? (
            <Grid item xs style={styles.detailsContainer}>
              <FormDateInputView
                control={control}
                field="paymentDate"
                title="Data de faturamento*"
                errors={errors}
              />

              <FormInputView
                field="observation"
                title="Observações e forma de pagamento do pedido*"
                placeholder="Insira as observações da loja..."
                rows={3}
                multiline
                register={register}
                control={control}
                errors={errors}
              />
            </Grid>
          ) : (
            <Grid item xs style={styles.detailsContainer}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    color={theme.color.grayscale[9]}
                    variant={theme.typography.body[3].highlight}
                  >
                    Data de faturamento
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color={theme.color.grayscale[9]}
                    variant={theme.typography.body[3].regular}
                  >
                    {formatDateWithoutTime(order.payment_date, "dd/MM/yyyy")}
                  </Typography>
                </Grid>
                <Grid item mt={1}>
                  <Typography
                    color={theme.color.grayscale[9]}
                    variant={theme.typography.body[3].highlight}
                  >
                    Observações
                  </Typography>
                </Grid>
                <Grid item mb={5}>
                  <Typography
                    color={theme.color.grayscale[9]}
                    variant={theme.typography.body[3].regular}
                  >
                    {order.observation || "Sem observações"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid style={styles.footer}>
          <CartOrderDetailsView
            orderTotal={cartTotal}
            isTablet={isTablet}
            handleSubmit={handleSubmit}
            handleOnSubmit={handleOnSubmit}
            handleKeepShopping={handleKeepShopping}
            handleToggleEdit={handleToggleEdit}
            handleCancelOrder={handleOpenCancelDialog}
            isButtonDisabled={isButtonDisabled}
            isSubmiting={isSubmiting}
            isOrderView
            isEditingOrder={isEditing}
            isAdmin={isAdmin}
            canCancel={canCancel}
            canEdit={canEdit}
          />
        </Grid>
      </StyledIonContent>
    </IonPage>
  );
};

export default OrderDetailsView;
