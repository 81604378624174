import { IonFooter, IonPage } from "@ionic/react";
import { Grid } from "@mui/material";
import BackButtonHeader from "components/BackButtonHeader";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import DialogWithButtons from "components/DialogWithButtons";
import Typography from "components/Typography";
import { StyledIonContent } from "pages/microregions/styles";

import EditAccountForm from "./components/EditAccountForm";
import { useEditAccountController } from "./controller";
import { editAccountStyles } from "./styles";

const EditAccountView: React.FC = () => {
  const {
    theme,
    isTablet,
    isDialogOpen,
    handleCloseDialog,
    handleGoBack,
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    identificationMask,
    isChangedEmail,
    handleResetUserData,
  } = useEditAccountController();

  const { footerStyle } = editAccountStyles(theme);

  return (
    <IonPage>
      <DialogWithButtons
        showIcon
        hasAutoSizedBtns
        isOpen={isDialogOpen || isChangedEmail}
        onClose={isChangedEmail ? handleResetUserData : handleCloseDialog}
        secondaryButtonText={
          isChangedEmail ? "Continuar" : "Voltar para o perfil"
        }
        onClickSecondaryButton={
          isChangedEmail ? handleResetUserData : handleCloseDialog
        }
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].highlight}
              color={theme.color.success.default}
            >
              <b>Conta atualizada {isChangedEmail ? "com sucesso!" : null}</b>
            </Typography>
            <br />
            <Typography
              variant={
                isChangedEmail
                  ? theme.typography.body[2].regular
                  : theme.typography.heading[2].regular
              }
              color={
                isChangedEmail
                  ? theme.color.grayscale[8]
                  : theme.color.success.default
              }
            >
              {isChangedEmail
                ? "Para manter a segurança, vamos pedir para você entrar na conta novamente."
                : "com sucesso"}
            </Typography>
          </>
        }
      />
      <BackButtonHeader />
      <StyledIonContent>
        <Grid item mx={3} my={4}>
          <Typography
            variant={theme.typography.subheading[3].regular}
            color={theme.color.grayscale[7]}
          >
            Editar conta
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent={isTablet ? "center" : "normal"}
            width="100%"
          >
            <EditAccountForm
              register={register}
              errors={errors}
              control={control}
              identificationMask={identificationMask}
              isTablet={isTablet}
            />
          </Grid>
        </form>
      </StyledIonContent>
      <IonFooter collapse="fade" style={footerStyle}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          py={2}
          px={3}
          spacing={1}
          direction={isTablet ? "row-reverse" : "row"}
        >
          <Grid item xs={12} sm={6} textAlign={isTablet ? "start" : "center"}>
            <ButtonPrimaryFilled onClick={handleSubmit(onSubmit)} type="submit">
              Salvar
            </ButtonPrimaryFilled>
          </Grid>
          <Grid item xs={12} sm={6} textAlign={isTablet ? "end" : "center"}>
            <ButtonPrimaryOutlined onClick={handleGoBack}>
              Cancelar
            </ButtonPrimaryOutlined>
          </Grid>
        </Grid>
      </IonFooter>
    </IonPage>
  );
};

export default EditAccountView;
