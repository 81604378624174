import { IonHeader } from "@ionic/react";
import { Grid } from "@mui/material";
import { chevronBack } from "ionicons/icons";
import { useTheme } from "styled-components";
import { globalStyles } from "theme/global.styles";

import { useBackButtonController } from "./controller";
import {
  BackButtonLink,
  StyledIonIcon,
  StyledIonToolbar,
  StyledBorderlessIonToolbar,
} from "./styles";
import { HeaderWithButtonProps } from "./types";

const HeaderWithButton = ({
  rightButton,
  borderless = false,
  handleCustomBackClick,
}: HeaderWithButtonProps) => {
  const theme = useTheme();
  const { handleClickBack } = useBackButtonController(handleCustomBackClick);
  const { textAlignRight } = globalStyles();
  const StyledToolbar = borderless
    ? StyledBorderlessIonToolbar
    : StyledIonToolbar;

  return (
    <IonHeader>
      <StyledToolbar color="light" mode="ios">
        <Grid container alignContent="center" alignItems="center">
          <Grid item xs={7}>
            <BackButtonLink
              href="#"
              color={theme.color.neutralDark.default}
              variant={theme.typography.body[2].regular}
              onClick={handleClickBack}
            >
              <StyledIonIcon icon={chevronBack} mode="ios" />
              <span>Voltar</span>
            </BackButtonLink>
          </Grid>
          <Grid item xs={5} style={textAlignRight}>
            {rightButton ? rightButton : null}
          </Grid>
        </Grid>
      </StyledToolbar>
    </IonHeader>
  );
};

export default HeaderWithButton;
