import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";

import { useModalSendMailController } from "./controller";
import { sendMailStyles } from "./styles";
import { ModalSendMailProps } from "./types";

export const ModalSendMail: React.FC<ModalSendMailProps> = ({
  handleClose,
  isOpen,
  email,
  handleSendMail,
  isLoadingSendMailSubmit,
  id,
}) => {
  const styles = sendMailStyles();
  const { register, handleSubmit, errors, emailsMessage } =
    useModalSendMailController();
  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle id="alert-dialog-title">
        Enviar pedido por e-mail
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} style={styles.textFieldContainer}>
            <TextField
              style={styles.textFieldContainer}
              id="storeEmail"
              value={email}
              label="Destinatário obrigatório"
              variant="outlined"
              {...register("storeEmail")}
              helperText="Este é o e-mail cadastrado na loja."
              InputProps={{
                readOnly: true,
                style: styles.readOnlytextField,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={styles.textFieldContainer}
              id="to"
              label="Outros destinatários (opcional)"
              variant="outlined"
              {...register("to")}
              error={!!errors["to"]}
              helperText={emailsMessage}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={styles.dialogActions}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          variant="contained"
          style={styles.confirmButton}
          onClick={handleSubmit((data: any) => handleSendMail(id, data))}
        >
          {isLoadingSendMailSubmit ? (
            <CircularProgress style={styles.circularProgress} size={20} />
          ) : (
            "Enviar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
