import {
  INVALID_EMAIL,
  REQUIRED_FIELD,
} from "commons/constants/form.message.constants";
import * as Yup from "yup";

export const recoverPassworValidationSchema = Yup.object().shape({
  email: Yup.string().trim().required(REQUIRED_FIELD).email(INVALID_EMAIL),
});

export const confirmRecoverPassworValidationSchema = Yup.object().shape({
  password: Yup.string().trim().required(REQUIRED_FIELD),
  repeatPassword: Yup.string()
    .required(REQUIRED_FIELD)
    .trim()
    .test("password-match", "As senhas devem ser iguais", function (value) {
      return this.parent.password === value;
    }),
});
