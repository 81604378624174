import { MaterialStyles } from "commons/types/generics/materialStyles";

export const storeFormInfoStyles = (): MaterialStyles => ({
  mainContainer: {
    marginBottom: "30px",
    width: "100%",
  },
  headerContainer: {
    marginBottom: "14px",
    width: "100%",
  },
  stepperContainer: {
    marginRight: "-25px",
    width: "100%",
  },
});
