import { Chip, Grid } from "@mui/material";
import logoPlaceholder from "assets/svgs/img_placeholder_big.svg";
import { formatCurrency } from "commons/utils/format";
import { ButtonsPdf } from "components/ButtonsPdf";
import Link from "components/Link";
import Typography from "components/Typography";
import { orderStyles, StyledIonCard } from "pages/orders/styles";
import { useTheme } from "styled-components";

import { OrderCardProps } from "../OrderCard/types";
import { getOrderStatusColor } from "../OrderCard/utils";
import { useOrderCardDetailController } from "./controller";

const OrderCardDetailsView = ({
  id,
  order,
  store,
  totalValue,
  status,
  logo,
  isDraft,
  handleGoToDetails,
  email,
}: OrderCardProps) => {
  const theme = useTheme();

  const { container, image, chip, imagePlaceholder } = orderStyles();

  const {
    handleGeneratePdf,
    isLoadingSubmit,
    handleShowModal,
    isOpenSendMailModal,
    handleSendPdfOrderMail,
    isLoadingSendMailSubmit,
  } = useOrderCardDetailController();

  return (
    <StyledIonCard class="isDetailsView">
      <Grid container direction="column" sx={container} spacing={1}>
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {logo ? (
                <img src={logo} style={image} alt="logo" />
              ) : (
                <img
                  src={logoPlaceholder}
                  style={imagePlaceholder}
                  alt="logo"
                />
              )}
            </Grid>
            <Grid container item xs direction="column">
              <Grid item mb={0.5}>
                <Typography
                  variant={theme.typography.subheading[2].highlight}
                  color={theme.color.grayscale[9]}
                >
                  {store}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={theme.typography.body[4].regular}
                  color={theme.color.grayscale[6]}
                >
                  Nº do {isDraft ? "rascunho" : "pedido"}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={theme.typography.body[4].highlight}
                  color={theme.color.grayscale[6]}
                >
                  {order || id}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            height={100}
            mb={1}
          >
            <Grid item>
              <Grid container item xs direction="column">
                <Grid item>
                  <Typography
                    variant={theme.typography.body[4].regular}
                    color={theme.color.grayscale[7]}
                  >
                    Valor total
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant={theme.typography.body[4].highlight}
                    color={theme.color.grayscale[9]}
                  >
                    {formatCurrency(totalValue || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} textAlign="right">
              <Chip
                sx={chip}
                color={getOrderStatusColor(status)}
                label={
                  <Typography variant={theme.typography.body[4].highlight}>
                    {isDraft ? "Rascunho" : status}
                  </Typography>
                }
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              marginTop={2}
            >
              <ButtonsPdf
                onSubmit={() => handleGeneratePdf(false, order ? order : id)}
                isLoadingSubmit={isLoadingSubmit}
                handleCloseSendMailModal={() => handleShowModal(false)}
                handleOpenSendMailModal={() => handleShowModal(true)}
                isOpenSendMailModal={isOpenSendMailModal}
                email={email}
                handleSendMail={handleSendPdfOrderMail}
                id={order ? order : id}
                isLoadingSendMailSubmit={isLoadingSendMailSubmit}
              />
            </Grid>
          </Grid>
        </Grid>

        {handleGoToDetails ? (
          <Grid item>
            <Link
              handleClick={handleGoToDetails}
              title={isDraft ? "Continuar pedido" : "Ver pedido"}
            />
          </Grid>
        ) : null}
      </Grid>
    </StyledIonCard>
  );
};

export default OrderCardDetailsView;
