import { SUPPLIERS_LOAD_ERROR } from "commons/constants/message.constants";
import { SupplierModel } from "commons/types/supplier.model";
import { useCart } from "contexts/cart";
import { useConfiguration } from "contexts/configuration";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import SupplierService from "services/supplier.service";

export const useListSuppliersController = () => {
  // History hoook
  const history = useHistory();
  // Store provider state
  const { selectedStore } = useCart();
  const { presentError } = useConfiguration();
  // Suppliers list state
  const [suppliers, setSuppliers] = useState<SupplierModel[]>([]);
  // Search state
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const ROWS_PER_PAGE = 10;

  async function searchNext(event: CustomEvent<void>, page: number) {
    await loadSuppliers(page);
    (event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const loadSuppliers = async (currentPage?: number) => {
    try {
      setIsLoading(true);
      const searchParams = {
        page: currentPage ? currentPage : 1,
        search: searchText,
        microregions: selectedStore?.microregion
          ? [selectedStore?.microregion?.id]
          : undefined,
      };
      const response = await SupplierService.getMany(searchParams);

      if (searchText) {
        setSuppliers(response.data.results);
      } else {
        const _suppliers = [...suppliers, ...response.data.results];
        const uniqueSuppliers = _suppliers.reduce((unique, o) => {
          if (!unique.some((obj: any) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        setSuppliers(uniqueSuppliers);
      }
      if (response.data.results.length >= ROWS_PER_PAGE) {
        setInfiniteDisabled(false);
      } else {
        setInfiniteDisabled(true);
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, SUPPLIERS_LOAD_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchText = useCallback(async (text: string) => {
    setSearchText(text);
    setPage(1);
  }, []);

  const handleClearText = useCallback(() => {
    setSearchText("");
  }, []);

  const handleLoadData = useCallback((event: CustomEvent<void>) => {
    setTimeout(() => {
      setScrollEvent(event);
    }, 1500);
  }, []);

  const handleClickSupplier = (supplier: SupplierModel) => () =>
    history.push(`/stores/product/supplier/${supplier.id}`, supplier);

  useEffect(() => {
    loadSuppliers();
  }, []);

  useEffect(() => {
    if (scrollEvent) {
      searchNext(scrollEvent, page + 1);
      setPage((oldstate) => oldstate + 1);
    }
  }, [scrollEvent]);

  useEffect(() => {
    setIsLoading(true);
    loadSuppliers(1);
  }, [searchText]);

  return {
    // Suppliers
    suppliers,
    // Supplier search
    searchText,
    handleSearchText,
    handleClearText,
    handleLoadData,
    isInfiniteDisabled,
    // Navigation
    handleClickSupplier,
    isLoading,
  };
};
