import { openOrderStatus } from "pages/orders/types";

import OrderListView from "../../OrderList";
import { useOrderListController } from "../../OrderList/controller";
import { OrdersView } from "../../OrderList/types";

const OrdersOpenView = ({ handleGoToDetails }: OrdersView) => {
  const {
    isInfiniteDisabled,
    isLoading,
    search,
    orders,
    handleLoadData,
    handleSearchText,
    handleClearText,
  } = useOrderListController(openOrderStatus);
  return (
    <OrderListView
      isInfiniteDisabled={isInfiniteDisabled}
      isLoading={isLoading}
      search={search}
      orders={orders}
      handleLoadData={handleLoadData}
      handleSearchText={handleSearchText}
      handleClearText={handleClearText}
      handleGoToDetails={handleGoToDetails}
    />
  );
};

export default OrdersOpenView;
