import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { sendMailValidationSchema } from "./utils";

export const useModalSendMailController = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendMailValidationSchema),
    reValidateMode: "onChange",
  });

  const emailsMessage = useMemo(() => {
    return errors["to"] && errors["to"].length
      ? "Um dos e-mails não é válido, digite corretamente os e-mails separados por vírgulas."
      : "Adicione outros e-mails para envio separando por vírgulas.";
  }, [errors["to"]]);

  return { control, register, handleSubmit, errors, emailsMessage };
};
