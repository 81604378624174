import { IonSearchbar } from "@ionic/react";

import { StyledDiv } from "./styles";
import { SearchbarProps } from "./types";

const Searchbar = ({
  value,
  color = "light",
  placeholder = "Buscar",
  onSearch,
  onClear,
}: SearchbarProps) => {
  return (
    <StyledDiv>
      <IonSearchbar
        value={value}
        onIonChange={(e) => onSearch(e.detail.value || "")}
        color={color}
        mode="md"
        placeholder={placeholder}
        debounce={2000}
        onIonClear={onClear}
      />
    </StyledDiv>
  );
};

export default Searchbar;
