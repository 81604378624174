import { useAuth } from "contexts/auth";
import { useState } from "react";
import { useHistory } from "react-router";
import { useTheme } from "styled-components";

export const useAccountController = () => {
  const { user, signOut } = useAuth();
  const theme = useTheme();

  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleLogOut = () => {
    signOut();
    setIsDialogOpen(false);
  };

  const handleGoToPage = (route: string) => () => {
    history.push(route);
  };

  return {
    theme,
    user,
    handleLogOut,
    isDialogOpen,
    handleOpenDialog,
    handleCloseDialog,
    handleGoToPage,
  };
};
