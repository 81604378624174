import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const productSubtotalStyles = (theme: DefaultTheme): MaterialStyles => ({
  mainCard: {
    width: "100%",
    padding: "20px",
    boxShadow: "unset",
  },
  supplierImg: {
    width: "60px",
    height: "60px",
    padding: 5,
    borderRadius: 8,
    border: "1px solid",
    borderColor: theme.color.grayscale[7],
  },
});
