import { IonImg } from "@ionic/react";
import { Card, Grid } from "@mui/material";
import styled from "styled-components";

export const ProductContainer = styled(Grid)`
  max-width: 160px;
  margin: auto;
`;

export const StyledCardProduct = styled(Card)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14) !important;
  height: 236px;
  width: 156px;
  /* left: 0px;
  top: 0px; */
  border-radius: 10px !important;
`;

export const ProductInfoContainer = styled(Grid)`
  width: 100%;
  padding: 10px;
  background: #ffffff;
  text-align: left;
`;

export const ProductIonImg = styled(IonImg)`
  object-fit: cover;
  height: 168px;
  width: 156px;
`;
