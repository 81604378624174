import { yupResolver } from "@hookform/resolvers/yup";
import { useIonAlert } from "@ionic/react";
import {
  PASSWORD_UPDATE_SUCCESS,
  TOKEN_INVALID,
} from "commons/constants/auth.constants";
import { UNEXPECTED_ERROR } from "commons/constants/message.constants";
import {
  NotificationModel,
  NOTIFICATION_TYPES,
} from "commons/types/auth.enums";
import { UserConfirmRecoverPasswordModel } from "commons/types/user.model";
import { StatusCodes } from "http-status-codes";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import AuthService from "services/auth.service";
import { useQuery } from "utils/functions";

import { confirmRecoverPassworValidationSchema } from "../utils";

export const useConfirmRecoverPasswordController = () => {
  const [presentAlert] = useIonAlert();

  const query = useQuery();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [token] = useState<string>(query.get("token") || "");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UserConfirmRecoverPasswordModel>({
    resolver: yupResolver(confirmRecoverPassworValidationSchema),
  });

  const errorAlert = (message = UNEXPECTED_ERROR) => {
    presentAlert({
      header: "Erro",
      message,
      buttons: ["OK"],
    });
  };

  const goTo = (path: string, notification?: NotificationModel) => {
    history.push(path, { notification });
  };

  const onSubmit = async ({
    password,
  }: Omit<UserConfirmRecoverPasswordModel, "repeatPassword">) => {
    try {
      setIsLoading(true);
      const response = await AuthService.confirmRecoverToken(token, password);

      if (response.status === StatusCodes.OK) {
        const message = {
          message: PASSWORD_UPDATE_SUCCESS,
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        goTo("/login", message);
      }
    } catch (error) {
      const status = error.response.status;
      const data = error.response.data;

      if (status === StatusCodes.NOT_FOUND) {
        if (data["detail"]) {
          errorAlert(TOKEN_INVALID);
          goTo("/login");
        }
      } else if (status === StatusCodes.BAD_REQUEST) {
        if (data["password"]) {
          setError("password", {
            message: data["password"][0],
          });
        }
        if (data["token"]) {
          errorAlert(TOKEN_INVALID);
        }
      } else {
        errorAlert();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyToken = useCallback(async () => {
    try {
      await AuthService.verifyRecoverToken(token);
    } catch (error) {
      const status = error.response.status;
      const data = error.response.data;

      if (status === StatusCodes.NOT_FOUND) {
        if (data["detail"]) {
          errorAlert(TOKEN_INVALID);
          goTo("/login");
        }
      }
    }
  }, [token]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  return {
    control,
    register,
    handleSubmit,
    errors,
    onSubmit,
    isLoading,
  };
};
