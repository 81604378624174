import { IonPage } from "@ionic/react";
import { CircularProgress, Grid } from "@mui/material";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import StripeNotificationView from "components/notifications/StripeNotification";
import SimpleHeader from "components/SimpleHeader";
import { StyledIonContent } from "pages/stores/styles";

import { useLoginController } from "./controller";
import LoginForm from "./LoginForm";
import { StyledMainGrid, StyledFooterGrid, muiLoginStyles } from "./styles";

const LoginView: React.FC = () => {
  const {
    control,
    register,
    handleSubmit,
    errors,
    onSubmit,
    isLoading,
    notification,
  } = useLoginController();

  const { progress } = muiLoginStyles();

  return (
    <IonPage>
      <SimpleHeader />
      <StyledIonContent>
        {notification?.message ? (
          <StripeNotificationView notification={notification} />
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledMainGrid container>
            <LoginForm register={register} errors={errors} control={control} />
          </StyledMainGrid>
        </form>
        <StyledFooterGrid container>
          <Grid item xs textAlign="center">
            <ButtonPrimaryFilled
              onClick={handleSubmit(onSubmit)}
              type="submit"
              data-test="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress style={progress} size={25} />
              ) : (
                "Entrar na conta"
              )}
            </ButtonPrimaryFilled>
          </Grid>
        </StyledFooterGrid>
      </StyledIonContent>
    </IonPage>
  );
};

export default LoginView;
