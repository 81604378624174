import { useMemo } from "react";
import { useLocation } from "react-router";

export const getFirstName = (name: string) => {
  const splitedName = name.split(" ");
  if (splitedName.length) {
    return splitedName[0];
  }
  return "-";
};

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function removeCharacters(value: string) {
  return value.replace(/\D/g, "");
}

// transform date-string in new date
export const parseSeparateDate = (date: string) => {
  const splitedDate = date.split("-");

  const year = splitedDate[0];
  const month = splitedDate[1];
  const day = splitedDate[2];

  return new Date(Number(year), Number(month), Number(day));
};
