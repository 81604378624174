import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { MaterialStyles } from "commons/types/generics/materialStyles";

export const productFilterContainerStyles = (): MaterialStyles => ({
  flex: {
    display: "flex",
  },
});

export const ProductFiltersContainer = styled(Grid)`
  margin: 15px 5px 0 5px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 450px) {
    width: 400px;
    text-align: center;
    margin: 15px auto;
  }
  @media (min-width: 600px) {
    width: 400px;
    text-align: center;
    overflow: unset;
    margin: auto;
    margin-top: -40px;
  }
`;
