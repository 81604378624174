import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme, useTheme } from "styled-components";

export const StorePaymentMehotdStyled = styled(InputBase)(() => {
  const theme = useTheme();

  const cssStyles: MaterialStyles = {
    "& .MuiInputBase-input": {
      /* appearance */
      backgroundColor: theme.color.neutralLight.default,
      height: "20px",
      padding: "14px 16px 10px 16px",
      boxShadow:
        "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);",
      /* font */
      fontFamily: "Inter",
      color: theme.color.grayscale[6],
      ...theme.typography.body[2].regular,
    },
  };

  return cssStyles;
});

export const inlineStyles = (theme: DefaultTheme): MaterialStyles => ({
  contentStyle: {
    backgroundColor: theme.color.grayscale[1],
    padding: "30px 20px",
  },
  paymentMethodText: {
    color: theme.color.grayscale[8],
    fontWeight: "bold",
  },
  formControlStyle: {
    marginTop: "8px",
  },
});
