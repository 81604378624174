import { NOTIFICATION_TYPES } from "commons/types/auth.enums";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const stripeNotificationStyles = (
  theme: DefaultTheme,
  type: NOTIFICATION_TYPES
): MaterialStyles => {
  let bgColor = theme.color.success.default;
  let textColor = "white";
  switch (type) {
    case NOTIFICATION_TYPES.SUCCESS:
      bgColor = theme.color.success.default;
      break;
    case NOTIFICATION_TYPES.WARNING:
      bgColor = theme.color.alert.default;
      textColor = theme.color.grayscale[10];
      break;

    case NOTIFICATION_TYPES.ERROR:
      bgColor = theme.color.error.default;
      break;
  }
  return {
    mainContainer: {
      backgroundColor: bgColor,
      color: textColor,
      padding: "20px",
    },
    icon: {
      marginTop: "2px",
    },
    message: {
      marginLeft: "10px",
    },
  };
};
