import circleCheck from "assets/svgs/icons/circle-check.svg";
import {
  CART_SAVE_ERROR,
  CART_SAVE_SUCCESS,
} from "commons/constants/cart.constants";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { useConfiguration } from "contexts/configuration";
import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import { useHistory } from "react-router";

import { SEGMENT_OPTIONS } from "./types";

export const useOrdersController = () => {
  const {
    selectedSuppliers,
    cartData,
    setCartId,
    setShouldLoadCart,
    setCurrentOrder,
    handleSaveCart,
    showError,
  } = useCart();
  const { user } = useAuth();
  const history = useHistory();
  const { presentToast } = useConfiguration();

  // Dialog states
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSaveCartDialogOpen, setIsSaveCartDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<number | null>(null);
  const [segment, setSegment] = useState<SEGMENT_OPTIONS>(SEGMENT_OPTIONS.OPEN);

  const handleChangeSegment = (option: SEGMENT_OPTIONS) => {
    setSegment(option);
  };

  const handleCloseSaveCartDialog = () => {
    setIsSaveCartDialogOpen(false);
  };

  const handleDoGoToDetails = (id: number) => {
    if (segment === SEGMENT_OPTIONS.DRAFT) {
      handleGoToCart(id);
    } else {
      handleGoToOrderDetails(id);
    }
  };
  const handleGoToDetails = (id: number) => {
    if (selectedSuppliers.length && !cartData.currentOrder) {
      setSelectedOrder(id);
      setIsSaveCartDialogOpen(true);
    } else {
      handleDoGoToDetails(id);
    }
  };

  const handleClearCartContext = () => {
    setShouldLoadCart(true);
    setCurrentOrder(null);
  };

  const handleGoToCart = (cartId: number) => {
    setCartId(cartId);
    handleClearCartContext();
    history.push("/stores/cart");
  };

  const handleGoToOrderDetails = (orderId: number) => {
    setCartId(null);
    handleClearCartContext();
    setTimeout(() => {
      history.push(`/orders/${orderId}`);
    }, 500);
  };

  const handleDoSaveCart = async () => {
    try {
      setIsSubmiting(true);
      if (user?.id) {
        const response: any = await handleSaveCart(user.id);
        if (response?.status !== StatusCodes.OK) {
          throw new Error(CART_SAVE_ERROR);
        } else {
          presentToast({
            message: CART_SAVE_SUCCESS,
            duration: 1500,
            color: "success",
            icon: circleCheck,
            position: "top",
            mode: "md",
          });

          if (selectedOrder) {
            handleDoGoToDetails(selectedOrder);
          }
        }
      }
    } catch (error) {
      showError(error.message);
      setIsSubmiting(false);
    }
  };

  return {
    segment,
    isSubmiting,
    isSaveCartDialogOpen,
    handleGoToDetails,
    handleChangeSegment,
    handleCloseSaveCartDialog,
    handleDoSaveCart,
  };
};
