import { IonContent } from "@ionic/react";
import styled from "styled-components";

export const StyledIonContent = styled(IonContent)`
  --background: ${({ theme }) => theme.color.grayscale[1]};
`;

export const StyledSelectItem = styled.div`
  width: 100%;
  max-width: 400px;
  display: block;
`;

export const StyledCardColumn = styled.div`
  margin: 8px 0px;
`;
