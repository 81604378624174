import { CartProvider } from "contexts/cart";
import { ConfigurationProvider } from "contexts/configuration";
import { FC } from "react";

import { useAuth } from "../contexts/auth";
import AuthRoutes from "./AuthRoutes";
import NonAuthRoutes from "./nonAuthRoutes";

const Routes: FC = () => {
  const auth = useAuth();
  if (auth.loadingUser || auth.isSigned === null) return null;

  return (
    <ConfigurationProvider>
      {auth.isSigned ? (
        <CartProvider>
          <AuthRoutes />
        </CartProvider>
      ) : (
        <NonAuthRoutes />
      )}
    </ConfigurationProvider>
  );
};

export default Routes;
