import { CartPostModel } from "commons/types/cart.model";

import { api } from "./api.service";

const MODULE_PATH = "cart";

export default class CartService {
  static async getMany({ page = 1, storeName }: CartGetMany) {
    const options = {
      params: {
        page,
        store_name: storeName,
      },
    };
    return api.get(`${MODULE_PATH}/`, options);
  }
  static async getOne(id: number) {
    return api.get(`${MODULE_PATH}/${id}/`);
  }
  static async create(data: CartPostModel) {
    return api.post(`${MODULE_PATH}/`, data);
  }
  static async update(id: number, data: CartPostModel) {
    return api.put(`${MODULE_PATH}/${id}/`, data);
  }
  static async delete(id: number) {
    return api.delete(`${MODULE_PATH}/${id}/`);
  }
  static async generatePdf(id: number) {
    try {
      const response = await api.get(`${MODULE_PATH}/${id}/download-pdf/`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Rascunho_${id}.pdf`);
      document.body.appendChild(link);
      link.click();

      return response;
    } catch (error) {
      return error;
    }
  }

  static async sendMailPdf(id: number, to?: string[]) {
    try {
      return await api.post(`${MODULE_PATH}/${id}/send-pdf-mail/`, {
        to,
      });
    } catch (error) {
      return error;
    }
  }
}

interface CartGetMany {
  page: number;
  status?: string;
  storeName?: string;
}
