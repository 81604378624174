import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";

export const StyledResponsiveContainer = styled(Grid)`
  justify-content: center;
`;

export const StyledCardsContainer = styled(Grid)`
  justify-content: center;
  align-items: center;
  align-content: center;
  @media (min-width: 600px) {
    display: inline-flex;
    max-width: 83.34%;
    flex-wrap: wrap;
    text-align: left;
  }
`;

export const StyledCardItem = styled(Grid)`
  margin: 16px 10px;
  @media (min-width: 600px) {
    margin: 16px 16px;
  }
`;
