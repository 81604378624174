import { useIonAlert, useIonToast } from "@ionic/react";
import { DJANGO_ERRORS } from "commons/constants/django.constants";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router";

import { ConfigurationContextProps, ConfigurationProps } from "./types";

const ConfigurationContext = createContext<ConfigurationContextProps>(
  {} as ConfigurationContextProps
);

export const ConfigurationProvider = ({ children }: ConfigurationProps) => {
  const [canShowTabs, setShowTabs] = useState(true);
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const noTabsPaths = ["/stores/cart", "microregions/stores/new"];
  const location = useLocation();
  const forcedLoad = new URLSearchParams(location.search).get("load");

  const redirectOrder = useMemo(() => {
    if (forcedLoad === "true") {
      const pathnameArray = location.pathname.split("/");
      return pathnameArray.length ? pathnameArray[2] : "";
    }
    return "";
  }, []);

  const presentError = (error: string, fallBackMessage: string) => {
    if (error != DJANGO_ERRORS.INVALID_PAGE) {
      presentAlert({
        header: "Alerta",
        message: fallBackMessage,
        buttons: ["OK"],
      });
    }
  };

  useEffect(() => {
    if (
      noTabsPaths.find((path) => location.pathname.includes(path)) ||
      location.pathname.includes("/orders/")
    ) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [location]);
  return (
    <ConfigurationContext.Provider
      value={{
        canShowTabs,
        setShowTabs,
        presentToast,
        presentAlert,
        presentError,
        redirectOrder,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export function useConfiguration(): ConfigurationContextProps {
  const context = useContext(ConfigurationContext);
  return context;
}
