import { Grid } from "@mui/material";
import FormPasswordInputView from "components/FormPasswordInput";
import Typography from "components/Typography";
import RecoverSectionTitleView from "pages/recoverPassword/sharedComponents/SectionTitle";
import { useTheme } from "styled-components";

import {
  StyledFormGrid,
  StyledFormHeaderContainer,
  StyledFormHeaderGrid,
} from "../../../styles";
import { ConfirmRecoverPasswordFormProps } from "./types";

const ConfirmRecoverPasswordForm = ({
  register,
  errors,
  control,
}: ConfirmRecoverPasswordFormProps) => {
  const theme = useTheme();

  return (
    <StyledFormGrid>
      <RecoverSectionTitleView shouldAddSpace />
      <StyledFormHeaderContainer container direction="column">
        <StyledFormHeaderGrid item xs data-test="heading">
          <Typography
            variant={theme.typography.heading[1].regular}
            color={theme.color.grayscale[11]}
          >
            Agora, escolha uma <b>nova senha</b>
          </Typography>
        </StyledFormHeaderGrid>
      </StyledFormHeaderContainer>

      <Grid container direction="column">
        <Grid item xs mb={5}>
          <Typography
            variant={theme.typography.body[2].regular}
            color={theme.color.grayscale[7]}
          >
            Digite uma nova senha abaixo
          </Typography>
        </Grid>

        <FormPasswordInputView
          field="password"
          title="Nova Senha"
          placeholder="Digite uma nova senha"
          register={register}
          control={control}
          errors={errors}
        />

        <FormPasswordInputView
          field="repeatPassword"
          title="Confirmação de senha"
          placeholder="Repita sua senha"
          register={register}
          control={control}
          errors={errors}
        />
      </Grid>
    </StyledFormGrid>
  );
};
export default ConfirmRecoverPasswordForm;
