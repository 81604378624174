import { Grid, MenuItem, Select } from "@mui/material";
import { SelectInputIconLeft } from "components/SelectInputIcon";
import Typography from "components/Typography";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "styled-components";

import FormErrorMessageView from "../FormErrorMessage";
import { formInputsStyles, FormSelectInput } from "../FormInput/styles";
import { FormSelectProps } from "./types";

const FormSelectView = ({
  field,
  title,
  selectOptions,
  errors,
  placeholder,
  control,
  defaultValue,
  disabled,
  isSelectingObject = false,
}: FormSelectProps) => {
  const theme = useTheme();
  const styles = formInputsStyles(theme);

  return (
    <Grid container direction="column" style={styles.inputContainner}>
      <Grid item xs>
        <Typography
          variant={theme.typography.body[2].regular}
          color={theme.color.grayscale[8]}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs marginTop="5px">
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              IconComponent={SelectInputIconLeft}
              input={<FormSelectInput />}
              variant="standard"
              displayEmpty
              fullWidth
              disabled={disabled}
              style={styles.inputSelect}
            >
              <MenuItem value="" style={styles.inputSelect}>
                <Fragment>
                  {placeholder} {<SelectInputIconLeft />}
                </Fragment>
              </MenuItem>
              {isSelectingObject
                ? selectOptions.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      style={styles.inputSelect}
                    >
                      {item.name}
                    </MenuItem>
                  ))
                : selectOptions.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item}
                      style={styles.inputSelect}
                    >
                      {item}
                    </MenuItem>
                  ))}
            </Select>
          )}
          control={control}
          name={field}
          defaultValue={defaultValue}
        />
      </Grid>

      {!!errors[field] ? (
        <FormErrorMessageView errors={errors} field={field} />
      ) : null}
    </Grid>
  );
};

export default FormSelectView;
