import { Button, CircularProgress, Grid } from "@mui/material";
import { ReactComponent as MailIconSVG } from "assets/svgs/icons/mail.svg";
import { ReactComponent as PrintIconSVG } from "assets/svgs/icons/print.svg";
import { ModalSendMail } from "components/ModalSendMail";
import Typography from "components/Typography";
import React from "react";

import { useStyles } from "./styles";
import { ButtonsPdfProps } from "./types";
export const ButtonsPdf: React.FC<ButtonsPdfProps> = ({
  onSubmit,
  isLoadingSubmit,
  handleCloseSendMailModal,
  isOpenSendMailModal,
  handleOpenSendMailModal,
  handleSendMail,
  email,
  id,
  isLoadingSendMailSubmit,
}) => {
  const styles = useStyles();
  return (
    <>
      <Grid item xs={6}>
        <Button
          onClick={handleOpenSendMailModal}
          variant="outlined"
          style={styles.pdfButton}
        >
          <MailIconSVG style={styles.icon} />
          <Typography style={styles.pdfTextButton}>Enviar por email</Typography>
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="outlined" style={styles.pdfButton} onClick={onSubmit}>
          {isLoadingSubmit ? (
            <CircularProgress size={15} />
          ) : (
            <>
              <PrintIconSVG style={styles.icon} />
              <Typography style={styles.pdfTextButton}>
                Imprimir pedido
              </Typography>
            </>
          )}
        </Button>
      </Grid>

      <ModalSendMail
        handleClose={handleCloseSendMailModal}
        isOpen={isOpenSendMailModal}
        email={email}
        handleSendMail={handleSendMail}
        id={id}
        isLoadingSendMailSubmit={isLoadingSendMailSubmit}
      />
    </>
  );
};
