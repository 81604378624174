import { Grid } from "@mui/material";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import styled from "styled-components";

export const StyledLink = styled("a")`
  text-decoration: none;
`;

export const StyledMainGrid = styled(Grid)`
  justify-content: normal;
  width: 100%;
  @media (min-width: 600px) {
    height calc(100vh - 142px);
    justify-content: center;
  }
`;

export const StyledFooterGrid = styled(Grid)`
  align-items: center;
  justify-content: center;
  padding: 18px;
  @media (min-width: 600px) {
    background-color: white;
    position: fixed,
    bottom: 0px;
  }
`;

export const StyledFormGrid = styled(Grid)`
  margin: 0px 20px;
  width: 100%;
  @media (min-width: 600px) {
    width: 488px;
  }
`;

export const StyledFormHeaderContainer = styled(Grid)`
  margin-top: 25px;
  margin-bottom: 35px;
`;

export const StyledFormHeaderGrid = styled(Grid)`
  width: 250px;
  @media (min-width: 600px) {
    width: 350px;
  }
`;

export const StyledLinkGrid = styled(Grid)`
  margin-top: -23px !important;
  margin-bottom: 30px;
`;

export const muiLoginStyles = (): MaterialStyles => ({
  progress: { color: "white" },
});
