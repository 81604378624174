import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import CardWithColumns from "components/CardWithColumns";
import Chubbs from "components/Chubbs";
import Searchbar from "components/Searchbar";
import SimpleHeader from "components/SimpleHeader";
import SimpleSubheader from "components/SimpleSubheader";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { useMicroregionsController } from "./controller";
import {
  ButtonContainer,
  CardsContainer,
  FilterContainer,
  StyledIonContent,
} from "./styles";

const MicroregionsView: React.FC = () => {
  const theme = useTheme();
  const controller = useMicroregionsController();

  const { microregions, handleClickDetails, handleClickNewStore } = controller;
  const { searchText, handleSearch, handleClearSearch } = controller;
  const { handleLoadData, isInfiniteDisabled, isLoading } = controller;

  return (
    <IonPage>
      <SimpleHeader />
      <StyledIonContent>
        <SimpleSubheader>
          <Typography variant={theme.typography.heading[2].regular}>
            Lojas por <b>microrregião</b>
          </Typography>
        </SimpleSubheader>
        <FilterContainer container>
          <Searchbar
            value={searchText}
            placeholder="Buscar microrregião..."
            onSearch={handleSearch}
            onClear={handleClearSearch}
          />
        </FilterContainer>
        <CardsContainer container>
          {!isLoading ? (
            microregions.length ? (
              microregions.map((region) => (
                <CardWithColumns
                  key={region.id}
                  title={`Região ${region.name}`}
                  items={region.cities?.map((city) => city.name) || []}
                  action={{
                    label: "Ver lojas",
                    handler: handleClickDetails(region),
                  }}
                />
              ))
            ) : (
              <Chubbs variant="not-found" />
            )
          ) : null}
        </CardsContainer>
        <ButtonContainer onClick={handleClickNewStore}>
          <ButtonPrimaryFilled>Cadastrar nova loja</ButtonPrimaryFilled>
        </ButtonContainer>
        <IonInfiniteScroll
          onIonInfinite={(e: CustomEvent<void>) => handleLoadData(e)}
          threshold="1%"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Carregando..."
          />
        </IonInfiniteScroll>
      </StyledIonContent>
    </IonPage>
  );
};

export default MicroregionsView;
