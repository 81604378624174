import { MaterialStyles } from "commons/types/generics/materialStyles";
import { useTheme } from "styled-components";

export const useStyles = (): MaterialStyles => {
  const theme = useTheme();
  return {
    pdfTextButton: {
      marginLeft: "5px",
      textTransform: "initial",
      fontSize: "14px",
    },
    icon: {
      width: "20px",
      height: "20px",
    },
    pdfButton: {
      width: "100%",
      minWidth: "160px",
      minHeight: "36px",
      backgroundColor: theme.color.neutralLight.default,
    },
  };
};
