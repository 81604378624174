import { useHistory } from "react-router";

const useOrderCancelledController = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/orders");
  };

  const handleOnClick = () => {
    history.replace("/stores");
  };

  return { handleOnClick, handleGoBack };
};

export default useOrderCancelledController;
