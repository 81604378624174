import styled from "styled-components";

// TODO: remove maxwidth from component
export const StyledDiv = styled.div`
  padding: 0px 10px;
  width: 100%;
  max-width: 400px;

  & .searchbar-input {
    text-align: left;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
  }
  & .searchbar-input-container > input {
    color: ${({ theme }) => theme.color.grayscale[6]};
    --placeholder-opacity: 1;
    border-radius: 4px;
    font-size: ${({ theme }) => theme.typography.body[2].regular.fontSize};
    font-weight: ${({ theme }) => theme.typography.body[2].regular.fontWeight};
  }
  & .searchbar-input-container ion-icon {
    color: ${({ theme }) => theme.color.grayscale[8]} !important;
  }
`;
