import { CircularProgress, Grid } from "@mui/material";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import { muiLoginStyles } from "pages/login/styles";

import { recoverButtonStyles } from "./styles";
import { RecoverButtonProps } from "./types";

const RecoverButtonView = ({
  isLoading,
  onSubmit,
  buttonText,
}: RecoverButtonProps) => {
  const { progress } = muiLoginStyles();
  const styles = recoverButtonStyles();

  return (
    <Grid item xs={12} style={styles.button}>
      <ButtonPrimaryFilled
        onClick={() => onSubmit()}
        data-test="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress style={progress} size={25} />
        ) : (
          buttonText
        )}
      </ButtonPrimaryFilled>
    </Grid>
  );
};

export default RecoverButtonView;
