import { IonIcon } from "@ionic/react";
import { Grid } from "@mui/material";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { infoCardStyles } from "./styles";
import { InfoCardProps } from "./types";

const InfoCard: React.FC<InfoCardProps> = ({
  icon,
  title,
  value,
  unwraped = false,
}) => {
  const theme = useTheme();

  const { iconStyle, titleStyle, valueStyle } = infoCardStyles(theme);

  return (
    <Grid item xs={8} ml={3} mr={2} mb={2}>
      <Grid item xs={12}>
        <IonIcon icon={icon} color="dark" style={iconStyle} />
        <Typography
          variant={theme.typography.body[3].highlight}
          style={titleStyle}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} ml={3} mt={0.5}>
        {!unwraped ? (
          <Typography
            variant={theme.typography.body[3].regular}
            style={valueStyle}
          >
            {value}
          </Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  );
};

export default InfoCard;
