import styled from "@emotion/styled";

export const ImageSliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
  margin-right: -30px;
  margin-left: -48px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & img {
    max-height: 256px;
    width: auto;
    border-radius: 10px;
  }
`;
