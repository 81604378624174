import { IonCard, IonCol, IonRow } from "@ionic/react";
import React from "react";
import styled from "styled-components";

export const StyledIonCard = styled(IonCard)`
  padding: unset;
  margin: 0 !important;
  border-radius: 8px;
  min-width: 332px;
  max-width: 400px;
  max-height: 100px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14) !important;
  @media (min-width: 700px) {
    display: inline-block;
    width: 360px;
  }
`;

export const StyledIonColInfo = styled(IonCol)`
  padding: 4px 4px 0px 4px;
`;

export const StyledIonCol = styled(IonCol)`
  text-align: left;
`;

export const StyledImg = styled.img`
  object-fit: cover;
  height: 100px;
  width: 100px;
`;

export const typographyBaseStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginBottom: "4px",
} as React.CSSProperties;

export const typographyTitleStyle = {
  ...typographyBaseStyle,
  margin: "10px 0px 4px 0px",
} as React.CSSProperties;

export const StyledNameIonRow = styled(IonRow)`
  min-width: 0;
`;
