import { IonText } from "@ionic/react";
import { FC } from "react";
import { useTheme } from "styled-components";

import { StyledTypography } from "./styles";
import { TypographyProps } from "./types";

const Typography: FC<TypographyProps> = ({
  color,
  variant,
  children,
  style,
  unwrapped = false,
}) => {
  const theme = useTheme();
  return !unwrapped ? (
    <IonText color={color}>
      <StyledTypography
        variant={variant || theme.typography.body[1].regular}
        style={{ color: color, ...style }}
      >
        {children}
      </StyledTypography>
    </IonText>
  ) : (
    <StyledTypography
      variant={variant || theme.typography.body[1].regular}
      style={{ color: color, ...style }}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
