import { Box, CardActions, CardContent, Grid } from "@mui/material";
import Link from "components/Link";
import Typography from "components/Typography";
import { useMemo } from "react";
import { useTheme } from "styled-components";

import {
  boxStyles,
  StyledCardContainer,
  StyledDivider,
  typographyItemStyle,
  typographyStyle,
} from "./styles";
import { CardWithColumnsProps } from "./types";

const CardWithColumns = ({ title, items, action }: CardWithColumnsProps) => {
  const theme = useTheme();

  const { column1, column2 } = useMemo(() => {
    const count = items.length;
    const defaultBreak = 9;
    let _items = items;
    const columnCount = Math.round(count / 2);
    let breakPoint = columnCount < 4 ? 4 : columnCount;
    if (count > defaultBreak) {
      _items = _items.splice(0, defaultBreak);
      _items.push("...");
      breakPoint = 5;
    }

    return {
      column1: count > 4 ? [..._items].splice(0, breakPoint) : _items,
      column2: count > 4 ? [..._items].splice(breakPoint, count) : [],
    };
  }, [items]);

  return (
    <StyledCardContainer onClick={action.handler}>
      <CardContent>
        <Typography
          variant={theme.typography.subheading[2].highlight}
          color={theme.color.neutralDark.default}
        >
          {title}
        </Typography>
        <Grid>
          <StyledDivider />
        </Grid>
        <Grid container spacing={2} columns={16}>
          <Grid item xs={8}>
            <Box sx={column2.length ? boxStyles(theme) : null}>
              {column1.map((item) => (
                <Grid item key={item}>
                  <Typography
                    variant={theme.typography.body[3].regular}
                    color={theme.color.grayscale[7]}
                    style={typographyStyle()}
                  >
                    <span style={typographyItemStyle()}>{item}</span>
                  </Typography>
                </Grid>
              ))}
            </Box>
          </Grid>
          {column2.length ? (
            <Grid item xs={8} pl={-2}>
              {column2.map((item) => (
                <Grid item key={item}>
                  <Typography
                    variant={theme.typography.body[3].regular}
                    color={theme.color.grayscale[7]}
                    style={typographyStyle()}
                  >
                    <span style={typographyItemStyle()}>{item}</span>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      {action && (
        <CardActions>
          <Link handleClick={action.handler} title={action.label} />
        </CardActions>
      )}
    </StyledCardContainer>
  );
};

export default CardWithColumns;
