import { useCart } from "contexts/cart";
import { AddedProductVariantValues } from "pages/products/ProductDetailsView/types";
import { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router";

export const useSupplierDetailsController = () => {
  const history = useHistory();
  const { selectedSuppliers, handleRemoveProduct, handleRemoveSupplier } =
    useCart();
  const { id, orderId } = useParams<{ id: string; orderId: string }>();
  const { state } = useLocation<{
    isEditingOrder: boolean;
    canEdit?: boolean;
  }>();
  // Query param variables
  const canShowActions =
    state?.isEditingOrder === undefined ||
    (!!state?.isEditingOrder && !!state?.canEdit);

  const isEditingOrder = state?.isEditingOrder !== undefined;

  // Memos
  const supplier = useMemo(() => {
    const index = selectedSuppliers.findIndex((item) => item.id === Number(id));
    if (index < 0) return null;
    return selectedSuppliers[index];
  }, [id, selectedSuppliers]);

  const productItems = useMemo(() => {
    if (!supplier) return [];
    return supplier.selectedProducts;
  }, [supplier]);

  const handleGoToSupplierProducts = (supplierId: number) => {
    history.push(`/stores/product/supplier/${supplierId}`, { id: supplierId });
  };

  const handleGoToProductDetails = (
    productId: number,
    variant: AddedProductVariantValues
  ) => {
    history.push(`/stores/supplier/${id}/product/${productId}`, {
      isEditingCart: true,
      variant,
    });
  };

  const handleReturnToOrder = () => {
    history.push(`/orders/${orderId}`, {
      isEditingOrder: state?.isEditingOrder,
    });
  };

  const handleDoRemoveProduct = (id: number, sku: number) => {
    handleRemoveProduct(id, sku, !!state?.isEditingOrder);
  };

  useEffect(() => {
    if (supplier?.selectedProducts?.length) return;

    handleRemoveSupplier(Number(id), !!state?.isEditingOrder);
    history.goBack();
  }, [handleRemoveProduct]);

  return {
    isEditingOrder,
    canShowActions,
    supplier,
    productItems,
    handleRemoveProduct: handleDoRemoveProduct,
    handleRemoveSupplier,
    handleGoToSupplierProducts,
    handleGoToProductDetails,
    handleReturnToOrder,
  };
};
