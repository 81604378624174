import { SelectedProduct } from "contexts/cart/types";

import { StoreModel } from "./store.model";

export enum ORDER_STATUS {
  SENT_TO_THE_OFFICE = "Enviado",
  IN_PROGRESS = "No admin",
  ORDER_IN_SUPPLIER = "Na fábrica",
  BILLED = "Faturado",
  CANCELED = "Cancelado",
}

export interface OrderSupplierProduct {
  product_id: number;
  sku_id: number;
  quantity: number;
  label?: string;
  order_product_id?: number;
}

export interface OrderSupplier {
  supplier_id: number;
  payment_method_id: number;
  products: OrderSupplierProduct[];
}

export interface OrderPostModel {
  user_id: number;
  store_id: number;
  payment_date: string;
  observation: string;
  suppliers: OrderSupplier[];
}

export interface OrderPostModel {
  user_id: number;
  store_id: number;
  cart_id?: number | null;
  payment_date: string;
  observation: string;
  suppliers: OrderSupplier[];
}

export interface OrderChangeStatusModel {
  status: ORDER_STATUS;
}

export interface OrdersListModel {
  id: number;
  user: number;
  store: number;
  status: ORDER_STATUS;
  total_sale: number;
  fantasy_name: string;
  company_name: string;
  supplier_names: string[];
  store_logo: string;
  quantity_items: number;
}

export interface OrderModel {
  id: number;
  user: number;
  store: StoreModel;
  status: ORDER_STATUS;
  total_sale: number;
  fantasy_name: string;
  company_name: string;
  created_at: string;
  updated_at: string;
  zipcode: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: number;
  complement: string;
  payment_date: string;
  observation: string;
}

export interface OrderProductModel {
  suppliers: OrderProductSupplierModel[];
  total_order: number;
}

interface OrderProductSupplierModel {
  id: number;
  company_name: string;
  fantasy_name: string;
  logo: string;
  minimum_order_total: number;
  selectedProducts: SelectedProduct[];
  supplierTotal: number;
}
