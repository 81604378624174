/* eslint-disable @typescript-eslint/naming-convention */
import { IonIcon } from "@ionic/react";
import { Divider, Grid } from "@mui/material";
import { formatCPForCNPJ } from "commons/utils/format";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import InfoCard from "components/InfoCard";
import Typography from "components/Typography";
import {
  callOutline,
  locationOutline,
  lockClosedOutline,
  logoWhatsapp,
  mailOutline,
  pencilOutline,
  personOutline,
} from "ionicons/icons";
import { useTheme } from "styled-components";

import { accountInfoStyles } from "./styles";
import { AccountInfoProps } from "./types";

const AccountInfo: React.FC<AccountInfoProps> = ({ user, handleGoToPage }) => {
  const theme = useTheme();
  const { containerStyle } = accountInfoStyles();

  const { address, phone, email, identification_number, whatsapp } = user;

  return (
    <Grid container spacing={1} direction="column" style={containerStyle}>
      <Grid item xs={8} mt={2} ml={3}>
        <Typography
          variant={theme.typography.body[2].highlight}
          color={theme.color.grayscale[7]}
        >
          Suas informações
        </Typography>
      </Grid>
      <Grid item xs={8} my={1.5} ml={3} mr={3}>
        <Divider />
      </Grid>
      <InfoCard
        icon={personOutline}
        title="CPF/CNPJ"
        value={formatCPForCNPJ(identification_number)}
      />
      <InfoCard icon={mailOutline} title="Email" value={email} />
      {whatsapp ? (
        <InfoCard icon={logoWhatsapp} title="WhatsApp" value={whatsapp} />
      ) : null}
      <InfoCard icon={callOutline} title="Telefone" value={phone} />
      {address ? (
        <InfoCard
          icon={locationOutline}
          title="Endereço"
          value={`${address.street} ${address.number}, ${address.city} - ${address.state}`}
        />
      ) : null}
      <Grid item my={3}>
        <Grid container>
          <Grid item width={140} ml={2.5} mr={1.5}>
            <ButtonPrimaryFilled onClick={handleGoToPage("/account/edit")}>
              <IonIcon icon={pencilOutline} />
              <Grid mr={1} />
              <Typography variant={theme.typography.body[3].highlight}>
                Editar conta
              </Typography>
            </ButtonPrimaryFilled>
          </Grid>
          <Grid item width={140}>
            <ButtonPrimaryOutlined
              onClick={handleGoToPage("/account/change-password")}
            >
              <IonIcon icon={lockClosedOutline} />
              <Grid mr={1} />
              <Typography variant={theme.typography.body[3].highlight}>
                Alterar senha
              </Typography>
            </ButtonPrimaryOutlined>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountInfo;
