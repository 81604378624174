import { Card, IconButton, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ptBR as bgBG } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import calendarIcon from "assets/svgs/calendar.svg";
import FormErrorMessageView from "components/FormErrorMessage";
import Typography from "components/Typography";
import { pt } from "date-fns/locale";
import { Controller } from "react-hook-form";
import { useTheme } from "styled-components";
import { globalStyles } from "theme/global.styles";

import { useFormDateInputController } from "./controller";
import { formDateInputsStyles } from "./styles";
import { FormDateInput } from "./types";
const FormDateInputView = ({
  control,
  field,
  format = "dd/MM/yyyy",
  title = "",
  // Passing an space so the "label default value" doesn't show
  label = " ",
  errors,
}: FormDateInput) => {
  const theme = useTheme();
  const styles = formDateInputsStyles();
  const { fullWidth } = globalStyles();

  const { isOpen, handleToggleOpen } = useFormDateInputController();

  return (
    <Grid container direction="column" style={styles.inputContainner}>
      <Grid item xs mb={1}>
        <Typography
          variant={theme.typography.body[2].regular}
          color={theme.color.grayscale[8]}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs style={styles.cardContainer}>
        <Card>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={pt}
            localeText={
              bgBG.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <Controller
              control={control}
              name={field}
              render={({ field }) => (
                <MobileDatePicker
                  open={isOpen}
                  onOpen={handleToggleOpen}
                  onClose={handleToggleOpen}
                  value={field.value}
                  label={label}
                  inputFormat={format}
                  onChange={(newValue) => field.onChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      style={fullWidth}
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                handleToggleOpen();
                              }}
                            >
                              <img src={calendarIcon} style={styles.icon} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </Card>
      </Grid>

      {!!errors[field] ? (
        <FormErrorMessageView errors={errors} field={field} />
      ) : null}
    </Grid>
  );
};

export default FormDateInputView;
