import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import { MaterialStyles } from "commons/types/generics/materialStyles";

export const SupplierCardContainer = styled(Card)`
  box-shadow: unset;

  @media (min-width: 600px) {
    margin: 20px;
  }
`;

export const supplierCardStyles = (): MaterialStyles => ({
  mainGrid: {
    padding: "20px",
  },
  actionButtonsContainer: {
    padding: "10px 5px",
    maxWidth: 400,
  },
  selectGrid: {
    padding: "15px 10px 0px 14px",
    maxWidth: 400,
  },
  primaryButton: {
    padding: "6px 18px",
    textTransform: "none",
    textDecoration: "unset",
    borderRadius: 6,
    boxShadow: "none",
  },
  secondaryButton: {
    padding: "5px 18px",
    textTransform: "none",
    textDecoration: "unset",
    borderRadius: 6,
    boxShadow: "none",
  },
  removeButton: {
    textTransform: "none",
    textDecoration: "unset",
    borderBottom: "1px solid #c93030",
    padding: 0,
    borderRadius: 0,
    width: "auto",
    minWidth: 0,
    height: 20,
  },
  buttonContainer: {
    margin: "20px 10px 0 5px",
  },
  icon: {
    marginTop: 0.8,
    marginRight: 1,
    marginLeft: 2,
  },
});
