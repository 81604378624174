import {
  DIFFERENT_PASSWORD,
  REQUIRED_FIELD,
} from "commons/constants/form.message.constants";
import * as Yup from "yup";

export const passwordChangeSchema = Yup.object().shape({
  old_password: Yup.string().trim().required(REQUIRED_FIELD),
  new_password: Yup.string().trim().required(REQUIRED_FIELD),
  confirm_password: Yup.string()
    .trim()
    .required(REQUIRED_FIELD)
    .test(
      "password-match",
      DIFFERENT_PASSWORD,
      (value, context) => context.parent.new_password === value
    ),
});
