import { IonHeader } from "@ionic/react";
import { FC } from "react";

import { HeaderTitle, StyledToolbar } from "./styles";

const SimpleHeader: FC = () => (
  <IonHeader>
    <StyledToolbar>
      <HeaderTitle>Dank</HeaderTitle>
    </StyledToolbar>
  </IonHeader>
);

export default SimpleHeader;
