import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const cartStyles = (
  theme: DefaultTheme,
  isTablet: boolean
): MaterialStyles => ({
  cartTitle: {
    padding: "35px 0 0 40px",
  },
  tabletCartSaveBtn: {
    marginRight: "20px",
  },
  detailsTitle: {
    padding: isTablet ? "40px 0 0 50px" : "40px 0 0 40px",
  },
  supplierCounterTitle: {
    padding: isTablet ? "0 25px" : "16px 25px",
  },
  detailsContainer: {
    padding: isTablet ? "20px 20px 0 50px" : "20px 20px 0 40px",
    maxWidth: 400,
  },
  footer: {
    padding: "20px",
    backgroundColor: theme.color.neutralLight.default,
  },
});
