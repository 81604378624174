import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import styledComponents from "styled-components";
import { useTheme } from "styled-components";

export const FormControlContainer = styled(FormControl)`
  display: flex;
  width: 100%;
`;

export const SelectBaseInput = styled(InputBase)(() => {
  const theme = useTheme();

  return {
    "& .MuiInputBase-input": {
      backgroundColor: theme.color.neutralLight.default,
      borderRadius: "4px",
      color: theme.color.grayscale[6],
      ...theme.typography.body[2].regular,
      lineHeight: "30px",
      padding: "6px 55px 6px 15px !important",
      width: "100%",
      textAlign: "left",
      boxShadow:
        "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.color.neutralLight.default,
    },
    "& .MuiSelect-nativeInput": {
      minWidth: "0px !important",
    },
    "&.Mui-focused": {
      backgroundColor: theme.color.neutralLight.default,
    },
  };
});

export const StyledTitle = styledComponents.div`
  padding-bottom: 5px
`;
