import { MicroregionModel } from "commons/types/store.model";

export const getAvailableCities = (userMicroregions: MicroregionModel[]) => {
  // TODO: change to (ID,name) tuple whenever the schema is normalized
  return userMicroregions
    .map((region) => region.cities.map((city) => city.name))
    .flat();
};

export const getMicroregionByCity = (
  userMicroregions: MicroregionModel[],
  cityName?: string
): MicroregionModel | undefined => {
  if (!cityName) return;
  return userMicroregions.find((micro) =>
    micro.cities.find((c) => c.name === cityName)
  );
};

export const isValidCNPJ = (cnpj?: string) => {
  if (!cnpj) return false;

  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "" || cnpj.length !== 14) return false;

  for (let i = 0; i <= 9; i++) {
    const digit = String(i);
    const str = digit.repeat(14);
    if (cnpj === str) return false;
  }

  let lenght = cnpj.length - 2;
  let numbers = cnpj.substring(0, lenght);
  const digits = cnpj.substring(lenght);
  let sum = 0;
  let pos = lenght - 7;
  for (let i = lenght; i >= 1; i--) {
    sum += Number(numbers[lenght - i]) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== Number(digits[0])) return false;

  lenght = lenght + 1;
  numbers = cnpj.substring(0, lenght);
  sum = 0;
  pos = lenght - 7;
  for (let i = lenght; i >= 1; i--) {
    sum += Number(numbers[lenght - i]) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== Number(digits[1])) return false;

  return true;
};

export const isValidCPF = (cpf?: string) => {
  if (!cpf) return false;

  cpf = cpf.replace(/\D+/g, "");

  if (cpf === "" || cpf.length !== 11) return false;

  for (let i = 0; i <= 9; i++) {
    const digit = String(i);
    const str = digit.repeat(11);
    if (cpf === str) return false;
  }

  let sum;
  let rest;
  sum = 0;

  for (let i = 1; i <= 9; i++)
    sum += Number(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== Number(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += Number(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== Number(cpf.substring(10, 11))) return false;

  return true;
};

export const uniqByField = (array: any[], field: string) => {
  try {
    return (array || []).reduce((prev: any, current) => {
      if (prev.filter((obj: any) => obj[field] === current[field]).length > 0) {
        return prev;
      }
      prev.push(current);
      return prev;
    }, []);
  } catch (error) {
    throw error;
  }
};

export const distinct = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

export const shortenText = (text: string, length: number) => {
  if (text.length <= length) return text;
  return `${text.substring(0, length)}...`;
};
