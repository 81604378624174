import { ErrorMessage } from "@hookform/error-message";
import { IonIcon } from "@ionic/react";
import { Grid } from "@mui/material";
import { closeCircleOutline } from "ionicons/icons";
import { useTheme } from "styled-components";

import { errorMessageStyles } from "./styles";
import { FornErrorMessageProps } from "./types";

const FormErrorMessageView = ({ field, errors }: FornErrorMessageProps) => {
  const theme = useTheme();
  const { mainContainer } = errorMessageStyles(theme);
  return (
    <Grid container alignItems="center" mt={0.5}>
      <Grid item mt={0.3}>
        <IonIcon icon={closeCircleOutline} color="danger" />
      </Grid>
      <Grid item data-test={`${field}-error-message`}>
        <ErrorMessage
          errors={errors}
          name={field}
          as={<div style={mainContainer} />}
        />
      </Grid>
    </Grid>
  );
};

export default FormErrorMessageView;
