import { MaterialStyles } from "commons/types/generics/materialStyles";

export const changePasswordStyles = (): MaterialStyles => ({
  footerStyle: {
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.07)",
    backgroundColor: "white",
  },
  header: {
    width: 270,
    "@media (min-width: 400px)": {
      width: 370,
    },
  },
  progress: { color: "white" },
});
