import { IonContent, IonPage } from "@ionic/react";
import BackButtonHeader from "components/BackButtonHeader";
import ListHeaderBig from "components/surfaces/ListHeaderBig";
import { RouteComponentProps } from "react-router";

import StoreInfos from "./components/StoreInfos";
import StorePaymentMethod from "./components/StorePaymentMethod";
import { useStoreDetailController } from "./controller";

const StoreDetailView = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const {
    store,
    storeData: { imageUrl, title, subtitle, subtexts },
    paymentMethods,
    selectedPaymentMethod,
    handleSelectPaymentMethod,
    isLoading,
  } = useStoreDetailController(match.params.id);

  if (isLoading) {
    return (
      <IonPage>
        <BackButtonHeader />
      </IonPage>
    );
  }

  return (
    <IonPage>
      <BackButtonHeader />
      <IonContent>
        <ListHeaderBig
          imageUrl={imageUrl}
          title={title}
          subtitle={subtitle}
          subtexts={subtexts}
        />
        <StorePaymentMethod
          paymentMethods={paymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          handleSelectPaymentMethod={handleSelectPaymentMethod}
        />
        <StoreInfos store={store} />
      </IonContent>
    </IonPage>
  );
};

export default StoreDetailView;
