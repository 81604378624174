import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
} from "@ionic/react";
import { formatCurrency } from "commons/utils/format";
import Chubbs from "components/Chubbs";
import Searchbar from "components/Searchbar";
import CardSmall from "components/surfaces/CardSmall";
import { Fragment } from "react";

import { ProductFiltersContainer } from "../styles";
import {
  StyledCardItem,
  StyledCardsContainer,
  StyledResponsiveContainer,
} from "./styles";
import { ListSuppliersProps } from "./types";

const ListSuppliersView = ({
  suppliers,
  searchText,
  handleSearchText,
  handleClearText,
  handleLoadData,
  isInfiniteDisabled,
  handleClickSupplier,
  isLoadingSuppliers,
}: ListSuppliersProps) => {
  if (isLoadingSuppliers) {
    return <IonLoading isOpen={isLoadingSuppliers} message={"Carregando..."} />;
  }

  return (
    <Fragment>
      <ProductFiltersContainer container>
        <Searchbar
          value={searchText}
          onSearch={handleSearchText}
          onClear={handleClearText}
          placeholder="Buscar fábrica..."
        />
      </ProductFiltersContainer>
      <StyledResponsiveContainer container>
        <StyledCardsContainer item>
          {suppliers.length ? (
            suppliers.map((supplier) => (
              <StyledCardItem key={supplier.id}>
                <CardSmall
                  logo={supplier.logo}
                  title={supplier.fantasy_name}
                  subtitle={`Pedido mínimo ${formatCurrency(
                    supplier.minimum_order_total
                  )}`}
                  linkText="Ver produtos"
                  handleClick={handleClickSupplier(supplier)}
                />
              </StyledCardItem>
            ))
          ) : (
            <Chubbs variant="not-found" />
          )}
          <IonInfiniteScroll
            onIonInfinite={handleLoadData}
            threshold="1%"
            disabled={isInfiniteDisabled}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Carregando..."
            />
          </IonInfiniteScroll>
        </StyledCardsContainer>
      </StyledResponsiveContainer>
    </Fragment>
  );
};

export default ListSuppliersView;
