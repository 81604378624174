import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { Grid } from "@mui/material";
import Chubbs from "components/Chubbs";
import { orderStyles, StyledSearchbar } from "pages/orders/styles";

import OrderCardView from "../OrderCard";
import { OrderCardsListProps } from "./types";

const OrderListView = ({
  handleGoToDetails,
  isInfiniteDisabled,
  isLoading,
  search,
  orders,
  handleLoadData,
  handleSearchText,
  handleClearText,
}: OrderCardsListProps) => {
  const { section } = orderStyles();

  if (isLoading) {
    return null;
  }

  return (
    <Grid container sx={section}>
      <Grid container sx={section}>
        <Grid item>
          <StyledSearchbar
            mode="md"
            value={search}
            onIonChange={(e) => handleSearchText(e.detail.value || "")}
            onIonClear={() => handleClearText()}
            placeholder="Buscar loja ou pedido"
            debounce={2000}
          />
        </Grid>
      </Grid>

      {orders.length ? (
        orders.map((order) => (
          <Grid key={order.id} item m={1}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <OrderCardView
                  key={order.id}
                  {...order}
                  handleGoToDetails={() => handleGoToDetails(order.id)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Chubbs variant="not-found" />
      )}

      <IonInfiniteScroll
        onIonInfinite={handleLoadData}
        threshold="1%"
        disabled={isInfiniteDisabled}
      >
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Carregando..."
        />
      </IonInfiniteScroll>
    </Grid>
  );
};

export default OrderListView;
