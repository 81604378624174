import {
  Grid,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
} from "@mui/material";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { customStepperStyles, StepIconRoot } from "./styles";
import { CustomStepperProps } from "./types";

const CustomStepIcon = ({ active, completed, className }: StepIconProps) => {
  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? (
        <div className="custom-step-icon-circle checked" />
      ) : active ? (
        <div className="custom-step-icon-circle active" />
      ) : (
        <div className="custom-step-icon-circle" />
      )}
    </StepIconRoot>
  );
};

const CustomStepper = ({
  activeStep,
  numberOfSteps,
  showInfo,
}: CustomStepperProps) => {
  const steps = Array.from(Array(numberOfSteps).keys());
  const theme = useTheme();
  const { stepperText } = customStepperStyles();

  return (
    <Grid width="90px" margin="auto" marginRight="25px">
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<StepConnector className="hide" />}
      >
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel StepIconComponent={CustomStepIcon} />
            </Step>
          );
        })}
      </Stepper>
      {showInfo && (
        <Typography
          variant={theme.typography.body[4].regular}
          color={theme.color.grayscale[7]}
          style={stepperText}
        >
          <span className="text-ellipsis">
            {`Passo ${activeStep + 1} de ${numberOfSteps} `}
          </span>
        </Typography>
      )}
    </Grid>
  );
};

export default CustomStepper;
