export const LOGIN_SUCCESS = "Login realizado com sucesso";
export const CHANGE_PASSWORD_ERROR = "Houve uma falha ao alterar a senha.";
export const OLD_PASSWORD_INVALID = "Senha atual incorreta.";
export const PASSWORD_UPDATE_SUCCESS = "Senha atualizada com sucesso!";
export const INVALID_EMAIL = "Email inválido.";

export const TOKEN_INVALID = "Token inválido.";

export enum DJANGO_PASSWORD_RECOVER_ERROR {
  WRONG_EMAIL = "We couldn't find an account associated with that email. Please try a different e-mail address.",
}
