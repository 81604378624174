import { ButtonProps } from "@mui/material";
import { useTheme } from "styled-components";

import { buttonPrimaryStyle, ButtonPrimaryStyled } from "./styles";

export default ({ children, ...props }: ButtonProps) => {
  const theme = useTheme();

  return (
    <ButtonPrimaryStyled
      variant="contained"
      style={buttonPrimaryStyle(theme)}
      {...props}
    >
      {children}
    </ButtonPrimaryStyled>
  );
};
