import { DefaultTheme } from "styled-components";

export const ionTabBarStyle = (theme: DefaultTheme) => ({
  "--background": theme.color.neutralLight.default,
  "--background-focused": theme.color.neutralLight.default,
  "--border": "none",
  boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.07)",
  height: "60px",
});

export const ionTabButtonStyle = (theme: DefaultTheme) => ({
  "--color": theme.color.grayscale[8],
  paddingTop: "4px",
  paddingBottom: "8px",
});

export const ionTabButtonIconStyle = () => ({
  fontSize: "24px",
});

export const ionLabelStyle = (theme: DefaultTheme) => ({
  ...theme.typography.body[4].highlight,
});
