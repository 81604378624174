import ConfirmRecoverPasswordView from "pages/recoverPassword/ConfirmRecoverPasswordView";
import RecoverPasswordView from "pages/recoverPassword/RecoverPasswordView";
import ResendRecoverPasswordView from "pages/recoverPassword/ResendRecoveryPasswordView";
import { FC, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import LoginView from "../pages/login";

const NonAuthRoutes: FC = () => (
  <Fragment>
    <Switch>
      <Route path="/login" component={LoginView} />
      <Route
        exact
        path="/recover-password/confirm"
        component={() => <ConfirmRecoverPasswordView />}
      />
      <Route
        exact
        path="/recover-password/resend"
        component={() => <ResendRecoverPasswordView />}
      />
      <Route path="/recover-password" component={RecoverPasswordView} />
      <Route exact path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  </Fragment>
);

export default NonAuthRoutes;
