import { SyntheticEvent } from "react";
import { useHistory } from "react-router";

export const useBackButtonController = () => {
  const history = useHistory();

  const handleClickBack = (e: SyntheticEvent) => {
    e.preventDefault();
    history.goBack();
  };

  return { handleClickBack };
};
