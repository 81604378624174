import { Grid } from "@mui/material";
import circleCheck from "assets/svgs/icons/circle-check.svg";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { stripeNotificationStyles } from "./styles";
import { StripeNotificationProps } from "./types";

const StripeNotificationView = ({
  notification,
  icon,
}: StripeNotificationProps) => {
  const theme = useTheme();
  const styles = stripeNotificationStyles(theme, notification.type);

  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      style={styles.mainContainer}
    >
      <Grid item xs={1}>
        {icon ? (
          <div style={styles.icon}>{icon}</div>
        ) : (
          <img src={circleCheck} style={styles.icon} />
        )}
      </Grid>
      <Grid item xs style={styles.message}>
        <Typography variant={theme.typography.body[2].highlight}>
          {notification.message}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StripeNotificationView;
