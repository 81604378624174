import { CartDetailsModel, CartSupplier } from "commons/types/cart.model";
import { OrderSupplier, OrderSupplierProduct } from "commons/types/order.model";
import { distinct } from "commons/utils/general";

import { SelectedProduct, SelectedSupplier } from "./types";

const mapProducts = (selectedProducts: SelectedProduct[]) => {
  const products: OrderSupplierProduct[] = [];
  selectedProducts.forEach((prod) => {
    if (prod.addedProductVariants) {
      const _prods = Object.values(prod.addedProductVariants);
      return _prods.forEach((p) => {
        products.push({
          product_id: prod.id,
          sku_id: p.sku,
          quantity: p.quantity,
          label: p.label,
          order_product_id: p.order_product_id,
        });
      });
    }
  });
  return products;
};

export const mapSuppliersToOrder = (selectedSuppliers: SelectedSupplier[]) => {
  const orderSuppliers: OrderSupplier[] = selectedSuppliers.map((supplier) => {
    if (
      !supplier.selectedPaymentMethod ||
      !supplier.selectedPaymentMethod.supplierPaymentMethodId
    ) {
      throw new Error(
        `Fabrica "${supplier.fantasy_name}" não possui forma de pagamento selecionada`
      );
    }
    const products: OrderSupplierProduct[] = mapProducts(
      supplier.selectedProducts
    );
    return {
      supplier_id: supplier.id,
      payment_method_id: supplier.selectedPaymentMethod.supplierPaymentMethodId,
      products,
    };
  });
  return orderSuppliers;
};

export const mapDetailsToCart = (
  selectedSuppliers: SelectedSupplier[]
): CartDetailsModel => {
  const supplierNames: string[] = [];
  const cartSuppliers: CartSupplier[] = selectedSuppliers.map((supplier) => {
    const products: OrderSupplierProduct[] = mapProducts(
      supplier.selectedProducts
    );
    supplierNames.push(supplier.fantasy_name);
    return {
      supplier_id: supplier.id,
      supplier_name: supplier.fantasy_name,
      payment_method_id:
        supplier?.selectedPaymentMethod?.supplierPaymentMethodId,
      products,
    };
  });
  return {
    suppliers: cartSuppliers,
    supplier_names: supplierNames.filter(distinct),
  };
};
