import FormInputView from "components/FormInput";
import { FormInputProps } from "components/FormInput/types";

import { useFormPasswordController } from "./controller";

const FormPasswordInputView = (props: FormInputProps) => {
  const { isPasswordVisible, togglePasswordVisibility, eyeIcon } =
    useFormPasswordController();

  return (
    <FormInputView
      {...props}
      type={isPasswordVisible ? "text" : "password"}
      endIcon={eyeIcon}
      endBtnAction={togglePasswordVisibility}
    />
  );
};

export default FormPasswordInputView;
