import { useIonAlert } from "@ionic/react";
import { USER_LOAD_ERROR } from "commons/constants/message.constants";
import { AUTH_DJANGO_CODES } from "commons/types/auth.enums";
import { UserLoggedInModel } from "commons/types/user.model";
import { StatusCodes } from "http-status-codes";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import AuthService, {
  getCurrentUser,
  getToken,
  logout,
  setAuthToken,
  storeToken,
  storeUser,
} from "services/auth.service";
import { AuthDTO } from "services/types";
import UserService from "services/user.service";

import { AuthContextProps, AuthProviderProps } from "./types";

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<UserLoggedInModel | null>(null);
  const [isSignedIn, setSignedIn] = useState<boolean | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [loginError, setLoginError] = useState("");
  const [presentAlert] = useIonAlert();

  // TODO: adjust error handling
  const showError = (message: string) => {
    setLoginError(message);
    presentAlert({
      header: "Alerta",
      message,
      buttons: ["OK"],
    });
  };

  const signIn = async (email: string, data: AuthDTO) => {
    const token = { access: data.access, refresh: data.refresh };
    storeToken(token);
    setAuthToken(data.access);
    getUser(email);
  };

  const getUser = async (email: string) => {
    try {
      const response = await UserService.getFullData(email);

      if (response.status !== StatusCodes.OK) {
        showError(USER_LOAD_ERROR);
      } else {
        const { data } = response;
        if (data.results.user) {
          const user = {
            ...data.results.user,
            microregions: data.results.microregions,
          };
          storeUser(user);
          setUser(user);
          setSignedIn(user !== null);
        }
      }
    } catch (error) {
      signOut();
      showError(USER_LOAD_ERROR);
    }
  };

  const getLocalUser = useCallback(async () => {
    const storageCurrentUser = getCurrentUser();

    if (storageCurrentUser?.email) {
      getUser(storageCurrentUser.email);
    }
  }, []);

  const initData = (token: AuthDTO) => {
    storeToken(token);
    setAuthToken(token.access);
    getLocalUser();
  };

  const signOut = () => {
    logout();
    setSignedIn(false);
    setIsLoadingUser(false);
    setUser(null);
  };

  const verifyToken = useCallback(async (storageToken: AuthDTO) => {
    try {
      const responseVerification = await AuthService.verify(
        storageToken.access
      );
      if (responseVerification.status === StatusCodes.OK) {
        storeToken(storageToken);
        setAuthToken(storageToken.access);
        getLocalUser();
      } else {
        throw new Error();
      }
    } catch (error) {
      await refreshToken(storageToken);
    }
  }, []);

  const refreshToken = useCallback(async (storageToken: AuthDTO) => {
    try {
      const response = await AuthService.refresh(storageToken.refresh);
      if (response.status === StatusCodes.OK) {
        const { data } = response;
        initData({ ...storageToken, access: data.access });
      } else {
        throw new Error();
      }
    } catch (error) {
      signOut();
    }
  }, []);

  const loadStorage = useCallback(async () => {
    const storageToken = getToken();
    try {
      if (storageToken.access) {
        await verifyToken(storageToken);
      } else {
        signOut();
      }
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      if (Object.values(AUTH_DJANGO_CODES).includes(errorCode)) {
        signOut();
      }
    } finally {
      setIsLoadingUser(false);
    }
  }, []);

  useEffect(() => {
    loadStorage();
  }, [loadStorage]);

  return (
    <AuthContext.Provider
      value={{
        isSigned: isSignedIn,
        user,
        signIn,
        signOut,
        loadingUser: isLoadingUser,
        loginError,
        setUser,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  return context;
}
