import { AuthTokenModel, UserLoginModel } from "commons/types/user.model";

import { api, FetchResponse } from "./api.service";
import { AuthDTO, LoginRequestPayload } from "./types";

const TOKEN_KEY = "djangoTokens";
const CURRENT_USER = "currentUser";

const MODULE_PATH = "token";

export const getToken = (): AuthDTO =>
  JSON.parse(localStorage.getItem(TOKEN_KEY) || "{}");

export const getCurrentUser = (): UserLoginModel | null =>
  JSON.parse(localStorage.getItem(CURRENT_USER) || "null");

// todo: change typing
export const storeUser = (user: UserLoginModel | null) => {
  localStorage.setItem(CURRENT_USER, JSON.stringify(user));
};

export const storeToken = (token: AuthTokenModel) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const setAuthToken = (token: string) => {
  const { common } = api.defaults.headers;

  common["Authorization"] = `Bearer ${token}`;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(CURRENT_USER);
};

export default class AuthService {
  static async signIn(
    user: LoginRequestPayload
  ): Promise<FetchResponse<AuthDTO>> {
    return api.post(`${MODULE_PATH}/`, user);
  }

  static async verify(token: string) {
    try {
      return api.post(`${MODULE_PATH}/verify/`, { token });
    } catch (error) {
      throw new Error(error);
    }
  }

  static async refresh(refresh: string) {
    try {
      return api.post(`${MODULE_PATH}/refresh/`, { refresh });
    } catch (error) {
      throw new Error(error);
    }
  }

  static async recoverPassword(email: string) {
    return await api.post("/recover-password/", {
      email,
    });
  }

  static async verifyRecoverToken(token: string) {
    return await api.post("/recover-password/validate_token/", { token });
  }

  static async confirmRecoverToken(token: string, password: string) {
    return await api.post("/recover-password/confirm/", { token, password });
  }
}
