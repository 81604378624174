import { Grid } from "@mui/material";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import styled from "styled-components";

export const StyledMainGrid = styled(Grid)`
  align-items: normal;
  width: 100%;
  @media (min-width: 600px) {
    margin-top: 25px;
    align-items: center;
  }
`;

export const StyledHeaderGrid = styled(Grid)`
  width: 100%;
  padding: 20px;
  @media (min-width: 600px) {
    width: 488px;
    padding: 0px;
  }
`;

export const StyledSubGrid = styled(Grid)`
  width: 100%;
  margin: 7px 0px !important;
  @media (min-width: 600px) {
    width: 488px;
  }
`;

export const StyledImagesGrid = styled(Grid)`
  padding: 0px 20px;
  width: 100%;
  margin: 0 0 10px 20px;
  @media (min-width: 600px) {
    width: 447px;
  }
`;

export const productDetailstStyles = (): MaterialStyles => ({
  sizeCardContainer: {
    padding: 20,
    boxShadow: "unset",
  },
});
