import { IonTitle, IonToolbar } from "@ionic/react";
import styled from "styled-components";

export const StyledToolbar = styled(IonToolbar)`
  --min-height: 56px;
  --background: ${({ theme }) => theme.color.grayscale[1]};
  --border-style: 0px;
`;

export const HeaderTitle = styled(IonTitle)`
  text-align: left;
  padding: 20px 20px;
  contain: none;
  font-weight: 700;
  font-size: 18px;
  font-family: Poppins;
`;
