export enum AUTH_DJANGO_CODES {
  INVALID_TOKEN = "token_not_valid",
}

export enum NOTIFICATION_TYPES {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

export interface NotificationModel {
  message: string;
  type: NOTIFICATION_TYPES;
}
