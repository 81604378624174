import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const infoCardStyles = (theme: DefaultTheme): MaterialStyles => ({
  iconStyle: {
    fontSize: "14px",
    verticalAlign: "middle",
    color: theme.color.grayscale[9],
  },
  titleStyle: {
    color: theme.color.grayscale[9],
    marginLeft: "10px",
  },
  valueStyle: {
    color: theme.color.grayscale[9],
    display: "block",
    wordWrap: "break-word",
  },
});
