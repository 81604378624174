import styled from "styled-components";

import { StyledTypographyProps } from "./types";

export const StyledTypographyComponent = ({
  style,
  children,
  className,
}: StyledTypographyProps) => {
  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
};

export const StyledTypography = styled(
  StyledTypographyComponent
)<StyledTypographyProps>`
  font-size: ${({ variant }) => variant.fontSize};
  font-weight: ${({ variant }) => variant.fontWeight};
  line-height: ${({ variant }) => variant.lineHeight};
  text-decoration: ${({ variant }) => variant.decoration || "none"};
`;
