import { IonContent, IonFooter, IonPage } from "@ionic/react";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import successIcon from "assets/svgs/chubbs/success.svg";
import { tabletMinWidth } from "commons/constants/configuration.constant";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import HeaderWithButton from "components/HeaderWithButton";
import Typography from "components/Typography";
import { useTheme } from "styled-components";
import { TypographySpec } from "theme/defaultThemeDefinition";

import useCartSuccessController from "./controller";
import { cartSuccessStyles } from "./styles";

const CartSucessView = () => {
  const theme = useTheme();
  const { handleOnClick, handleGoBack } = useCartSuccessController();
  const isTablet = useMediaQuery(tabletMinWidth);
  const styles = cartSuccessStyles(isTablet);
  const fontVariant = isTablet
    ? (styles.title as TypographySpec)
    : theme.typography.heading[1].regular;

  return (
    <IonPage>
      <HeaderWithButton handleCustomBackClick={handleGoBack} />
      <IonContent>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          style={styles.mainContaier}
        >
          <Grid
            container
            direction={isTablet ? "row" : "column"}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            style={styles.mainGrid}
          >
            <Grid item xs sx={styles.messageContainer}>
              <Typography
                variant={fontVariant}
                color={theme.color.success.default}
              >
                Seu pedido foi realizado com <b>sucesso!</b>
              </Typography>
            </Grid>
            <Grid item xs>
              <img src={successIcon} style={styles.icon} />
            </Grid>
          </Grid>
        </Grid>
      </IonContent>
      <IonFooter>
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          style={styles.buttonContainer}
        >
          <ButtonPrimaryFilled onClick={handleOnClick}>
            Ver histórico de pedidos
          </ButtonPrimaryFilled>
        </Grid>
      </IonFooter>
    </IonPage>
  );
};

export default CartSucessView;
