import { MaterialStyles } from "commons/types/generics/materialStyles";
import { IonCard, IonImg, IonRow } from "@ionic/react";
import styled from "styled-components";

export const StyledIonCard = styled(IonCard)`
  padding: unset;
  border-radius: 8px;
  max-width: 400px;
  @media (min-width: 734px) {
    display: inline-block;
    width: 360px;
  }
`;

export const StyledIonImg = styled(IonImg)`
  object-fit: cover;
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px;
`;

export const StyledNameIonRow = styled(IonRow)`
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const storeCardStyles = (): MaterialStyles => ({
  infoContainer: {
    textAlign: "left",
    padding: "0 10px",
  },
  storeName: {
    whiteSpace: "nowrap",
  },
});
