import {
  OrderChangeStatusModel,
  OrderPostModel,
} from "commons/types/order.model";

import { api } from "./api.service";

const MODULE_PATH = "order";

export default class OrderService {
  static async getMany({
    page = 1,
    id,
    status,
    storeName = undefined,
  }: OrderGetMany) {
    const options = {
      params: {
        id,
        page,
        status,
        store_name: storeName,
      },
    };
    return api.get(`${MODULE_PATH}/`, options);
  }

  static async getOne(id: number | string) {
    return api.get(`${MODULE_PATH}/${id}/`);
  }

  static async getProducts(id: number) {
    return api.get(`${MODULE_PATH}/${id}/order-products/`);
  }

  static async create(payload: OrderPostModel) {
    return api.post(`${MODULE_PATH}/`, payload);
  }

  static async update(id: number, payload: OrderPostModel) {
    return api.put(`${MODULE_PATH}/${id}/`, payload);
  }
  static async updateStatus(id: number, payload: OrderChangeStatusModel) {
    return api.put(`${MODULE_PATH}/${id}/update-status/`, payload);
  }
  static async cancel(id: number) {
    return api.put(`${MODULE_PATH}/${id}/cancel/`);
  }

  static async removeOrderProducts(id: number, removedProductIds: number[]) {
    return api.post(`${MODULE_PATH}/${id}/delete-products/`, {
      orderProductIds: removedProductIds,
    });
  }
  static async generatePdf(id: number) {
    try {
      const response = await api.get(`${MODULE_PATH}/${id}/download-pdf/`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Pedido_${id}.pdf`);
      document.body.appendChild(link);
      link.click();

      return response;
    } catch (error) {
      return error;
    }
  }

  static async sendMailPdf(id: number, to?: string[]) {
    try {
      return await api.post(`${MODULE_PATH}/${id}/send-pdf-mail/`, {
        to,
      });
    } catch (error) {
      return error;
    }
  }
}

interface OrderGetMany {
  page: number;
  id?: number;
  status?: string[];
  storeName?: string;
}
