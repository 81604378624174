import { useIonAlert, useIonLoading } from "@ionic/react";
import { SelectChangeEvent } from "@mui/material";
import { STORE_LOAD_ERROR } from "commons/constants/message.constants";
import { StoreModel } from "commons/types/store.model";
import { formatCnpj } from "commons/utils/format";
import { useCart } from "contexts/cart";
import { useCallback, useEffect, useMemo, useState } from "react";
import StoreService from "services/store.service";

export const useStoreDetailController = (storeId: string) => {
  // Context hook
  const { setSelectedPaymentMethod, paymentMethods } = useCart();

  // Local state
  const [store, setStore] = useState<StoreModel>({} as StoreModel);

  const [isLoading, setLoading] = useState(true);
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const [localPaymentMethod, setLocalPaymentMethod] = useState("");

  const storeData = useMemo(
    () => ({
      imageUrl: store.logo,
      title: store.fantasy_name,
      subtitle: `Código: ${store.id}`,
      subtexts: [
        `CNPJ: ${formatCnpj(store.registration_number)}`,
        store.company_name ? `Razão social: ${store.company_name}` : "",
      ],
    }),
    [store]
  );

  const loadInfo = useCallback(async () => {
    try {
      const response = await StoreService.getOne(storeId);
      setStore(response?.data);
    } catch (error) {
      presentAlert({
        header: "Alerta",
        message: STORE_LOAD_ERROR,
        buttons: ["OK"],
      });
    } finally {
      setLoading(false);
      dismiss();
    }
  }, [storeId]);

  useEffect(() => {
    // todo: add redux or use context for loading
    present({
      message: "Carregando...",
      duration: 30000,
    });
  }, []);

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  const handleSelectPaymentMethod = (
    event: SelectChangeEvent<typeof localPaymentMethod>
  ) => {
    const {
      target: { value },
    } = event;
    setLocalPaymentMethod(String(value));
  };

  useEffect(() => {
    if (paymentMethods.length) {
      setSelectedPaymentMethod(
        paymentMethods.find(
          (method) => method.id.toString() === localPaymentMethod
        ) || null
      );
    }
  }, [paymentMethods, localPaymentMethod]);

  return {
    store,
    storeData,
    paymentMethods,
    selectedPaymentMethod: localPaymentMethod,
    handleSelectPaymentMethod,
    isLoading,
  };
};
