import { IonPage } from "@ionic/react";
import { CircularProgress, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import alertIcon from "assets/svgs/icons/alert.svg";
import { tabletMinWidth } from "commons/constants/configuration.constant";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import { ButtonsPdf } from "components/ButtonsPdf";
import Chubbs from "components/Chubbs";
import FormInputView from "components/FormInput";
import HeaderWithButton from "components/HeaderWithButton";
import FormDateInputView from "components/inputs/FormDateInput";
import StripeNotificationView from "components/notifications/StripeNotification";
import Typography from "components/Typography";
import { useOrderCardDetailController } from "pages/orders/components/OrderCardDetails/controller";
import StoreInfoBlockView from "pages/products/components/StoreInfoBlock";
import { Fragment } from "react";
import { useTheme } from "styled-components";
import { StyledIonContent } from "theme/global.styles";

import CartOrderDetailsView from "./components/CartOrderDetails";
import CartDialogsView from "./components/dialogs/CartDialogs";
import SupplierCardView from "./components/SupplierCard";
import { useCartController } from "./controller";
import { cartStyles } from "./styles";

const CartView = () => {
  const {
    isLoading,
    isSubmiting,
    isButtonDisabled,
    isEditingCart,
    saveCartNotification,
    storeEmail,
    // Cart context
    cartData,
    selectedSuppliers,
    cartTotal,
    paymentMethods,
    // Form
    control,
    register,
    errors,
    // Actions
    handleSubmit,
    handleOnSubmit,
    handleKeepShopping,
    handleGoToSupplierProducts,
    handleGoToSupplierDetails,
    handleRemoveSupplier,
    handleSaveCart,
    handleSetSupplierPaymentMethod,
    // Dialogs
    isSubmitDialogOpen,
    handleCloseSubmitDialog,
    handleDoSubmit,
    supplierToRemove,
    handleCloseRemoveDialog,
    handleDoRemoveSupplier,
    isMinOrderDialogOpen,
    handleCloseMinOrderDialog,
  } = useCartController();

  const {
    handleGeneratePdf,
    isLoadingSubmit,
    handleShowModal,
    isOpenSendMailModal,
    handleSendPdfCartMail,
    isLoadingSendMailSubmit,
  } = useOrderCardDetailController();

  const isTablet = useMediaQuery(tabletMinWidth);
  const theme = useTheme();
  const styles = cartStyles(theme, isTablet);

  if (isLoading)
    return (
      <IonPage>
        <HeaderWithButton handleCustomBackClick={handleKeepShopping} />
        <StyledIonContent />
      </IonPage>
    );

  const ButtonSaveCart = () => (
    <ButtonPrimaryOutlined onClick={handleSaveCart} disabled={isSubmiting}>
      {isSubmiting ? <CircularProgress size={25} /> : "Salvar rascunho"}
    </ButtonPrimaryOutlined>
  );

  return (
    <IonPage>
      <HeaderWithButton handleCustomBackClick={handleKeepShopping} />
      <StyledIonContent>
        {cartData && cartData.selectedStore ? (
          <StoreInfoBlockView store={cartData.selectedStore} isOutFocus />
        ) : null}

        {!selectedSuppliers.length ? null : (
          <Fragment>
            {isEditingCart ? null : (
              <StripeNotificationView
                notification={saveCartNotification}
                icon={<img src={alertIcon} />}
              />
            )}
            {isTablet ? null : (
              <>
                <Grid padding={2}>
                  <ButtonSaveCart />
                </Grid>
                {cartData.cartId ? (
                  <Grid
                    container
                    padding={2}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <ButtonsPdf
                      isLoadingSubmit={isLoadingSubmit}
                      onSubmit={() =>
                        handleGeneratePdf(true, Number(cartData.cartId))
                      }
                      handleCloseSendMailModal={() => handleShowModal(false)}
                      handleOpenSendMailModal={() => handleShowModal(true)}
                      handleSendMail={handleSendPdfCartMail}
                      isOpenSendMailModal={isOpenSendMailModal}
                      email={storeEmail}
                      id={Number(cartData.cartId)}
                      isLoadingSendMailSubmit={isLoadingSendMailSubmit}
                    />
                  </Grid>
                ) : null}
              </>
            )}
          </Fragment>
        )}

        <Grid container direction="column" spacing={2}>
          <Grid container alignItems="center" style={styles.cartTitle}>
            <Grid item xs={8}>
              <Typography
                variant={theme.typography.heading[3].highlight}
                color={theme.color.tertiary.default}
              >
                Carrinho
              </Typography>
            </Grid>
            {!selectedSuppliers.length ? null : !isTablet ? null : (
              <>
                <Grid item xs style={styles.tabletCartSaveBtn}>
                  <ButtonSaveCart />
                </Grid>
                {cartData.cartId ? (
                  <Grid
                    container
                    padding={2}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <ButtonsPdf
                      isLoadingSubmit={isLoadingSubmit}
                      onSubmit={() =>
                        handleGeneratePdf(true, Number(cartData.cartId))
                      }
                      handleCloseSendMailModal={() => handleShowModal(false)}
                      handleOpenSendMailModal={() => handleShowModal(true)}
                      isOpenSendMailModal={isOpenSendMailModal}
                      email={storeEmail}
                      handleSendMail={handleSendPdfCartMail}
                      id={Number(cartData.cartId)}
                      isLoadingSendMailSubmit={isLoadingSendMailSubmit}
                    />
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>

          <Grid item xs>
            {selectedSuppliers.length ? (
              selectedSuppliers.map((supplier, index) => {
                return (
                  <Grid key={supplier.id} mt="15px">
                    <Grid style={styles.supplierCounterTitle}>
                      <Typography
                        variant={theme.typography.subheading[3].highlight}
                        color={theme.color.tertiary.default}
                      >
                        Fábrica {index + 1}:
                      </Typography>
                    </Grid>
                    <SupplierCardView
                      paymentMethods={paymentMethods}
                      supplier={supplier}
                      control={control}
                      addProducts={handleGoToSupplierProducts}
                      viewDetails={handleGoToSupplierDetails}
                      removeSupplier={handleRemoveSupplier}
                      supplierToRemove={supplierToRemove}
                      handleCloseRemoveDialog={handleCloseRemoveDialog}
                      handleDoRemove={handleDoRemoveSupplier}
                      isMinOrderDialogOpen={isMinOrderDialogOpen}
                      handleCloseMinOrderDialog={handleCloseMinOrderDialog}
                      handleSetSupplierPaymentMethod={
                        handleSetSupplierPaymentMethod
                      }
                      isEditingOrder
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid alignContent="center" justifyContent="flex-start">
                <Chubbs
                  isCentered={!isTablet}
                  variant="not-found"
                  customMessage="Seu carrinho está vazio. Os produtos escolhidos irão aparecer aqui."
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs style={styles.detailsTitle}>
            <Typography
              variant={theme.typography.heading[4].highlight}
              color={theme.color.neutralDark.default}
            >
              Detalhes do pedido
            </Typography>
          </Grid>

          <Grid item xs style={styles.detailsContainer}>
            <FormDateInputView
              control={control}
              field="paymentDate"
              title="Data de faturamento*"
              errors={errors}
            />

            <FormInputView
              field="observation"
              title="Observações e forma de pagamento do pedido*"
              placeholder="Insira as observações da loja..."
              rows={3}
              multiline
              register={register}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid style={styles.footer}>
          <CartOrderDetailsView
            orderTotal={cartTotal}
            isTablet={isTablet}
            handleSubmit={handleSubmit}
            handleOnSubmit={handleOnSubmit}
            handleKeepShopping={handleKeepShopping}
            isButtonDisabled={isButtonDisabled}
            isSubmiting={isSubmiting}
            isEditingOrder
          />
        </Grid>
      </StyledIonContent>

      <CartDialogsView
        isSubmitDialogOpen={isSubmitDialogOpen}
        handleCloseSubmitDialog={handleCloseSubmitDialog}
        handleDoSubmit={handleDoSubmit}
        isSubmiting={isSubmiting}
      />
    </IonPage>
  );
};

export default CartView;
