import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { formatCurrency } from "commons/utils/format";
import ButtonErrorOutlined from "components/ButtonErrorOutlined";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { CartSubmitButton, orderDetailsStyles } from "./styles";
import { OrderDetailsProps } from "./types";

const CartOrderDetailsView = ({
  orderTotal,
  isTablet,
  isButtonDisabled,
  isSubmiting,
  isOrderView,
  isEditingOrder,
  isAdmin,
  canCancel,
  handleSubmit,
  handleOnSubmit,
  handleKeepShopping,
  handleToggleEdit,
  handleCancelOrder,
  canEdit = true,
}: OrderDetailsProps) => {
  const theme = useTheme();
  const styles = orderDetailsStyles(isTablet);

  const canShowFooter = isOrderView && !isEditingOrder && canEdit;

  return (
    <Grid container direction={isTablet ? "row" : "column"}>
      <Grid
        container
        item
        xs
        alignItems="center"
        justifyContent={isTablet ? "center" : "space-between"}
      >
        <Grid item xs={6} style={styles.totalLabel}>
          <Typography
            variant={theme.typography.body[2].regular}
            color={theme.color.grayscale[6]}
          >
            Total do pedido:
          </Typography>
        </Grid>
        <Grid item xs={6} style={styles.totalPrice}>
          <Typography
            variant={theme.typography.body[2].highlight}
            color={theme.color.grayscale[9]}
          >
            {formatCurrency(orderTotal || 0)}
          </Typography>
        </Grid>
      </Grid>

      {isEditingOrder ? (
        <Grid
          container
          item
          xs
          alignItems="center"
          justifyContent="center"
          style={styles.buttonContainer}
          spacing={1}
        >
          <Grid item xs={12} sm={4} textAlign={isTablet ? "start" : "center"}>
            <CartSubmitButton
              type="submit"
              onClick={handleSubmit(handleOnSubmit)}
              disabled={isButtonDisabled || isSubmiting}
            >
              {isSubmiting ? (
                <CircularProgress style={styles.progress} size={25} />
              ) : isOrderView ? (
                "Salvar edição"
              ) : (
                "Finalizar pedido"
              )}
            </CartSubmitButton>
          </Grid>
          <Grid item xs={12} sm={8} textAlign={isTablet ? "end" : "center"}>
            <ButtonPrimaryOutlined onClick={handleKeepShopping}>
              Continuar escolhendo produtos
            </ButtonPrimaryOutlined>
          </Grid>
        </Grid>
      ) : null}

      {canShowFooter ? (
        <Grid
          container
          item
          xs
          alignItems="center"
          justifyContent="center"
          style={styles.buttonContainer}
          spacing={1}
        >
          {isAdmin ? (
            <Grid item xs={12} sm={4} textAlign={isTablet ? "start" : "center"}>
              <ButtonPrimaryFilled onClick={handleToggleEdit}>
                Editar pedido
              </ButtonPrimaryFilled>
            </Grid>
          ) : null}
          {!canCancel ? null : (
            <Grid item xs={12} sm={8} textAlign={isTablet ? "end" : "center"}>
              <ButtonErrorOutlined onClick={handleCancelOrder}>
                Cancelar pedido
              </ButtonErrorOutlined>
            </Grid>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CartOrderDetailsView;
