import { useIonLoading } from "@ionic/react";
import { MICROREGIONS_LOAD_ERROR } from "commons/constants/message.constants";
import { MicroregionModel } from "commons/types/microregion.model";
import { useAuth } from "contexts/auth";
import { useConfiguration } from "contexts/configuration";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import MicroregionService from "services/microregion.service";

export const useMicroregionsController = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { presentError } = useConfiguration();
  const [microregions, setMicroregions] = useState<MicroregionModel[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [present, dismiss] = useIonLoading();

  const [searchText, setSearchText] = useState("");
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();

  const loadMicroregions = useCallback(async () => {
    try {
      if (isInfiniteDisabled || !user) return;
      const searchParams = {
        page,
        search: searchText,
      };
      const response = await MicroregionService.get(searchParams);
      const _microregions: MicroregionModel[] = response.data?.results;

      const canSearch =
        microregions.concat(_microregions).length <
        (response?.data?.count || 0);

      if (page === 1) {
        setMicroregions(_microregions);
      } else if (_microregions.length && page != 1) {
        setMicroregions((current) => current.concat(_microregions));
      }

      if (!canSearch) {
        setInfiniteDisabled(true);
      }
      if (canSearch && scrollEvent) {
        // Complete the scroll event, so que pagination can work
        (scrollEvent.target as HTMLIonInfiniteScrollElement).complete();
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, MICROREGIONS_LOAD_ERROR);
    } finally {
      setLoading(false);
      dismiss();
    }
  }, [searchText, page, isInfiniteDisabled]);

  const cleanSearch = () => {
    setPage(1);
    setInfiniteDisabled(false);
  };

  const handleSearch = (input: string) => {
    setSearchText(() => {
      cleanSearch();
      return input;
    });
  };

  const handleClearSearch = () => {
    setSearchText(() => {
      cleanSearch();
      return "";
    });
  };

  const handleClickDetails = (region: MicroregionModel) => () => {
    history.push(`/microregions/stores`, region);
  };

  const handleClickNewStore = () => {
    history.push(`/microregions/stores/new`);
  };

  const handleLoadData = useCallback(
    (event: CustomEvent<void>) => {
      setPage((oldPage) => {
        setScrollEvent(event);
        return oldPage + 1;
      });
    },
    [setPage, page]
  );

  useEffect(() => {
    // TODO: add redux or use context for loading
    present({
      message: "Carregando...",
      duration: 30000,
    });
  }, []);

  useEffect(() => {
    loadMicroregions();
  }, [page, searchText]);

  return {
    //Microregion
    microregions,
    handleClickDetails,
    handleClickNewStore,
    // Search
    searchText,
    handleSearch,
    handleClearSearch,
    // State
    isLoading,
    handleLoadData,
    isInfiniteDisabled,
  };
};
