import { IonHeader, IonToolbar } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { SyntheticEvent } from "react";
import { useTheme } from "styled-components";

import { useBackButtonController } from "./controller";
import { BackButtonLink, StyledIonIcon, StyledIonToolbar } from "./styles";
import { BackButtonHeaderProps } from "./types";

const BackButtonHeader = ({
  children,
  handleCustomClick,
  hasBackgroundColor = true,
}: BackButtonHeaderProps) => {
  const theme = useTheme();
  const { handleClickBack } = useBackButtonController();

  const handleOnClick = (e: SyntheticEvent) => {
    if (handleCustomClick) {
      handleCustomClick(e);
    } else {
      handleClickBack(e);
    }
  };

  return (
    <IonHeader className={children ? "" : "ion-no-border"}>
      <StyledIonToolbar color={hasBackgroundColor ? "light" : ""} mode="ios">
        <BackButtonLink
          href="#"
          color={theme.color.neutralDark.default}
          variant={theme.typography.body[2].regular}
          onClick={handleOnClick}
        >
          <StyledIonIcon icon={chevronBack} mode="ios" />
          <span>Voltar</span>
        </BackButtonLink>

        {children ? <br /> : null}
      </StyledIonToolbar>
      {children ? (
        <IonToolbar color="light" mode="ios">
          {children}
        </IonToolbar>
      ) : null}
    </IonHeader>
  );
};

export default BackButtonHeader;
