import { MaterialStyles } from "commons/types/generics/materialStyles";

export const cartSuccessStyles = (isTablet: boolean): MaterialStyles => ({
  mainContaier: {
    height: "100%",
    backgroundColor: "#d0e5d2",
  },

  messageContainer: {
    padding: "51px 39px",
    "@media (min-width: 700px)": {
      padding: "51px 0 251px 15vw",
    },
  },
  buttonContainer: {
    padding: "20px",
  },
  icon: {
    width: isTablet ? "463px" : "auto",
  },
  title: {
    fontSize: "60px",
    fontWeight: 500,
    lineHeight: "78px",
  },
});
