import {
  INVALID_EMAIL,
  INVALID_NUMBER,
  INVALID_REGISTRATION_NUMBER,
  INVALID_ZIPCODE,
  REQUIRED_FIELD,
} from "commons/constants/form.message.constants";
import * as Yup from "yup";

export const basicFormValidationSchema = Yup.object().shape({
  company_name: Yup.string().trim().required(REQUIRED_FIELD),
  fantasy_name: Yup.string().trim().required(REQUIRED_FIELD),
  state_registration: Yup.string().trim().required(REQUIRED_FIELD),
  registration_number: Yup.string()
    .trim()
    .min(14, INVALID_REGISTRATION_NUMBER)
    .required(REQUIRED_FIELD),
  store_type: Yup.string().trim().required(REQUIRED_FIELD),
});

export const addressFormValidationSchema = Yup.object().shape({
  zipcode: Yup.string().trim().min(8, INVALID_ZIPCODE).required(REQUIRED_FIELD),
  state: Yup.string().trim().required(REQUIRED_FIELD),
  city: Yup.string().trim().required(REQUIRED_FIELD),
  neighborhood: Yup.string().trim().required(REQUIRED_FIELD),
  street: Yup.string().trim().required(REQUIRED_FIELD),
  number: Yup.number().typeError(INVALID_NUMBER).required(REQUIRED_FIELD),
});

export const contactFormValidationSchema = Yup.object().shape({
  contact_name: Yup.string().trim().required(REQUIRED_FIELD),
  whatsapp: Yup.string().trim().required(REQUIRED_FIELD),
  phone: Yup.string().trim().required(REQUIRED_FIELD),
  email: Yup.string().trim().required(REQUIRED_FIELD).email(INVALID_EMAIL),
  admin_email: Yup.string().trim().email(INVALID_EMAIL),
  billing_email: Yup.string().trim().email(INVALID_EMAIL),
});

export const initialValues = {
  // 1 Step
  fantasy_name: "",
  registration_number: "",
  store_type: "",
  company_name: "",
  state_registration: "",
};

export const initialValuesAddress = {
  zipcode: "",
  state: "",
  city: "",
  neighborhood: "", // TODO is required but not informed by front
  street: "",
  number: undefined,
  complement: "",
};

export const initialValuesContact = {
  contact_name: "",
  whatsapp: "",
  phone: "",
  email: "",
  admin_email: "",
  billing_email: "",
};

export const brazilStates = [
  "Acre",
  "Alagoas",
  "Amazonas",
  "Amapá",
  "Bahia",
  "Ceará",
  "Distrito Federal",
  "Espírito Santo",
  "Goiás",
  "Maranhão",
  "Minas Gerais",
  "Mato Grosso do Sul",
  "Mato Grosso",
  "Pará",
  "Paraíba",
  "Pernambuco",
  "Piauí",
  "Paraná",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rondônia",
  "Roraima",
  "Rio Grande do Sul",
  "Santa Catarina",
  "Sergipe",
  "São Paulo",
  "Tocantins",
];

export enum STORE_PAGES {
  BASIC = 0,
  ADDRESS = 1,
  CONTACT = 2,
}

export const BRAZIL_STATES = [
  { id: "AC", name: "Acre" },
  { id: "AL", name: "Alagoas" },
  { id: "AP", name: "Amapá" },
  { id: "AM", name: "Amazonas" },
  { id: "BA", name: "Bahia" },
  { id: "CE", name: "Ceará" },
  { id: "DF", name: "Distrito Federal" },
  { id: "ES", name: "Espírito Santo" },
  { id: "GO", name: "Goiás" },
  { id: "MA", name: "Maranhão" },
  { id: "MG", name: "Minas Gerais" },
  { id: "MS", name: "Mato Grosso do Sul" },
  { id: "MT", name: "Mato Grosso" },
  { id: "PA", name: "Pará" },
  { id: "PB", name: "Paraíba" },
  { id: "PE", name: "Pernambuco" },
  { id: "PI", name: "Piauí" },
  { id: "PR", name: "Paraná" },
  { id: "RJ", name: "Rio de Janeiro" },
  { id: "RN", name: "Rio Grande do Norte" },
  { id: "RO", name: "Rondônia" },
  { id: "RR", name: "Roraima" },
  { id: "RS", name: "Rio Grande do Sul" },
  { id: "SC", name: "Santa Catarina" },
  { id: "SE", name: "Sergipe" },
  { id: "SP", name: "São Paulo" },
  { id: "TO", name: "Tocantins" },
];
