import { MaterialStyles } from "commons/types/generics/materialStyles";

export const dialogStyles = (hasAutoSizedBtns?: boolean): MaterialStyles => ({
  gridContainer: {
    padding: "20px 25px 10px 25px",
  },
  icon: {
    fontSize: "50px",
  },
  dialogPaper: {
    borderRadius: 10,
  },
  leftButton: {
    width: hasAutoSizedBtns ? "auto" : undefined,
    padding: "5px 12px",
    margin: 0,
    marginRight: hasAutoSizedBtns ? 10 : 0,
    height: 36,
  },
  rightButton: {
    padding: "5px 12px",
    width: hasAutoSizedBtns ? "auto" : undefined,
    margin: 5,
    height: 36,
  },
  messageContainer: {
    margin: "10px 0",
  },
  buttonsContainer: {
    margin: "15px 0",
  },
});
