import { IonPage } from "@ionic/react";
import Grid from "@mui/material/Grid";
import BackButtonHeader from "components/BackButtonHeader";
import Typography from "components/Typography";
import { StyledIonContent } from "pages/stores/styles";
import { useTheme } from "styled-components";

import RecoverButtonView from "../sharedComponents/RecoverButton";
import RecoverSectionTitleView from "../sharedComponents/SectionTitle";
import {
  StyledFooterGrid,
  StyledFormGrid,
  StyledFormHeaderContainer,
  StyledFormHeaderGrid,
  StyledMainGrid,
} from "../styles";
import { useResendRecoverPasswordController } from "./controller";

const ResendRecoverPasswordView = () => {
  const { onSubmit, isLoading, censoredEmail } =
    useResendRecoverPasswordController();
  const theme = useTheme();

  return (
    <IonPage>
      <BackButtonHeader hasBackgroundColor={false} />
      <StyledIonContent>
        <StyledMainGrid>
          <StyledFormGrid>
            <RecoverSectionTitleView />

            <StyledFormHeaderContainer container direction="column">
              <StyledFormHeaderGrid item xs data-test="heading">
                <Typography
                  variant={theme.typography.heading[1].regular}
                  color={theme.color.grayscale[11]}
                >
                  Email enviado com sucesso!{" "}
                  <b>Verifique sua caixa de entrada</b>
                </Typography>
              </StyledFormHeaderGrid>
            </StyledFormHeaderContainer>

            <Grid item xs={12}>
              <Typography
                variant={theme.typography.body[2].regular}
                color={theme.color.grayscale[7]}
              >
                <p>
                  Enviamos um link de recuperação para o email{" "}
                  <b>{` ${censoredEmail}.`}</b>
                </p>

                <p>
                  Revise seu email e entre no link para recuperar a sua senha.
                </p>
                <p>
                  Caso você não consiga visualizar o email, revise a categoria
                  spam.
                </p>
              </Typography>
            </Grid>
          </StyledFormGrid>
        </StyledMainGrid>
        <StyledFooterGrid container>
          <RecoverButtonView
            buttonText="Reenviar email"
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </StyledFooterGrid>
      </StyledIonContent>
    </IonPage>
  );
};

export default ResendRecoverPasswordView;
