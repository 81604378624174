import { IonPage } from "@ionic/react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import trashBinIcon from "assets/svgs/trash_bin.svg";
import HeaderWithButton from "components/HeaderWithButton";
import ButtonCart from "components/HeaderWithButton/ButtonCart";
import Typography from "components/Typography";
import { useCart } from "contexts/cart";
import { useTheme } from "styled-components";
import { StyledIonContent } from "theme/global.styles";

import SupplierDialogsView from "../components/dialogs/SupplierDialogs";
import { supplierCardStyles } from "../components/SupplierCard/styles";
import SupplierInfoView from "../components/SupplierInfo";
import { useCartController } from "../controller";
import ProductVariantItem from "./components/ProductVariantItem";
import { useSupplierDetailsController } from "./controller";
import { supplierDetailsStyles } from "./styles";

const CartSupplierDetailsView = () => {
  const {
    isEditingOrder,
    canShowActions,
    supplier,
    productItems,
    handleRemoveProduct,
    handleGoToSupplierProducts,
    handleGoToProductDetails,
    handleReturnToOrder,
  } = useSupplierDetailsController();

  const {
    handleRemoveSupplier,
    supplierToRemove,
    handleCloseRemoveDialog,
    handleDoRemoveSupplier,
    handleCloseMinOrderDialog,
  } = useCartController();

  const { applyCartDiscount } = useCart();

  const theme = useTheme();
  const { primaryButton, removeButton, icon } = supplierCardStyles();
  const { container, buttonsContainer, listText } = supplierDetailsStyles();

  if (!supplier) {
    return (
      <IonPage>
        <HeaderWithButton
          rightButton={isEditingOrder ? undefined : <ButtonCart />}
          handleCustomBackClick={
            isEditingOrder ? () => handleReturnToOrder() : undefined
          }
        />
        <StyledIonContent />
      </IonPage>
    );
  }

  return (
    <IonPage>
      <HeaderWithButton
        rightButton={isEditingOrder ? undefined : <ButtonCart />}
        handleCustomBackClick={
          isEditingOrder ? () => handleReturnToOrder() : undefined
        }
      />
      <StyledIonContent>
        <Grid container direction="column" sx={container}>
          <Grid item>
            <SupplierInfoView
              supplier={supplier}
              total={supplier.supplierTotal}
            />
          </Grid>

          {canShowActions ? (
            <Grid item sx={buttonsContainer}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Button
                    style={primaryButton}
                    variant="contained"
                    onClick={() => handleGoToSupplierProducts(supplier.id)}
                  >
                    <Typography variant={theme.typography.body[4].highlight}>
                      Adicionar produtos
                    </Typography>
                  </Button>
                </Grid>
                <Grid item textAlign="start">
                  <Grid container alignItems="center">
                    <Grid item sx={icon}>
                      <img src={trashBinIcon} />
                    </Grid>
                    <Grid
                      item
                      onClick={() => handleRemoveSupplier(supplier.id)}
                    >
                      <Button color="error" style={removeButton}>
                        <Typography
                          variant={theme.typography.body[4].highlight}
                        >
                          Excluir pedido
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid item sx={listText}>
            <Typography
              variant={theme.typography.body[3].highlight}
              color={theme.color.grayscale[7]}
            >
              Lista de produtos
            </Typography>
          </Grid>
          {productItems.map((product) => {
            if (!product.addedProductVariants) return null;

            return Object.entries(product.addedProductVariants).map(
              ([sku, values]) => (
                <ProductVariantItem
                  showButtons={canShowActions}
                  key={sku}
                  quantity={values.quantity}
                  price={applyCartDiscount(
                    values.price * values.quantity,
                    supplier
                  )}
                  label={values.label}
                  onClickEdit={() =>
                    handleGoToProductDetails(product.id, values)
                  }
                  onClickRemove={() =>
                    handleRemoveProduct(product.id, Number(sku))
                  }
                />
              )
            );
          })}
        </Grid>
      </StyledIonContent>
      <SupplierDialogsView
        supplier={supplier}
        isRemoveDialogOpen={supplier.id === supplierToRemove}
        handleCloseRemoveDialog={handleCloseRemoveDialog}
        handleDoRemove={handleDoRemoveSupplier}
        isMinOrderDialogOpen={false}
        handleCloseMinOrderDialog={handleCloseMinOrderDialog}
      />
    </IonPage>
  );
};

export default CartSupplierDetailsView;
