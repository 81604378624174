import { IonPage } from "@ionic/react";
import HeaderWithButton from "components/HeaderWithButton";
import ButtonCart from "components/HeaderWithButton/ButtonCart";

import ProductFiltersView from "./components/filters/ProductFilterContainer";
import ListProductsView from "./components/lists/ListProducts";
import ProductsBannerView from "./components/ProductsBanner";
import StoreInfoBlockView from "./components/StoreInfoBlock";
import { useProductsController } from "./controller";
import { StyledIonContent } from "./styles";

const ProductsView = () => {
  const {
    products,
    categories,
    tags,
    cartData,
    isLoading,
    isInfiniteDisabled,
    handleClickDetails,
    handleLoadData,
    handleBackClick,
  } = useProductsController();
  return (
    <IonPage>
      <HeaderWithButton
        rightButton={<ButtonCart />}
        handleCustomBackClick={handleBackClick}
      />
      <StyledIonContent>
        {cartData?.selectedStore ? (
          <StoreInfoBlockView store={cartData.selectedStore} />
        ) : null}
        <ProductsBannerView />
        <ProductFiltersView />
        <ListProductsView
          products={products}
          categories={categories}
          tags={tags}
          isLoading={isLoading}
          handleClickDetails={handleClickDetails}
          handleGeneralLoadData={handleLoadData}
          isGeneralInfiniteDisabled={isInfiniteDisabled}
        />
      </StyledIonContent>
    </IonPage>
  );
};

export default ProductsView;
