import styled from "@emotion/styled";
import { IonGrid, IonContent } from "@ionic/react";
import { Grid } from "@mui/material";
import styledComponents from "styled-components";

// TODO: move to global styles
export const StyledIonContent = styledComponents(IonContent)`
  --background: ${({ theme }) => theme.color.grayscale[1]};
`;

export const StyledIonGridDark = styledComponents(IonGrid)`
  background-color: ${({ theme }) => theme.color.neutralDark.default};
  color: ${({ theme }) => theme.color.neutralLight.default};
  padding: 33px 35px 53px 20px;
  margin-bottom: -25px;
  font-size: 24px;
`;

export const StyledIonGridLight = styledComponents(IonGrid)`
  background-color: ${({ theme }) => theme.color.grayscale[1]};
  color: ${({ theme }) => theme.color.grayscale[10]};
  padding: 33px 35px 8px 20px;
  font-size: 24px;
`;

export const FiltersContainer = styled(Grid)`
  text-align: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  @media (min-width: 734px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledCardsIonGrid = styled(IonGrid)`
  text-align: center;
  @media (min-width: 734px) {
    & > ion-card {
      margin: 10px 25px;
    }
  }
`;
