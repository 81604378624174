import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { SyntheticEvent, useState } from "react";
import { useHistory } from "react-router";

export const useStoreInfoController = () => {
  const history = useHistory();
  const { handleSaveCart, showError, selectedSuppliers, clearCart } = useCart();
  const { user } = useAuth();
  const [isChangeClientDialogOpen, setIsChangeClientDialogOpen] =
    useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleChangeClient = (e: SyntheticEvent) => {
    e.preventDefault();
    if (selectedSuppliers.length) {
      setIsChangeClientDialogOpen(true);
    } else {
      history.replace("/stores");
    }
  };

  const handleDoChangeClient = async () => {
    try {
      if (user?.id) {
        setIsSubmiting(true);
        const response: any = await handleSaveCart(user.id);
        if (response) {
          clearCart(true);
          history.replace("/stores");
        }
      }
    } catch (error) {
      showError(error.message);
      setIsSubmiting(false);
    }
  };

  const handleCloseChangeClientDialog = () => {
    setIsChangeClientDialogOpen(false);
  };

  const handleOpenChangeClienteDialog = () => {
    setIsChangeClientDialogOpen(true);
  };

  return {
    isChangeClientDialogOpen,
    isSubmiting,
    handleChangeClient,
    handleDoChangeClient,
    handleCloseChangeClientDialog,
    handleOpenChangeClienteDialog,
  };
};
