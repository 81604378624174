import styled from "@emotion/styled";
import { Card, Grid } from "@mui/material";
import { DefaultTheme, useTheme } from "styled-components";

export const StyledCardContainer = styled(Card)`
  border-radius: 10px;
  width: 100%;
  max-width: 364px;
  margin-bottom: 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  @media (min-width: 734px) {
    margin-right: 10px;
    margin-left: 10px;
  }

  & .MuiCardContent-root {
    text-align: left;
    padding-top: 15px;
    padding-left: 20px;
  }
  & .MuiCardActions-root {
    padding-top: 0px;
  }
  & ion-button {
    transform: translate(-4px, 0) !important;
  }
`;

export const StyledDivider = styled(Grid)`
  margin: 8px -20px;
  max-height: 1px !important;
  height: 1px;
  box-sizing: border-box;
  border-top: 1px solid ${() => useTheme().color.grayscale[6]};
  box-shadow: 0px -1px 0.4px 0px rgba(102, 122, 153, 0.14);
  filter: blur(0.5px);
`;

export const boxStyles = (theme: DefaultTheme) => ({
  borderRight: 1,
  borderColor: theme.color.grayscale[2],
});

export const typographyStyle = () => ({
  display: "list-item",
  marginLeft: "16px",
  marginTop: "8px",
  marginBottom: "8px",
});

export const typographyItemStyle = (): React.CSSProperties => ({
  position: "relative",
  left: "-4px",
});
