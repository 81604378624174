import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { AUTH_TABS } from "commons/types/configuration.enum";
import { ProductFilterProvider } from "contexts/product";
import {
  personCircleOutline,
  storefrontOutline,
  addCircleOutline,
  archive,
} from "ionicons/icons";
import AccountView from "pages/account";
import ChangePasswordView from "pages/account/ChangePasswordView";
import EditAccountView from "pages/account/EditAccountView";
import CartView from "pages/cart";
import CartSucessView from "pages/cart/components/CartSuccess";
import CartSupplierDetailsView from "pages/cart/SupplierDetailsView";
import MicroregionsView from "pages/microregions";
import OrdersView from "pages/orders";
import OrderCancelledView from "pages/orders/OrderCancelledView";
import OrderDetailsView from "pages/orders/OrderDetailsView";
import ProductsView from "pages/products";
import ProductDetailView from "pages/products/ProductDetailsView";
import ProductsPerSupplierView from "pages/products/ProductsPerSupplierView";
import StoresView from "pages/stores";
import StoreCreateView from "pages/stores/StoreCreate";
import StoreDetailView from "pages/stores/StoreDetailsView";
import { Redirect, Route, Switch } from "react-router-dom";
import { useTheme } from "styled-components";

import {
  ionLabelStyle,
  ionTabBarStyle,
  ionTabButtonIconStyle,
  ionTabButtonStyle,
} from "../styles";
import useAuthRoutesController from "./controller";

const AuthRoutes = () => {
  const theme = useTheme();
  const { canShowTabs, storesPath } = useAuthRoutesController();

  return (
    <IonTabs>
      <IonRouterOutlet id="auth-routes">
        <Switch>
          {/* Account Stack */}
          <Route exact path="/account" component={AccountView} />
          <Route exact path="/account/edit" component={EditAccountView} />
          <Route
            exact
            path="/account/change-password"
            component={ChangePasswordView}
          />
          {/* Stores Stack */}
          <Route exact path="/stores" component={StoresView} />
          <Route
            path="/stores/supplier/:supplierId/product/:id"
            component={ProductDetailView}
          />
          <Route exact path="/stores/products">
            <ProductFilterProvider>
              <ProductsView />
            </ProductFilterProvider>
          </Route>
          <Route
            exact
            path="/stores/product/supplier/:id"
            component={ProductsPerSupplierView}
          />
          <Route exact path="/stores/cart" component={CartView} />
          <Route
            path="/stores/cart/supplier/:id"
            component={CartSupplierDetailsView}
          />
          <Route exact path="/stores/cart/success" component={CartSucessView} />
          <Route exact path="/stores/:id" component={StoreDetailView} />
          {/* Microregions Stack */}
          <Route exact path="/microregions" component={MicroregionsView} />
          <Route exact path="/microregions/stores" component={StoresView} />
          <Route
            exact
            path="/microregions/stores/new"
            component={StoreCreateView}
          />
          {/* Orders Stack */}
          <Route exact path="/orders" component={OrdersView} />
          <Route exact path="/orders/:id" component={OrderDetailsView} />
          <Route
            exact
            path="/orders/:orderId/supplier/:id"
            component={CartSupplierDetailsView}
          />
          <Route
            exact
            path="/orders/:id/cancelled"
            component={OrderCancelledView}
          />
          {/* Default Route */}
          <Route exact path="*">
            <Redirect to="/stores" />
          </Route>
        </Switch>
      </IonRouterOutlet>

      {!canShowTabs ? (
        <IonTabBar />
      ) : (
        <IonTabBar slot="bottom" style={ionTabBarStyle(theme)}>
          <IonTabButton
            tab={AUTH_TABS.ACCOUNT}
            href="/account"
            style={ionTabButtonStyle(theme)}
          >
            <IonIcon
              icon={personCircleOutline}
              style={ionTabButtonIconStyle()}
            />
            <IonLabel style={ionLabelStyle(theme)}>Conta</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab={AUTH_TABS.MICROREGIONS}
            href="/microregions"
            style={ionTabButtonStyle(theme)}
          >
            <IonIcon icon={storefrontOutline} style={ionTabButtonIconStyle()} />
            <IonLabel style={ionLabelStyle(theme)}>Lojas</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab={AUTH_TABS.STORES}
            href={storesPath}
            style={ionTabButtonStyle(theme)}
          >
            <IonIcon icon={addCircleOutline} style={ionTabButtonIconStyle()} />
            <IonLabel style={ionLabelStyle(theme)}>Novo pedido</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab={AUTH_TABS.ORDERS}
            href="/orders"
            style={ionTabButtonStyle(theme)}
          >
            <IonIcon icon={archive} style={ionTabButtonIconStyle()} />
            <IonLabel style={ionLabelStyle(theme)}>Pedidos</IonLabel>
          </IonTabButton>
        </IonTabBar>
      )}
    </IonTabs>
  );
};

export default AuthRoutes;
