import { Grid } from "@mui/material";
import Typography from "components/Typography";
import { ProductCardProps } from "pages/products/types";
import { useTheme } from "styled-components";

import {
  StyledCardProduct,
  ProductContainer,
  ProductInfoContainer,
  ProductIonImg,
} from "./styles";

const ProductCardView = ({ product, handleClick }: ProductCardProps) => {
  const theme = useTheme();

  return (
    <ProductContainer onClick={handleClick}>
      <StyledCardProduct>
        <Grid container direction="column" alignItems="center">
          <Grid item xs>
            <ProductIonImg src={product?.images[0]?.images} />
          </Grid>
          <ProductInfoContainer item xs>
            <Typography variant={theme.typography.body[3].regular}>
              <span className="text-ellipsis">{product.name}</span>
            </Typography>
          </ProductInfoContainer>
        </Grid>
      </StyledCardProduct>
    </ProductContainer>
  );
};

export default ProductCardView;
