import { StyledSubheaderLight, StyledSubheaderDark } from "./styles";
import { SimpleSubheaderProps } from "./types";

const SimpleSubheader = ({
  variant = "dark",
  children,
}: SimpleSubheaderProps) => {
  const StyledSubheader = {
    light: StyledSubheaderLight,
    dark: StyledSubheaderDark,
  }[variant];
  return <StyledSubheader>{children}</StyledSubheader>;
};

export default SimpleSubheader;
