import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectInputIconLeft } from "components/SelectInputIcon";
import Typography from "components/Typography";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "styled-components";

import { FormControlContainer, SelectBaseInput, StyledTitle } from "./styles";
import { SelectInputProps } from "./types";

const SelectObjectInputView = ({
  placeholder,
  title,
  name,
  control,
  items,
  disabled = false,
}: SelectInputProps) => {
  const theme = useTheme();
  return (
    <FormControlContainer>
      {title && (
        <StyledTitle>
          <Typography
            variant={theme.typography.body[2].regular}
            color={theme.color.grayscale[8]}
          >
            {title}
          </Typography>
        </StyledTitle>
      )}
      <Controller
        render={({ field }) => (
          <Select
            value={field.value ?? ""}
            onChange={(e) => field.onChange(e.target.value)}
            IconComponent={SelectInputIconLeft}
            input={<SelectBaseInput />}
            variant="standard"
            displayEmpty
            disabled={disabled}
            renderValue={(value) => {
              if (!value) return placeholder;
              const item = items.find(({ id: v }) => v === value);
              return item?.name;
            }}
          >
            <MenuItem value={undefined}>
              <Fragment>
                {placeholder} {<SelectInputIconLeft />}
              </Fragment>
            </MenuItem>
            {items?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControlContainer>
  );
};

export default SelectObjectInputView;
