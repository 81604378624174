import { yupResolver } from "@hookform/resolvers/yup";
import { useIonAlert } from "@ionic/react";
import { useMediaQuery } from "@mui/material";
import { tabletMinWidth } from "commons/constants/configuration.constant";
import { UNEXPECTED_ERROR } from "commons/constants/message.constants";
import { UPDATE_USER_DATA_ERROR } from "commons/constants/user.constants";
import { UserUpdateModel } from "commons/types/user.model";
import { MASK } from "commons/utils/format";
import { useAuth } from "contexts/auth";
import { StatusCodes } from "http-status-codes";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import UserService from "services/user.service";
import { useTheme } from "styled-components";

import { accountValidationSchema, userDefaultValues } from "./utils";

export const useEditAccountController = () => {
  const { user, getUser } = useAuth();
  const theme = useTheme();
  const history = useHistory();
  const isTablet = useMediaQuery(tabletMinWidth);
  const [presentAlert] = useIonAlert();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChangedEmail, setIsChangedEmail] = useState(false);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    history.push("/account");
    if (user) {
      getUser(user.email);
    }
  };

  const handleResetUserData = async () => {
    try {
      localStorage.removeItem("djangoTokens");
      localStorage.removeItem("jwtToken");
      setIsDialogOpen(false);
      history.go(0);
    } catch (error) {
      showError(UNEXPECTED_ERROR);
    }
  };

  const handleGoBack = () => {
    history.push("/account");
  };

  const showError = (message: string) => {
    presentAlert({
      header: "Alerta",
      message,
      buttons: ["OK"],
    });
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserUpdateModel>({
    defaultValues: user ? userDefaultValues(user) : {},
    resolver: yupResolver(accountValidationSchema),
  });

  const watchedIdentification = watch("identification_number");
  const identificationMask = useMemo(() => {
    if (!watchedIdentification) return MASK.CPF;
    if (watchedIdentification.length > 11) return MASK.CNPJ;
    return MASK.CPF;
  }, [watchedIdentification]);

  const onSubmit = async (data: UserUpdateModel) => {
    try {
      if (user?.id) {
        const response = await UserService.updateUserData(user.id, data);
        if (response.status !== StatusCodes.OK) {
          throw new Error(UPDATE_USER_DATA_ERROR);
        }
        if (data.email !== user.email) {
          setIsChangedEmail(true);
        }
        setIsDialogOpen(true);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  return {
    theme,
    isTablet,
    isDialogOpen,
    handleCloseDialog,
    handleGoBack,
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    identificationMask,
    isChangedEmail,
    handleResetUserData,
  };
};
