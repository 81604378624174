import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { DefaultTheme, useTheme } from "styled-components";

export const ButtonPrimaryStyled = styled(Button)`
  background-color: ${() => useTheme().color.neutralLight.default};
  color: ${() => useTheme().color.primary.default};
  border: 1px solid ${() => useTheme().color.primary.default};
  box-shadow: none;
  width: 100%;
  margin: 0;
  padding: 14px 0;
  border-radius: 8px;
  text-transform: none;

  &:hover,
  &:active,
  &:focus {
    background-color: ${() => useTheme().color.neutralLight.default};
    color: ${() => useTheme().color.primary.default};
    border: 1px solid ${() => useTheme().color.primary.default};
    box-shadow: none;
  }
  &:disabled {
    background-color: ${() => useTheme().color.grayscale[4]} !important;
    color: white !important;
    border: none;
  }

  @media (min-width: 734px) {
    max-width: 335px;
  }
`;

export const buttonPrimaryStyle = (theme: DefaultTheme) =>
  theme.typography.body[2].highlight;
