import Grid from "@mui/material/Grid";
import removeIcon from "assets/svgs/icons/trash.svg";
import DialogWithButtons from "components/DialogWithButtons";
import Typography from "components/Typography";
import { Fragment } from "react";
import { useTheme } from "styled-components";

import { SupplierDialogsProps } from "./types";

const SupplierDialogsView = ({
  supplier,
  isRemoveDialogOpen,
  handleCloseRemoveDialog,
  handleDoRemove,
  isMinOrderDialogOpen,
  handleCloseMinOrderDialog,
}: SupplierDialogsProps) => {
  const theme = useTheme();

  return (
    <Fragment>
      <DialogWithButtons
        showIcon
        isDangerAction
        hasAutoSizedBtns
        isOpen={isRemoveDialogOpen}
        onClose={handleCloseRemoveDialog}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, excluir"
        onClickPrimaryButton={handleCloseRemoveDialog}
        onClickSecondaryButton={() => handleDoRemove(supplier.id)}
        icon={<img src={removeIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].highlight}
              color={theme.color.error.default}
            >
              <b>Você tem certeza que quer excluir o pedido da fábrica?</b>
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              Todos os produtos adicionados desta fábrica irão ser excluidos do
              seu carrinho.
            </Typography>
          </>
        }
      />

      <DialogWithButtons
        showIcon
        hasAutoSizedBtns
        isOpen={isMinOrderDialogOpen}
        onClose={handleCloseMinOrderDialog}
        secondaryButtonText="Voltar"
        onClickSecondaryButton={handleCloseMinOrderDialog}
        icon={<img src={removeIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].highlight}
              color={theme.color.error.default}
            >
              <b>O pedido mínimo não foi atingido</b>
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              {`O pedido mínimo de uma das fábricas não foi atingido, adicione mais
              produtos.`}
            </Typography>
          </>
        }
      />
    </Fragment>
  );
};

export default SupplierDialogsView;
