import { Divider, Grid } from "@mui/material";
import { MASK } from "commons/utils/format";
import FormInputView from "components/FormInput";
import FormNumberInputView from "components/FormNumberInput";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { EditAccountFormProps } from "./types";

const EditAccountForm = ({
  register,
  errors,
  control,
  identificationMask,
  isTablet,
}: EditAccountFormProps) => {
  const theme = useTheme();

  return (
    <Grid mx={3} width={isTablet ? 488 : "100%"}>
      <Grid container direction="column" mb={3}>
        <Grid item xs>
          <Typography
            variant={theme.typography.heading[2].highlight}
            color={theme.color.grayscale[9]}
          >
            Informações básicas
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography
            variant={theme.typography.body[4].regular}
            color={theme.color.grayscale[7]}
          >
            Os campos marcados com (*) são obrigatórios
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" mb={1}>
        <FormInputView
          field="name"
          title="Nome*"
          placeholder="Insira o seu nome"
          register={register}
          control={control}
          errors={errors}
        />

        <FormNumberInputView
          field="identification_number"
          title="CPF / CNPJ*"
          placeholder="Insira seu CPF ou CNPJ"
          format={identificationMask}
          register={register}
          control={control}
          errors={errors}
        />
      </Grid>

      <Grid item mb={3}>
        <Divider />
      </Grid>

      <Grid container direction="column" mb={3}>
        <Grid item xs>
          <Typography
            variant={theme.typography.heading[2].highlight}
            color={theme.color.grayscale[9]}
          >
            Informações de contato
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography
            variant={theme.typography.body[4].regular}
            color={theme.color.grayscale[7]}
          >
            Os campos marcados com (*) são obrigatórios
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" marginBottom="10px">
        <FormInputView
          field="email"
          title="Email*"
          placeholder="Insira um email"
          register={register}
          control={control}
          errors={errors}
        />

        <FormNumberInputView
          field="whatsapp"
          title="WhatsApp*"
          placeholder="Insira número de WhatsApp"
          format={MASK.CELLPHONE}
          register={register}
          control={control}
          errors={errors}
        />

        <FormNumberInputView
          field="phone"
          title="Telefone (opcional)"
          placeholder="Insira número de telefone"
          format={MASK.CELLPHONE}
          register={register}
          control={control}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
};

export default EditAccountForm;
