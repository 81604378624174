import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { productDescriptionStyles } from "./styles";
import { ProductDescriptionProps } from "./types";

const ProductDescriptionView = ({ product }: ProductDescriptionProps) => {
  const theme = useTheme();
  const styles = productDescriptionStyles();

  return (
    <Card style={styles.descriptionCard}>
      <Grid container direction="column" item xs>
        <Grid item xs>
          <Typography
            variant={theme.typography.body[2].highlight}
            color={theme.color.grayscale[10]}
          >
            Descrição
          </Typography>
        </Grid>

        <Grid item xs style={styles.descriptionContent}>
          <Typography
            variant={theme.typography.body[3].regular}
            color={theme.color.grayscale[7]}
          >
            {product.description ? product.description : "Sem descrição"}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductDescriptionView;
