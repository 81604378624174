import { IonCard, IonSearchbar, IonSegment } from "@ionic/react";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import styled from "styled-components";

export const StyledIonCard = styled(IonCard)`
  padding: unset;
  margin: 0 !important;
  border-radius: 8px;
  box-shadow: ${(props) =>
    props.class === "isDetailsView"
      ? "none"
      : "0px 2px 4px rgba(0, 0, 0, 0.14)"} !important;
  width: ${(props) => (props.class === "isDetailsView" ? "100%" : "324px")};
  @media (min-width: 600px) {
    width: 400px;
  }
`;

export const StyledSearchbar = styled(IonSearchbar)`
  padding: unset;
  width: 324px;
  @media (min-width: 600px) {
    width: 400px;
  }

  & .searchbar-input {
    text-align: left;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14) !important;
  }
  & .searchbar-input-container > input {
    color: ${({ theme }) => theme.color.grayscale[6]};
    --placeholder-opacity: 1;
    border-radius: 4px;
    font-size: ${({ theme }) => theme.typography.body[2].regular.fontSize};
    font-weight: ${({ theme }) => theme.typography.body[2].regular.fontWeight};
  }
  & .searchbar-input-container ion-icon {
    color: ${({ theme }) => theme.color.grayscale[8]} !important;
  }
`;

export const StyledIonSegment = styled(IonSegment)`
  background: white;
`;

export const orderStyles = (isDetailsView?: boolean): MaterialStyles => ({
  card: {
    padding: "unset",
    margin: 0,
    borderRadius: 8,
    boxShadow: isDetailsView ? "none" : "0px 2px 4px rgba(0, 0, 0, 0.14)",
    width: isDetailsView ? "100%" : 324,
    "@media(min-width:600px)": {
      width: isDetailsView ? "100%" : 400,
    },
  },
  cartTitle: {
    padding: "40px 0 0 40px",
  },
  container: {
    padding: "15px 20px",
  },
  image: {
    width: "60px",
    height: "60px",
    borderRadius: 8,
  },
  imagePlaceholder: {
    width: "60px",
    height: "40px",
    borderRadius: 8,
  },
  chip: {
    height: 22,
  },
  header: {
    width: "100%",
    background: "black",
    padding: 4,
    "@media(min-width:600px)": {
      textAlign: "center",
      padding: "30px 0",
    },
  },
  suppliersList: {
    maxHeight: 37,
    overflow: "hidden",
  },
  section: {
    width: "100%",
    marginBottom: 2,
    justifyContent: "center",
  },
});
