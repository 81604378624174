import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const productFilterButtonStyles = (
  theme: DefaultTheme,
  isActive: boolean
): MaterialStyles => ({
  mainContainer: {
    height: "74px",
    width: "88px",
    left: "24px",
    top: "15px",
    borderRadius: "8px 8px 0px 0px",
    boxShadow: "none",
    padding: "10px, 5px, 15px, 5px",
    margin: "0 6px",
  },
  mainGrid: {
    height: "100%",
    borderBottom: isActive
      ? `solid 2px ${theme.color.primary.default}`
      : "unset",
  },
  icon: {
    margin: "auto",
    fontSize: "22px",
  },
  textContainer: {
    margin: "0px 5px 10px 5px",
  },
});
