// TODO: expand this theme and replace styled-components with it

import { createTheme } from "@mui/material";

export const defaultMuiTheme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
  palette: {
    primary: {
      main: "#4305f2",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#190259",
      contrastText: "#ffffff",
    },
    error: {
      main: "#c93030",
      contrastText: "#ffffff",
    },
    success: {
      main: "#28a534",
      contrastText: "#ffffff",
    },
    info: {
      main: "#021859",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#f2b705",
      contrastText: "#000000",
    },
  },
});
