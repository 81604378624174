import {
  ProductCategoryModel,
  ProductModel,
  ProductTagModel,
} from "commons/types/product.model";

export interface ListProductsProps {
  products: ProductModel[];
  categories: ProductCategoryModel[];
  tags: ProductTagModel[];
  isLoading: boolean;
  handleClickDetails: (product: ProductModel) => void;
  // General list props
  isGeneralInfiniteDisabled: boolean;
  handleGeneralLoadData: (event: CustomEvent) => void;
}

export interface DefaultAllProductFilters {
  searchText: string;
  page: number;
  isInfiniteDisabled: boolean;
}

export const defaultAllFilter: DefaultAllProductFilters = {
  searchText: "",
  page: 1,
  isInfiniteDisabled: false,
};
