import { api } from "./api.service";

const MODULE_PATH = "microregion";

export default class MicroregionService {
  static async get({ page = 1, search = "" }) {
    const options = {
      params: {
        page,
        search,
      },
    };
    return api.get(`${MODULE_PATH}/`, options);
  }
}
