import { Grid } from "@mui/material";
import { shortenText } from "commons/utils/general";
import { useTheme } from "styled-components";

import ImgPlaceholder from "../../../../assets/svgs/img_placeholder.svg";
import Link from "../../../../components/Link";
import Typography from "../../../../components/Typography";
import {
  storeCardStyles,
  StyledIonCard,
  StyledIonImg,
  StyledNameIonRow,
} from "./styles";
import { StoreCardProps } from "./types";

const StoreCard = ({ store, handleClick }: StoreCardProps) => {
  const theme = useTheme();

  const styles = storeCardStyles();

  return (
    <StyledIonCard>
      <Grid container direction="row" onClick={() => handleClick(store)}>
        <Grid item xs={4}>
          {store.logo ? (
            <StyledIonImg src={store.logo} />
          ) : (
            <StyledIonImg src={ImgPlaceholder} />
          )}
        </Grid>
        <Grid
          container
          direction="column"
          item
          xs={8}
          style={styles.infoContainer}
        >
          <StyledNameIonRow>
            <Typography
              color="dark"
              variant={theme.typography.heading[4].highlight}
              style={styles.storeName}
            >
              {shortenText(store.fantasy_name, 16)}
            </Typography>
          </StyledNameIonRow>
          <Grid item xs>
            <Typography variant={theme.typography.body[3].regular}>
              Código: {store.id}
            </Typography>
          </Grid>
          <Grid item xs>
            <Link handleClick={() => handleClick(store)} title="Ver detalhes" />
          </Grid>
        </Grid>
      </Grid>
    </StyledIonCard>
  );
};

export default StoreCard;
