import { MaterialStyles } from "commons/types/generics/materialStyles";

export const accountStyles = (): MaterialStyles => ({
  logoutButton: {
    textTransform: "none",
    borderBottom: "2px solid #c93030",
    padding: 0,
    borderRadius: 0,
  },
});
