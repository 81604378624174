import { IonPage } from "@ionic/react";
import { Card, Grid } from "@mui/material";
import HeaderWithButton from "components/HeaderWithButton";
import ImageSlider from "components/ImageSlider";
import Typography from "components/Typography";
import { RouteComponentProps } from "react-router";
import { useTheme } from "styled-components";
import { StyledIonContent } from "theme/global.styles";

import ProductAttributeSelectView from "./components/ProductAttributeSelect";
import ProductDescriptionView from "./components/ProductDescription";
import ProductQuantityInputView from "./components/ProductQuantityInput";
import ProductSubtotalView from "./components/ProductSubtotal";
import ProductSupplierCardView from "./components/ProductSupplierCard";
import { useProductDetailController } from "./controller";
import {
  productDetailstStyles,
  StyledHeaderGrid,
  StyledImagesGrid,
  StyledMainGrid,
  StyledSubGrid,
} from "./styles";

const ProductDetailView = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const {
    control,
    isLoading,
    isEditingCart,
    characteristicLabel,
    isPaymentMethodLoaded,
    product,
    supplier,
    productImages,
    productOptions,
    inputOptions,
    addedProductVariants,
    handleOnAdd,
    handleOnRemove,
    productTotal,
    supplierTotal,
    handleApplyDiscount,
  } = useProductDetailController(match.params.id);

  const theme = useTheme();
  const styles = productDetailstStyles();

  if (isLoading || !isPaymentMethodLoaded)
    return (
      <IonPage>
        <HeaderWithButton />
        <StyledIonContent />
      </IonPage>
    );

  return (
    <IonPage>
      <HeaderWithButton />
      <StyledIonContent>
        <StyledMainGrid container direction="column">
          <StyledHeaderGrid item>
            <Typography
              variant={theme.typography.heading[3].highlight}
              color={theme.color.grayscale[10]}
            >
              {product.name}
            </Typography>
          </StyledHeaderGrid>
          {product?.images?.length && (
            <StyledImagesGrid>
              <ImageSlider imageList={productImages} />
            </StyledImagesGrid>
          )}

          {!isEditingCart ? null : (
            <StyledSubGrid item>
              <Card style={styles.sizeCardContainer}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs>
                    <Typography
                      variant={theme.typography.body[3].highlight}
                      color={theme.color.grayscale[10]}
                    >
                      Características
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant={theme.typography.body[2].regular}
                      color={theme.color.grayscale[9]}
                    >
                      {characteristicLabel}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </StyledSubGrid>
          )}

          {!productOptions?.length && !isEditingCart
            ? null
            : productOptions.map((option, index) => {
                return (
                  <StyledSubGrid item key={index}>
                    <ProductAttributeSelectView
                      title={option.label}
                      name={`${index}`}
                      control={control}
                      items={option.values || []}
                    />
                  </StyledSubGrid>
                );
              })}

          {!inputOptions ? null : (
            <StyledSubGrid item>
              <Card style={styles.sizeCardContainer}>
                <Typography
                  variant={theme.typography.body[2].regular}
                  color={theme.color.grayscale[10]}
                >
                  {inputOptions.label}
                </Typography>
                {inputOptions?.values?.map((attribute, index) => {
                  const quantity =
                    (addedProductVariants[attribute.skuId] &&
                      addedProductVariants[attribute.skuId].quantity) ||
                    0;
                  return (
                    <ProductQuantityInputView
                      position={index}
                      itemsQuantity={inputOptions?.values?.length || 10}
                      key={index}
                      control={control}
                      name={`sku-${attribute.skuId}`}
                      title={attribute.name}
                      wasAdded={!!addedProductVariants[attribute.skuId]}
                      quantity={quantity}
                      price={attribute.price}
                      multiple={attribute.multiple}
                      handleOnAdd={() => handleOnAdd(attribute)}
                      handleOnRemove={() => handleOnRemove(attribute)}
                      isInputDisabled={true}
                      handleApplyDiscount={handleApplyDiscount}
                    />
                  );
                })}
              </Card>
            </StyledSubGrid>
          )}

          <StyledSubGrid item>
            <ProductSubtotalView
              productTotal={handleApplyDiscount(productTotal)}
              supplierTotal={supplierTotal}
            />
          </StyledSubGrid>
          <StyledSubGrid item>
            <ProductSupplierCardView supplier={supplier} />
          </StyledSubGrid>
          <StyledSubGrid item>
            <ProductDescriptionView product={product} />
          </StyledSubGrid>
        </StyledMainGrid>
      </StyledIonContent>
    </IonPage>
  );
};

export default ProductDetailView;
