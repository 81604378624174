import { IonGrid, IonRow } from "@ionic/react";
import ImgPlaceholder from "assets/svgs/img_placeholder.svg";
import Link from "components/Link";
import Typography from "components/Typography";
import { FC } from "react";
import { useTheme } from "styled-components";

import {
  StyledIonCard,
  StyledIonColInfo,
  StyledImg,
  StyledNameIonRow,
  typographyBaseStyle,
  typographyTitleStyle,
  StyledIonCol,
} from "./styles";
import { CardSmallProps } from "./types";

const CardSmall: FC<CardSmallProps> = ({
  logo = ImgPlaceholder,
  title,
  subtitle,
  linkText,
  handleClick,
}) => {
  const theme = useTheme();

  return (
    <StyledIonCard>
      <IonGrid className="no-padding">
        <IonRow>
          <StyledIonCol className="no-padding">
            <StyledImg src={logo} />
          </StyledIonCol>
          <StyledIonColInfo size="8">
            <IonGrid className="no-padding">
              <StyledNameIonRow>
                <Typography
                  color={theme.color.grayscale[9]}
                  variant={theme.typography.subheading[2].highlight}
                  unwrapped
                  style={typographyTitleStyle}
                >
                  {title}
                </Typography>
              </StyledNameIonRow>
              <StyledNameIonRow>
                <Typography
                  color={theme.color.grayscale[7]}
                  variant={theme.typography.body[4].regular}
                  unwrapped
                  style={typographyBaseStyle}
                >
                  {subtitle}
                </Typography>
              </StyledNameIonRow>
              <IonRow>
                <Link handleClick={handleClick} title={linkText} />
              </IonRow>
            </IonGrid>
          </StyledIonColInfo>
        </IonRow>
      </IonGrid>
    </StyledIonCard>
  );
};

export default CardSmall;
