import { useIonLoading } from "@ionic/react";
import { STORES_LOAD_ERROR } from "commons/constants/message.constants";
import { MicroregionModel } from "commons/types/microregion.model";
import { getAvailableCities } from "commons/utils/general";
import { useAuth } from "contexts/auth";
import { useConfiguration } from "contexts/configuration";
import { Location } from "history";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";

import { StoreModel } from "../../commons/types/store.model";
import StoreService from "../../services/store.service";

export const useStoresController = (location: Location<MicroregionModel>) => {
  const history = useHistory();
  const { user } = useAuth();
  const { presentError } = useConfiguration();

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();
  const [stores, setStores] = useState<StoreModel[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [present, dismiss] = useIonLoading();

  const loadStores = useCallback(async () => {
    try {
      if (isInfiniteDisabled || !user) return;
      const microregions = location?.state
        ? [location?.state.id]
        : user.microregions.map((m) => m.id);
      const searchParams = {
        page,
        search: searchText,
        cityName: searchCity,
        microregions,
      };
      const response = await StoreService.getMany(searchParams);
      const _stores: StoreModel[] = response.data?.results;
      const canSearch =
        stores.concat(_stores).length < (response?.data?.count || 0);
      if (page === 1) {
        setStores(_stores);
      } else if (_stores.length && page != 1) {
        setStores((oldStores) => oldStores.concat(_stores));
      }

      if (!canSearch) {
        setInfiniteDisabled(true);
      }
      if (canSearch && scrollEvent) {
        // Complete the scroll event, so que pagination can work
        (scrollEvent.target as HTMLIonInfiniteScrollElement).complete();
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, STORES_LOAD_ERROR);
    } finally {
      setLoading(false);
      dismiss();
    }
  }, [
    page,
    setPage,
    searchText,
    setSearchText,
    setStores,
    isInfiniteDisabled,
    searchCity,
  ]);

  const loadCityOptions = () => {
    const userMicroregions =
      (location.state ? [location.state] : user?.microregions) || [];
    const cityOptions = getAvailableCities(userMicroregions);
    const uniqueOptions = cityOptions
      .filter((city, position) => cityOptions.indexOf(city) == position)
      .sort();
    setCityOptions(uniqueOptions);
  };

  const cleanSearch = () => {
    setPage(1);
    setInfiniteDisabled(false);
  };

  const handleSearchText = (text: string) => {
    setSearchText(() => {
      cleanSearch();
      return text;
    });
  };

  const handleClearText = () => {
    setSearchText(() => {
      cleanSearch();
      return "";
    });
  };

  const handleSearchCity = (value: string) => {
    setSearchCity(() => {
      cleanSearch();
      return value;
    });
  };

  const handleClickDetails = (store: StoreModel) => {
    history.push(`/stores/${store.id}`);
  };

  const handleLoadData = useCallback(
    (event: CustomEvent<void>) => {
      setPage((oldPage) => {
        setScrollEvent(event);
        return oldPage + 1;
      });
    },
    [setPage, page]
  );

  useEffect(() => {
    // TODO: add redux or use context for loading
    present({
      message: "Carregando...",
      duration: 30000,
    });
  }, []);

  // TODO: fix Ionic being unable to reset a stack when their tab switches
  useLayoutEffect(() => {
    if (
      location.pathname.includes("microregion") &&
      typeof location.state === "undefined"
    ) {
      history.push("/microregions");
    }
  }, [location]);

  useEffect(() => {
    const viewState = {
      mounted: true,
    };

    // TODO: use viewState to prevent state updates when component unmounts
    loadStores();
    return () => {
      viewState.mounted = false;
    };
  }, [page, searchText, searchCity]);

  useEffect(() => {
    loadCityOptions();
  }, [user]);

  return {
    stores,
    page,
    isLoading,
    isInfiniteDisabled,
    handleLoadData,
    handleClickDetails,
    searchText,
    handleSearchText,
    handleClearText,
    searchCity,
    handleSearchCity,
    cityOptions,
  };
};
