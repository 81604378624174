import { Button, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import editIcon from "assets/svgs/icons/edit.svg";
import trashBinIcon from "assets/svgs/trash_bin.svg";
import { formatCurrency } from "commons/utils/format";
import Typography from "components/Typography";
import { supplierCardStyles } from "pages/cart/components/SupplierCard/styles";
import { useTheme } from "styled-components";

import { supplierDetailsStyles } from "../../styles";
import { ProductVariantItemProps } from "./types";

const ProductVariantItem = ({
  quantity,
  price,
  label,
  onClickEdit,
  onClickRemove,
  showButtons = true,
}: ProductVariantItemProps) => {
  const theme = useTheme();
  const { removeButton } = supplierCardStyles();
  const {
    variantItemContainer,
    editButton,
    itemContent,
    actionsContainer,
    editButtonContainer,
    actionIcon,
  } = supplierDetailsStyles();

  return (
    <Grid item sx={variantItemContainer}>
      <Divider />
      <Grid container direction="column" sx={itemContent}>
        <Grid item>
          <Grid container>
            <Grid item xs={1.5}>
              <Typography variant={theme.typography.body[2].regular}>
                x{quantity}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant={theme.typography.body[2].regular}>
                {label}
              </Typography>
            </Grid>
            <Grid item textAlign="end" xs={3}>
              <Typography variant={theme.typography.body[4].highlight}>
                {formatCurrency(price)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {!showButtons ? null : (
          <Grid item sx={actionsContainer}>
            <Grid container>
              <Grid item sx={editButtonContainer}>
                <Grid container alignItems="center">
                  <Grid item sx={actionIcon}>
                    <img src={editIcon} />
                  </Grid>
                  <Grid item>
                    <Button
                      color="secondary"
                      style={editButton}
                      onClick={onClickEdit}
                    >
                      <Typography variant={theme.typography.body[4].highlight}>
                        Alterar
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item sx={actionIcon}>
                    <img src={trashBinIcon} />
                  </Grid>
                  <Grid item>
                    <Button
                      color="error"
                      style={removeButton}
                      onClick={onClickRemove}
                    >
                      <Typography variant={theme.typography.body[4].highlight}>
                        Excluir
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductVariantItem;
