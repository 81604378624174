import Card from "@mui/material/Card";
import SelectObjectInputView from "components/inputs/SelectObjectInput";

import { productSelectStyles } from "./styles";
import { ProductAttributeSelectProps } from "./types";

const ProductAttributeSelectView = ({
  title,
  name,
  items,
  control,
}: ProductAttributeSelectProps) => {
  const styles = productSelectStyles();

  return (
    <Card style={styles.mainContainer}>
      <SelectObjectInputView
        title={title}
        placeholder="Selecione..."
        name={name}
        control={control}
        items={items}
      />
    </Card>
  );
};

export default ProductAttributeSelectView;
