import { IonLoading } from "@ionic/react";
import Grid from "@mui/material/Grid";
import { ProductModel } from "commons/types/product.model";
import Chubbs from "components/Chubbs";
import { StyledProductCardsGrid } from "theme/global.styles";

import ProductCardView from "../../ProductCard";
import {
  litProductItemsCreateStyles,
  StyledChubbsGrid,
  StyledProductGrid,
} from "./styles";
import { ListProductItemsProps } from "./types";

/**
 * Renders a list of products based on a criteria, if there is not product show an image placeholder
 */
const ListProductItemsView = ({
  products,
  isLoading,
  showItems,
  customMessage,
  handleClickDetails,
}: ListProductItemsProps) => {
  const styles = litProductItemsCreateStyles();

  if (isLoading) {
    return (
      <Grid style={styles.mainContainer}>
        <IonLoading isOpen={isLoading} message={"Carregando..."} />
      </Grid>
    );
  }

  return (
    <Grid style={styles.mainContainer}>
      {showItems ? (
        products?.length ? (
          <StyledProductCardsGrid>
            {products.map((item: ProductModel) => (
              <StyledProductGrid key={item.id}>
                <ProductCardView
                  product={item}
                  handleClick={() => handleClickDetails(item)}
                />
              </StyledProductGrid>
            ))}
          </StyledProductCardsGrid>
        ) : (
          <StyledChubbsGrid alignContent="center" justifyContent="center">
            <Chubbs variant="not-found" />
          </StyledChubbsGrid>
        )
      ) : (
        <StyledChubbsGrid alignContent="center" justifyContent="center">
          <Chubbs variant="selected" customMessage={customMessage} />
        </StyledChubbsGrid>
      )}
    </Grid>
  );
};

export default ListProductItemsView;
