import { PRODUCTS_LOAD_ERROR } from "commons/constants/message.constants";
import { ProductModel } from "commons/types/product.model";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { useConfiguration } from "contexts/configuration";
import { useCallback, useEffect, useState } from "react";
import ProductService from "services/product.service";

import { defaultAllFilter, DefaultAllProductFilters } from "./types";

export const useAllProductsController = () => {
  const auth = useAuth();

  const { selectedStore } = useCart();
  const { presentError } = useConfiguration();

  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();
  const [products, setProducts] = useState<ProductModel[]>([]);

  const [filters, setFilters] =
    useState<DefaultAllProductFilters>(defaultAllFilter);

  const [isLoading, setIsLoading] = useState(true);

  const loadProducts = useCallback(async () => {
    try {
      const { page, isInfiniteDisabled, searchText } = filters;

      if (isInfiniteDisabled || !auth.user) return;
      const searchParams = {
        page,
        search: searchText,
        microregions: auth.user.microregions.map((m) => m.id),
        categories: [],
        tags: [],
      };
      const response = await ProductService.getMany(searchParams);
      const _products: ProductModel[] = response.data?.results;

      let canSearch = true;

      if (page === 1) {
        setProducts(_products);
        canSearch = _products.length < (response?.data?.count || 0);
      } else if (_products.length && page != 1) {
        setProducts((oldStores) => oldStores.concat(_products));
        canSearch =
          products.concat(_products).length < (response?.data?.count || 0);
      }

      if (!canSearch) {
        setInfiniteDisabled(true);
      }
      if (canSearch && scrollEvent) {
        // Complete the scroll event, so que pagination can work
        (scrollEvent.target as HTMLIonInfiniteScrollElement).complete();
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, PRODUCTS_LOAD_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [
    filters.page,
    filters.searchText,
    filters.isInfiniteDisabled,
    setProducts,
  ]);

  const setPage = (page: number) => {
    setFilters((oldFilters) => ({ ...oldFilters, page }));
  };
  const setInfiniteDisabled = (isInfiniteDisabled: boolean) => {
    setFilters((oldFilters) => ({ ...oldFilters, isInfiniteDisabled }));
  };

  const handleLoadData = useCallback(
    (event: CustomEvent<void>) => {
      setScrollEvent(event);
      setPage(filters.page + 1);
    },
    [filters.page]
  );

  const handleSearchText = useCallback((text: string) => {
    setFilters(() => {
      return {
        ...defaultAllFilter,
        searchText: text,
      };
    });
  }, []);

  const handleClearText = useCallback(() => {
    setFilters(() => defaultAllFilter);
  }, []);

  useEffect(() => {
    loadProducts();
  }, [
    loadProducts,
    filters.page,
    filters.searchText,
    filters.isInfiniteDisabled,
  ]);

  useEffect(() => {
    setIsLoading(true);
  }, [filters.searchText]);

  return {
    selectedStore,
    products,
    isLoading,
    filters,
    handleLoadData,
    handleSearchText,
    handleClearText,
  };
};
