import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import { MicroregionModel } from "commons/types/microregion.model";
import BackButtonHeader from "components/BackButtonHeader";
import Chubbs from "components/Chubbs";
import Searchbar from "components/Searchbar";
import SimpleSubheader from "components/SimpleSubheader";
import { useLocation } from "react-router";
import { useTheme } from "styled-components";

import { StoreModel } from "../../commons/types/store.model";
import SimpleHeader from "../../components/SimpleHeader";
import Typography from "../../components/Typography";
import StoreCard from "./components/StoreCard";
import { StoreCitySelect } from "./components/StoreCitySelect";
import { useStoresController } from "./controller";
import {
  FiltersContainer,
  StyledCardsIonGrid,
  StyledIonContent,
} from "./styles";

const StoresView: React.FC = () => {
  const location = useLocation<MicroregionModel>();
  const theme = useTheme();

  const HeaderVariant = location.state ? BackButtonHeader : SimpleHeader;
  const subheaderVariant = location.state ? "light" : "dark";

  const {
    stores,
    isLoading,
    isInfiniteDisabled,
    handleLoadData,
    handleClickDetails,
    searchText,
    handleSearchText,
    handleClearText,
    searchCity,
    handleSearchCity,
    cityOptions,
  } = useStoresController(location);

  return (
    <IonPage>
      <HeaderVariant />
      <StyledIonContent>
        <SimpleSubheader variant={subheaderVariant}>
          <Typography variant={theme.typography.heading[2].regular}>
            {location.state ? (
              <>
                Região <b>{location.state.name}</b>
              </>
            ) : (
              <>
                Escolha apenas <b>uma loja</b> para fazer o pedido.
              </>
            )}
          </Typography>
        </SimpleSubheader>
        <FiltersContainer container>
          <Searchbar
            value={searchText}
            onSearch={handleSearchText}
            onClear={handleClearText}
            placeholder="Buscar por nome ou código..."
          />
          <StoreCitySelect
            cityOptions={cityOptions}
            searchCity={searchCity}
            handleSearchCity={handleSearchCity}
          />
        </FiltersContainer>
        <StyledCardsIonGrid>
          {!isLoading ? (
            stores?.length ? (
              stores.map((item: StoreModel) => (
                <StoreCard
                  key={item.id}
                  store={item}
                  handleClick={handleClickDetails}
                />
              ))
            ) : (
              <Chubbs variant="not-found" />
            )
          ) : null}
        </StyledCardsIonGrid>
        <IonInfiniteScroll
          onIonInfinite={(e: CustomEvent<void>) => handleLoadData(e)}
          threshold="1%"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Carregando..."
          />
        </IonInfiniteScroll>
      </StyledIonContent>
    </IonPage>
  );
};

export default StoresView;
