import styled from "@emotion/styled";
import { InputBase, TextField } from "@mui/material";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme, useTheme } from "styled-components";

export const FormSelectInput = styled(InputBase)(() => {
  const theme = useTheme();

  return {
    "& .MuiInputBase-input": {
      backgroundColor: theme.color.neutralLight.default,
      borderRadius: "4px",
      color: theme.color.grayscale[7],
      opacity: 1,
      ...theme.typography.body[2].regular,
      lineHeight: "30px",
      padding: "10px 15px !important",
      width: "100%",
      textAlign: "left",
      boxShadow:
        "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.color.neutralLight.default,
    },
    "& .MuiSelect-nativeInput": {
      minWidth: "0px !important",
    },
    "&.Mui-focused": {
      backgroundColor: theme.color.neutralLight.default,
    },
  };
});

export const FormCustomField = styled(TextField)(() => {
  const theme = useTheme();

  return {
    "& input": {
      color: `${theme.color.grayscale[7]}`,
    },
    "& input::placeholder": {
      color: theme.color.grayscale[7],
      opacity: 1,
      ...theme.typography.body[2].regular,
    },
    "& .Mui-error": {
      "& input": {
        color: theme.color.error.default,
      },
      "& fieldset": {
        borderStyle: "none",
      },
    },
  };
});

export const formInputsStyles = (theme: DefaultTheme): MaterialStyles => ({
  inputContainner: {
    marginBottom: "25px",
  },
  cardContainer: {
    marginTop: "5px",
  },
  input: {
    width: "100%",
    padding: "14px 16px",
  },
  inputSelect: {
    fontFamily: "Inter",
    /* todo: check not working */
    color: `${theme.color.grayscale[7]} !important`,
  },
  fontInter: {
    fontFamily: "Inter",
  },
});
