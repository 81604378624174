import styledMui from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import styled, { useTheme } from "styled-components";
import { DefaultTheme } from "styled-components";

export const ProductBannerContainer = styledMui(Grid)`
  background-color: ${() => useTheme().color.neutralDark.default};
  overflow: hidden;
  max-height: 135px;

  @media (min-width: 734px) {
    max-height: 225px;
  }

`;

export const ProductBannerTypography = styledMui(Typography)`
  color: #ffff;
  text-decoration-color: ${() => useTheme().color.primary.default};
  font-size: ${() => useTheme().typography.heading[3].highlight.fontSize};
  padding: 15px 20px;

  @media (min-width: 734px) {
    padding: 30px;
    font-size: ${() => useTheme().typography.heading[0].regular.fontSize};
  }

  @media (min-width: 900px) {
    padding: 30px 0 30px 30px;
  }
`;

export const StyledBannerImg = styled.img`
  height: 100%;
  width: 100%;
  max-height: 145px;
  max-width: 302.53px;
  overflow: hidden;

  @media (min-width: 734px) {
    max-height: 436.05px;
    max-width: 302.53px;
    width: 80%;
  }
`;

export const bannersStyles = (theme: DefaultTheme): MaterialStyles => ({
  bannerGridStyle: {
    color: theme.color.neutralDark.default,
    overflow: "hidden",
  },
  bannerGridImageStyle: {
    textAlign: "right",
  },
});
