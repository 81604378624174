import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme, useTheme } from "styled-components";

export const StyledButton = styled(Button)`
  color: ${() => useTheme().color.primary.default};
  border: 1px solid ${() => useTheme().color.primary.default};
  border-radius: 8px;
  text-transform: none;
  padding: 6px 12px 6px 12px;
`;

export const buttonCartStyle = (theme: DefaultTheme) =>
  theme.typography.body[3].highlight;

export const buttonCardStyles = (theme: DefaultTheme): MaterialStyles => ({
  iconStyle: {
    fontSize: "18px",
    marginRight: "5px",
    verticalAlign: "middle",
    marginBottom: "2px",
    color: theme.color.grayscale[9],
  },
});
