import { AUTH_TABS } from "commons/types/configuration.enum";
import { useCart } from "contexts/cart";
import { useConfiguration } from "contexts/configuration";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

const useAuthRoutesController = () => {
  const history = useHistory();
  const { canShowTabs, redirectOrder } = useConfiguration();
  const { cartData, clearCurrentOrder, clearPaymentMethods } = useCart();

  const storesPath = useMemo(() => {
    return cartData.selectedStore
      ? `/${AUTH_TABS.STORES}/products`
      : `/${AUTH_TABS.STORES}`;
  }, [cartData.selectedStore]);

  const handleClickTab = (tab: string) => {
    switch (tab) {
      case AUTH_TABS.STORES:
        clearCurrentOrder();
        break;
      case AUTH_TABS.ORDERS:
        clearCurrentOrder();
        clearPaymentMethods();
        break;
      default:
        history.push(tab);
    }
  };

  useEffect(() => {
    if (redirectOrder) {
      history.push(`/orders/${redirectOrder}?load=true`);
    }
  }, [redirectOrder]);

  return {
    canShowTabs,
    storesPath,
    handleClickTab,
  };
};

export default useAuthRoutesController;
