import { REQUIRED_FIELD } from "commons/constants/form.message.constants";
import * as Yup from "yup";

export const sendMailValidationSchema = Yup.object().shape({
  storeEmail: Yup.string().email("E-mail inválido.").required(REQUIRED_FIELD),
  to: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(
      Yup.string().email(
        ({ value }) =>
          `${value} não é válido, digite corretamente os e-mails separados por vírgulas.`
      )
    ),
});
