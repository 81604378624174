import { ORDER_STATUS } from "commons/types/order.model";

import { StatusColor } from "./types";

export const getOrderStatusColor = (status?: ORDER_STATUS) => {
  const colors: Record<string, StatusColor> = {
    [ORDER_STATUS.SENT_TO_THE_OFFICE]: "warning",
    [ORDER_STATUS.IN_PROGRESS]: "warning",
    [ORDER_STATUS.ORDER_IN_SUPPLIER]: "success",
    [ORDER_STATUS.BILLED]: "success",
    [ORDER_STATUS.CANCELED]: "error",
  };

  return status && status in colors ? colors[status] : "warning";
};
