import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { formatCurrency } from "commons/utils/format";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { productSubtotalStyles } from "./styles";
import { ProductSubtotalProps } from "./types";

const ProductSubtotalView = ({
  productTotal,
  supplierTotal,
}: ProductSubtotalProps) => {
  const theme = useTheme();
  const styles = productSubtotalStyles();

  return (
    <Card style={styles.subtotalCard}>
      <Grid container direction="column" item xs spacing={2}>
        <Grid container item xs justifyContent="space-between">
          <Grid item>
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[6]}
            >
              Subtotal do produto:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={theme.typography.body[2].highlight}
              color={theme.color.primary.default}
            >
              {formatCurrency(productTotal)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs justifyContent="space-between">
          <Grid item>
            <Typography
              variant={theme.typography.subheading[3].regular}
              color={theme.color.grayscale[6]}
            >
              Subtotal da fábrica:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={theme.typography.subheading[3].regular}
              color={theme.color.grayscale[9]}
            >
              {formatCurrency(supplierTotal)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductSubtotalView;
