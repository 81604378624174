import { IonIcon } from "@ionic/react";
import { Card, Grid } from "@mui/material";
import Typography from "components/Typography";
import { ProductFilter } from "contexts/product/types";
import { Dispatch, SetStateAction } from "react";
import { useTheme } from "styled-components";

import { productFilterButtonStyles } from "./styles";

interface ProductFilterButtonProps {
  label: string;
  icon: string;
  selectedFilter: ProductFilter;
  filter: ProductFilter;
  setSelectedFilter: Dispatch<SetStateAction<ProductFilter>>;
}

const ProductFilterButtonView = ({
  label,
  icon,
  selectedFilter,
  setSelectedFilter,
  filter,
}: ProductFilterButtonProps) => {
  const theme = useTheme();
  const isActive = selectedFilter === filter;
  const styles = productFilterButtonStyles(theme, isActive);
  const currentColor = isActive
    ? theme.color.primary.default
    : theme.color.grayscale[10];

  const currentVariant = isActive
    ? theme.typography.body[4].highlight
    : theme.typography.body[4].regular;

  const currentIconColor = isActive ? "primary" : "neutralLight";

  return (
    <Card style={styles.mainContainer}>
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
        style={styles.mainGrid}
        onClick={() => setSelectedFilter(filter)}
      >
        <IonIcon icon={icon} color={currentIconColor} style={styles.icon} />
        <Grid style={styles.textContainer}>
          <Typography variant={currentVariant} color={currentColor}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductFilterButtonView;
