import { IonContent, IonFooter, IonPage } from "@ionic/react";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import cancelledIcon from "assets/svgs/chubbs/cancelled.svg";
import { tabletMinWidth } from "commons/constants/configuration.constant";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import HeaderWithButton from "components/HeaderWithButton";
import Typography from "components/Typography";
import { useTheme } from "styled-components";
import { TypographySpec } from "theme/defaultThemeDefinition";

import useOrderCancelledController from "./controller";
import { orderCancelledStyles } from "./styles";

const OrderCancelledView = () => {
  const theme = useTheme();
  const { handleOnClick, handleGoBack } = useOrderCancelledController();
  const isTablet = useMediaQuery(tabletMinWidth);
  const styles = orderCancelledStyles(isTablet);

  const fontVariant = isTablet
    ? (styles.title as TypographySpec)
    : theme.typography.heading[1].regular;

  return (
    <IonPage>
      <HeaderWithButton handleCustomBackClick={handleGoBack} />
      <IonContent>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          style={styles.mainContaier}
        >
          <Grid
            container
            direction={isTablet ? "row" : "column"}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            style={styles.mainGrid}
          >
            <Grid item xs sx={styles.messageContainer}>
              <Typography
                variant={fontVariant}
                color={theme.color.error.default}
              >
                Seu pedido foi <b>cancelado.</b>
              </Typography>
            </Grid>
            <Grid item xs>
              <img src={cancelledIcon} style={styles.icon} />
            </Grid>
          </Grid>
        </Grid>
      </IonContent>
      <IonFooter>
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          style={styles.buttonContainer}
        >
          <ButtonPrimaryFilled onClick={handleOnClick}>
            Iniciar novo pedido
          </ButtonPrimaryFilled>
        </Grid>
      </IonFooter>
    </IonPage>
  );
};

export default OrderCancelledView;
