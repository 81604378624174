import { IonIcon } from "@ionic/react";
import { Autocomplete, TextField } from "@mui/material";
import { SelectInputIconRight } from "components/SelectInputIcon";
import { chevronDown, locationOutline } from "ionicons/icons";
import { useTheme } from "styled-components";

import {
  StoreCitySelectFormControlStyled,
  StyledFormControlContainer,
  useStyles,
} from "./styles";
import { StoreCitySelectProps } from "./types";

/* TODO: transform into a reusable component */
export const StoreCitySelect: React.FC<StoreCitySelectProps> = ({
  cityOptions,
  searchCity,
  handleSearchCity,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  return cityOptions ? (
    <StyledFormControlContainer>
      <StoreCitySelectFormControlStyled>
        <SelectInputIconRight icon={locationOutline} />
        <Autocomplete
          id="autocomplete-cities"
          onChange={(event, value) => handleSearchCity(value ? value : "")}
          value={searchCity}
          disablePortal
          options={cityOptions}
          noOptionsText="Nenhuma cidade encontrada"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Filtrar por cidades"
              sx={styles.sx}
              style={styles.inputTextAutocomplete}
              focused={false}
            />
          )}
          getOptionLabel={(option) => option}
          popupIcon={<IonIcon icon={chevronDown} />}
          clearIcon={false}
        />
      </StoreCitySelectFormControlStyled>
    </StyledFormControlContainer>
  ) : null;
};
