import { yupResolver } from "@hookform/resolvers/yup";
import circleCheck from "assets/svgs/icons/circle-check.svg";
import {
  ORDER_CANCEL_ERROR,
  ORDER_CREATE_ERROR,
  ORDER_STATUS_CHANGE_ERROR,
  ORDER_STATUS_CHANGE_SUCCESS,
  ORDER_UPDATE_SUCCESS,
} from "commons/constants/order.constants";
import {
  OrderModel,
  OrderPostModel,
  OrderProductModel,
  ORDER_STATUS,
} from "commons/types/order.model";
import { formatDate, createDateFromSimpleDate } from "commons/utils/format";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { mapSuppliersToOrder } from "contexts/cart/utils";
import { useConfiguration } from "contexts/configuration";
import { StatusCodes } from "http-status-codes";
import {
  cartValidationSchema,
  validateSelectedSuppliers,
} from "pages/cart/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import OrderService from "services/order.service";

import { closedOrderStatus } from "../types";

export const useOrderDetailsController = (orderId: number) => {
  const { search } = useLocation();
  const forcedLoad = new URLSearchParams(search).get("load");

  const { user } = useAuth();
  const { presentToast } = useConfiguration();
  const {
    // Props
    removedProductIds,
    selectedSuppliers,
    paymentMethods,
    cartData,
    cartTotal,
    shouldLoadCart,
    paymentMethodBundle,
    // Actions
    setCurrentOrder,
    setShouldLoadCart,
    loadSupplierPaymentMethods,
    handleRemoveSupplier: handleCartRemoveSupplier,
    handleSelectStore,
    updateSuppliers,
    handleChangeSupplierPaymentMethod,
    showError,
    clearCart,
    clearAllData,
  } = useCart();

  // Form
  const {
    control,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    trigger,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(cartValidationSchema),
  });
  const history = useHistory();
  const { state } = useLocation<{ isEditingOrder: boolean }>();

  // Dialog states
  const [supplierToRemove, setSupplierToRemove] = useState<number | null>(null);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [isMinOrderDialogOpen, setIsMinOrderDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isFormInitiated, setIsFormInitiated] = useState(false);
  //TODO: revert
  const [isEditing, setIsEditing] = useState(!!state?.isEditingOrder);

  const [orderProducts, setOrderProducts] = useState<OrderProductModel>(
    {} as OrderProductModel
  );

  const selectedStatus = watch("status");

  const isAdmin = useMemo(() => !!user && user.is_staff, [user]);

  const canCancel = useMemo(() => {
    const canSellerCancel =
      cartData.currentOrder?.status === ORDER_STATUS.SENT_TO_THE_OFFICE &&
      !isAdmin;
    return isAdmin || canSellerCancel;
  }, [cartData.currentOrder, user]);

  const canEdit = useMemo(() => {
    if (!isAdmin) return false;
    if (!cartData?.currentOrder) return false;
    if (closedOrderStatus.includes(cartData.currentOrder.status)) return false;
    return true;
  }, [cartData.currentOrder]);

  const isButtonDisabled = useMemo(() => {
    return !selectedSuppliers.length;
  }, [selectedSuppliers?.length]);

  const orderCard = useMemo(() => {
    const { currentOrder } = cartData;
    return (
      currentOrder && {
        id: currentOrder.id,
        store: currentOrder.store.fantasy_name,
        order: currentOrder.id,
        suppliers: [],
        status: currentOrder.status,
        logo: currentOrder.store.logo,
        totalValue: cartTotal,
        email: currentOrder.store.email,
      }
    );
  }, [cartData, cartTotal]);

  const initFormValues = (order: OrderModel) => {
    if (order) {
      setValue("status", order.status);
      setValue("paymentDate", createDateFromSimpleDate(order.payment_date));
      setValue("observation", order.observation);
    }
  };

  const showSuccessToast = (message: string) => {
    presentToast({
      message: message,
      duration: 1500,
      color: "success",
      icon: circleCheck,
      position: "top",
      mode: "md",
    });
  };

  // Loaders
  const loadInitialData = async () => {
    try {
      await loadOrder();
      await loadOrderProducts();
      await loadPaymentMethods();
    } catch (error) {
      throw error;
    } finally {
      setShouldLoadCart(false);
      setIsLoading(false);
    }
  };

  const loadOrder = async () => {
    try {
      const response = await OrderService.getOne(orderId);
      if (response.data) {
        const _order: OrderModel = response.data;
        setCurrentOrder(_order);
        handleSelectStore(_order.store, false);
        return _order;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  const loadOrderProducts = async () => {
    try {
      const response = await OrderService.getProducts(orderId);
      const data: OrderProductModel = response?.data?.results;
      if (data) {
        setOrderProducts(data);
        updateSuppliers(data.suppliers);
      }
    } catch (error) {
      throw error;
    }
  };
  const loadPaymentMethods = useCallback(async () => {
    for (const supplier of selectedSuppliers) {
      await loadSupplierPaymentMethods(supplier.id);
    }
    setIsFormInitiated(true);
  }, [selectedSuppliers]);

  // Handlers
  // Set the initial value of the supplier payment method
  const handleSetSupplierPaymentMethod = (
    supplierId: number,
    supplierMethodId?: number
  ) => {
    if (!supplierMethodId && !cartData?.selectedPaymentMethod?.id) {
      setValue(`${supplierId}`, cartData.selectedPaymentMethod?.id);
    } else {
      setValue(`${supplierId}`, supplierMethodId);
    }
  };
  const handleOnSubmit = async () => {
    try {
      const shouldPass = await trigger();
      const isValid = validateSelectedSuppliers(selectedSuppliers);

      if (shouldPass) {
        clearErrors();
        if (isValid) {
          handleDoSubmit();
        } else {
          setIsMinOrderDialogOpen(true);
        }
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleDoSubmit = async () => {
    try {
      const { currentOrder } = cartData;
      const data: any = getValues();
      const suppliers = mapSuppliersToOrder(selectedSuppliers);

      setIsSubmiting(true);
      if (!cartData.selectedStore || !currentOrder || !user) return;
      let observation = currentOrder.observation;
      let paymentDate = formatDate(new Date(currentOrder.payment_date));

      if (isEditing) {
        observation = data.observation || null;
        paymentDate = formatDate(new Date(data.paymentDate));
      }

      const payload: OrderPostModel = {
        user_id: user.id,
        store_id: cartData.selectedStore.id,
        payment_date: paymentDate,
        observation: observation,
        suppliers,
        cart_id: null,
      };

      if (removedProductIds.length) {
        await handleRemoveOrderProducts();
      }

      const response = await OrderService.update(orderId, payload);
      if (response.status !== StatusCodes.OK) {
        throw new Error(ORDER_CREATE_ERROR);
      } else {
        showSuccessToast(ORDER_UPDATE_SUCCESS);
        clearAllData();
        history.replace("/orders");
      }
    } catch (error) {
      showError(ORDER_CREATE_ERROR);
    } finally {
      setIsSubmiting(false);
    }
  };

  const handleOpenCancelDialog = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCancelOrder = async () => {
    try {
      const payload = {
        status: ORDER_STATUS.CANCELED,
      };
      let response: any = null;

      if (isAdmin) {
        response = await OrderService.updateStatus(orderId, payload);
      } else {
        response = await OrderService.cancel(orderId);
      }
      if (response.status !== StatusCodes.OK) {
        throw new Error(ORDER_CANCEL_ERROR);
      } else {
        clearCart();
        history.push(`/orders/${orderId}/cancelled`);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleChangeOrderStatus = async () => {
    setIsStatusDialogOpen(true);
  };

  const handleDoChangeOrderStatus = async () => {
    try {
      const status = getValues("status");
      const response: any = await OrderService.updateStatus(orderId, {
        status,
      });
      if (response.status !== StatusCodes.OK) {
        throw new Error(ORDER_STATUS_CHANGE_ERROR);
      } else {
        showSuccessToast(ORDER_STATUS_CHANGE_SUCCESS);
        setIsStatusDialogOpen(false);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleRemoveOrderProducts = async () => {
    try {
      await OrderService.removeOrderProducts(orderId, removedProductIds);
    } catch (error) {
      throw error;
    }
  };

  // Redirects
  const handleGoBack = () => {
    history.push(`/orders`);
  };
  const handleKeepShopping = () => {
    history.push(`/stores/products#todos`);
  };

  const handleGoBackListOrders = () => {
    clearAllData();
    history.push("/orders");
  };

  const handleGoToSupplierProducts = (supplierId: number) => {
    const _supplier = selectedSuppliers.find((sup) => sup.id === supplierId);
    history.push(`/stores/product/supplier/${supplierId}`, _supplier);
  };

  const handleGoToSupplierDetails = (supplierId: number) => {
    history.push(`/orders/${orderId}/supplier/${supplierId}`, {
      isEditingOrder: isEditing,
      canEdit,
    });
  };

  const handleRemoveSupplier = async (orderId: number) => {
    setSupplierToRemove(orderId);
  };

  const handleDoRemoveSupplier = async (id: number) => {
    try {
      handleCartRemoveSupplier(id, true);
      setSupplierToRemove(null);
    } catch (error) {
      throw error;
    }
  };

  // Close Dialogs
  const handleCloseRemoveDialog = () => {
    setSupplierToRemove(null);
  };

  const handleCloseMinOrderDialog = () => {
    setIsMinOrderDialogOpen(false);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleCloseStatusDialog = async () => {
    setIsStatusDialogOpen(false);
  };

  const handleToggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const handleUpdateSupplierPaymentMethod = async (
    name: string | undefined,
    obj: any
  ) => {
    const supplierId = Number(name);
    if (supplierId) {
      const paymentMethodId = obj[supplierId];
      await handleChangeSupplierPaymentMethod(
        selectedSuppliers,
        supplierId,
        paymentMethodId,
        paymentMethodBundle
      );
    }
  };

  // Force load when query param is passed
  useEffect(() => {
    const shouldLoad = forcedLoad === "true";
    if (shouldLoad && !state?.isEditingOrder) {
      setShouldLoadCart(shouldLoad);
    }
  }, []);

  // Load order data
  useEffect(() => {
    if (orderId && shouldLoadCart) {
      loadInitialData();
    } else if (cartData.currentOrder?.id) {
      initFormValues(cartData.currentOrder);
      setIsLoading(false);
    }
  }, [orderId, shouldLoadCart]);

  useEffect(() => {
    if (!isLoading && !shouldLoadCart) {
      // TODO: sugestion: pre load all suppliers PMs once on a single point
      loadPaymentMethods();
    }
  }, [isLoading, shouldLoadCart]);

  /**
   * Update cart context based in the useForm changes
   */
  useEffect(() => {
    if (!isLoading && isFormInitiated) {
      const subscription = watch((obj, { name }) => {
        handleUpdateSupplierPaymentMethod(name, obj);
      });
      return () => subscription.unsubscribe();
    }
  }, [isLoading, isFormInitiated, watch()]);

  // Show order dialog for status change confirmation
  useEffect(() => {
    if (
      cartData.currentOrder &&
      selectedStatus &&
      selectedStatus !== cartData.currentOrder.status
    ) {
      handleChangeOrderStatus();
    }
  }, [selectedStatus]);

  return {
    isAdmin,
    isLoading,
    isSubmiting,
    isEditing,
    isButtonDisabled,
    canCancel,
    canEdit,
    order: cartData.currentOrder,
    orderCard,
    orderProducts,
    // Cart context
    selectedSuppliers,
    paymentMethods,
    cartTotal,
    cartData,
    handleSetSupplierPaymentMethod,
    handleChangeSupplierPaymentMethod,
    // form
    control,
    register,
    setValue,
    getValues,
    errors,
    handleSubmit,
    // Actions
    handleDoSubmit,
    handleOnSubmit,
    handleChangeOrderStatus,
    handleCancelOrder,
    handleGoToSupplierProducts,
    handleGoToSupplierDetails,
    handleRemoveSupplier,
    handleDoRemoveSupplier,
    handleKeepShopping,
    handleDoChangeOrderStatus,
    handleToggleEdit,
    handleGoBack,
    handleGoBackListOrders,
    // Dialogs
    handleOpenCancelDialog,
    isCancelDialogOpen,
    handleCloseCancelDialog,
    isStatusDialogOpen,
    handleCloseStatusDialog,
    supplierToRemove,
    handleCloseRemoveDialog,
    isMinOrderDialogOpen,
    handleCloseMinOrderDialog,
  };
};
