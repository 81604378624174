import { closedOrderStatus } from "pages/orders/types";

import { DraftListProps } from "../../DraftList/types";
import OrderListView from "../../OrderList";
import { useOrderListController } from "../../OrderList/controller";

const OrdersClosedView = ({ handleGoToDetails }: DraftListProps) => {
  const {
    isInfiniteDisabled,
    isLoading,
    search,
    orders,
    handleLoadData,
    handleSearchText,
    handleClearText,
  } = useOrderListController(closedOrderStatus);
  return (
    <OrderListView
      isInfiniteDisabled={isInfiniteDisabled}
      isLoading={isLoading}
      search={search}
      orders={orders}
      handleLoadData={handleLoadData}
      handleSearchText={handleSearchText}
      handleClearText={handleClearText}
      handleGoToDetails={handleGoToDetails}
    />
  );
};

export default OrdersClosedView;
