import Grid from "@mui/material/Grid";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { chubbsStyles } from "./styles";
import { ChubbsProps, ChubbsVariants } from "./types";

const Chubbs = ({ variant, customMessage, isCentered = true }: ChubbsProps) => {
  const theme = useTheme();
  const styles = chubbsStyles(isCentered);
  const { image, message } = ChubbsVariants[variant];

  return (
    <Grid style={styles.chubbsContianer}>
      <Grid>
        <Grid>
          <Typography
            variant={theme.typography.body[2].regular}
            color={theme.color.grayscale[6]}
            style={styles.typography}
          >
            {customMessage || message}
          </Typography>
        </Grid>
        <img src={image} />
      </Grid>
    </Grid>
  );
};

export default Chubbs;
