import { useIonAlert } from "@ionic/react";
import {
  CART_SAVE_ERROR,
  STORE_NOT_SELECTED_ERROR,
} from "commons/constants/cart.constants";
import { STORE_LOAD_ERROR } from "commons/constants/message.constants";
import { CartPostModel } from "commons/types/cart.model";
import { OrderModel } from "commons/types/order.model";
import {
  PaymentMethodModel,
  SupplierPaymentMethodModel,
} from "commons/types/paymentMethod.model";
import { StoreModel } from "commons/types/store.model";
import { SupplierModel } from "commons/types/supplier.model";
import {
  applyDiscountTotal,
  calculateDiscountPercent,
  getProductTotalSum,
  getSupplierTotalSum,
} from "commons/utils/cart";
import { formatDate } from "commons/utils/format";
import {
  AddedProductVariantModel,
  AddedProductVariantValues,
} from "pages/products/ProductDetailsView/types";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UseFormGetValues } from "react-hook-form";
import CartService from "services/cart.service";
import PaymentMethodService from "services/paymentMethod.service";
import { Storage } from "services/storage.service";

import {
  CartContextProps,
  CartDataModel,
  CartProviderProps,
  PaymentMethodBundleModel,
  SelectedPaymentMethod,
  SelectedProduct,
  SelectedSupplier,
} from "./types";
import { mapDetailsToCart } from "./utils";

const CartContext = createContext<CartContextProps>({} as CartContextProps);

export const CartProvider = ({ children }: CartProviderProps) => {
  const [currentOrder, setCurrentOrder] = useState<OrderModel | null>(null);
  const [cartId, setCartId] = useState<number | null>(null);
  const [shouldLoadCart, setShouldLoadCart] = useState(false);

  const [selectedStore, setSelectedStore] = useState<StoreModel | null>(null);
  // PaymentMethod used globally besides cart page
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodModel | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodModel[]>(
    []
  );
  // PaymentMethod bundle grouped by supplier (to cache de discountPercent)
  const [paymentMethodBundle, setPaymentMethodBundle] =
    useState<PaymentMethodBundleModel | null>(null);
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    SelectedSupplier[]
  >([]);
  const [cartData, setCartData] = useState<CartDataModel>({} as CartDataModel);
  const [cartTotal, setCartTotal] = useState(0);
  const [removedProductIds, setRemovedProductIds] = useState<number[]>([]);
  const [isCartInitiated, setIsCartInitiated] = useState(false);
  const [observation, setObservation] = useState("");
  const [paymentDate, setPaymentDate] = useState(new Date());

  const [presentAlert] = useIonAlert();

  // TODO: review placement
  const showError = (message: string) => {
    presentAlert({
      header: "Alerta",
      message,
      buttons: ["OK"],
    });
  };

  const loadPaymentMethods = useCallback(async () => {
    try {
      const response = await PaymentMethodService.getMany();
      const _paymentMethod: PaymentMethodModel[] =
        response?.data?.results || [];
      setPaymentMethods(_paymentMethod);
    } catch (error) {
      presentAlert({
        header: "Alerta",
        message: STORE_LOAD_ERROR,
        buttons: ["OK"],
      });
    }
  }, []);

  const loadSupplierPaymentMethods = useCallback(async (supplierId: number) => {
    try {
      const params = {
        supplierId,
      };
      const response = await PaymentMethodService.getBySupplier(params);
      const _paymentMethods: SupplierPaymentMethodModel[] =
        response?.data?.results || [];
      const mappedPMs: any = {};
      if (_paymentMethods.length) {
        _paymentMethods.forEach((pm) => {
          const discountPercent = calculateDiscountPercent(pm.discount_percent);

          mappedPMs[pm.payment_method.id] = {
            id: pm.payment_method.id,
            discountPercent,
            supplierPaymentMethodId: pm.id,
            name: pm.payment_method.name,
          };
        });

        setPaymentMethodBundle((oldValues) => {
          return {
            ...oldValues,
            [supplierId]: mappedPMs,
          };
        });

        return _paymentMethods;
      }
      return null;
    } catch (error) {
      throw error;
    }
  }, []);

  const loadSinglePaymentMethod = async (
    supplierId: number,
    paymentMethodId: number
  ) => {
    const response = await PaymentMethodService.getBySupplier({
      paymentMethodId,
      supplierId,
    });
    const results: SupplierPaymentMethodModel[] = response.data?.results || [];
    const paymentConfig = results && results[0];

    if (paymentConfig) {
      const supplierPaymentMethodId = paymentConfig.id;
      const paymentMethodName = paymentConfig.payment_method.name;
      const discountPercent = calculateDiscountPercent(
        paymentConfig.discount_percent
      );

      // Cache locally the payment data
      setPaymentMethodBundle((oldValues) => {
        return {
          ...oldValues,
          [supplierId]: {
            [paymentMethodId]: {
              discountPercent,
              supplierPaymentMethodId,
              name: paymentMethodName,
            },
          },
        };
      });

      return {
        id: paymentMethodId,
        supplierPaymentMethodId,
        discountPercent,
        name: paymentMethodName,
      };
    }
    return null;
  };

  /**
   * Return the supplier's paymentMethod with the discount Percentage
   * - check if there is a local value, if not fetch the data from the backend
   */
  const getSupplierPaymentMethod = useCallback(
    async (
      supplierId: number,
      paymentMethodId: number,
      paymentMethodBundle: PaymentMethodBundleModel | null
    ): Promise<SelectedPaymentMethod | null> => {
      try {
        if (paymentMethodBundle && paymentMethodBundle[supplierId]) {
          if (paymentMethodBundle[supplierId][paymentMethodId]) {
            return {
              ...paymentMethodBundle[supplierId][paymentMethodId],
            };
          }
          // If the supplier does not have the given PM, get the first one
          const index = Object.keys(paymentMethodBundle[supplierId])[0];
          if (index) {
            return {
              ...paymentMethodBundle[supplierId][index],
            };
          }
        }
        return await loadSinglePaymentMethod(supplierId, paymentMethodId);
      } catch (error) {
        throw error;
      }
    },
    []
  );

  const handleDoChangeSupplierPaymentMethod = async (
    supplierId: number,
    paymentMethodId: number,
    selectedSuppliers: SelectedSupplier[],
    paymentMethodBundle: PaymentMethodBundleModel | null
  ) => {
    let _selectedSuppliers = selectedSuppliers;
    const _paymentMethod = await getSupplierPaymentMethod(
      supplierId,
      paymentMethodId,
      paymentMethodBundle
    );

    if (_paymentMethod) {
      const _supplierIndex = _selectedSuppliers.findIndex(
        (sup) => sup.id === supplierId
      );
      const _selectedSupplier = _selectedSuppliers[_supplierIndex];
      const newSupplier: SelectedSupplier = {
        ..._selectedSupplier,
        selectedPaymentMethod: _paymentMethod,
      };
      _selectedSuppliers[_supplierIndex] =
        {
          ...newSupplier,
          supplierTotal: supplierTotalSum(
            _selectedSupplier.selectedProducts,
            newSupplier
          ),
        } || [];
      _selectedSuppliers = [..._selectedSuppliers];
    }
    return _selectedSuppliers;
  };

  const handleChangeSupplierPaymentMethod = useCallback(
    async (
      selectedSuppliers: SelectedSupplier[],
      supplierId: number,
      paymentMethodId: number,
      paymentMethodBundle: PaymentMethodBundleModel | null
    ) => {
      const _selectedSuppliers = await handleDoChangeSupplierPaymentMethod(
        supplierId,
        paymentMethodId,
        selectedSuppliers,
        paymentMethodBundle
      );
      setSelectedSuppliers(_selectedSuppliers);
      handleSetCartTotalSum(_selectedSuppliers);
    },
    []
  );

  // Init the payment method of all selected suppliers
  const handleChangeSuppliersPaymentMethod = useCallback(
    async (
      selectedSuppliers: SelectedSupplier[],
      paymentMethodId: number,
      paymentMethodBundle: PaymentMethodBundleModel | null
    ) => {
      let _selectedSuppliers = selectedSuppliers;
      for (const sup of selectedSuppliers) {
        _selectedSuppliers = await handleDoChangeSupplierPaymentMethod(
          sup.id,
          paymentMethodId,
          _selectedSuppliers,
          paymentMethodBundle
        );
      }
      setSelectedSuppliers(_selectedSuppliers);
      handleSetCartTotalSum(_selectedSuppliers);
    },
    []
  );

  const handleSetCartTotalSum = (suppliers: SelectedSupplier[]) => {
    setCartTotal(
      suppliers.reduce((acc, curr) => acc + Number(curr.supplierTotal), 0)
    );
  };

  const applyCartDiscount = (price: number, supplier: SelectedSupplier) => {
    let discountPercent = supplier?.selectedPaymentMethod?.discountPercent;

    if (discountPercent && discountPercent > 1) {
      discountPercent = discountPercent / 100;

      return price - price * discountPercent;
    }

    if (!discountPercent) {
      const _id =
        cartData.selectedPaymentMethod?.id || selectedPaymentMethod?.id;
      discountPercent =
        _id &&
        paymentMethodBundle &&
        paymentMethodBundle[supplier.id] &&
        paymentMethodBundle[supplier.id][_id]
          ? paymentMethodBundle[supplier.id][_id].discountPercent
          : 1;
    }
    return applyDiscountTotal(price, discountPercent);
  };

  const supplierTotalSum = (
    products: SelectedProduct[],
    supplier: SelectedSupplier
  ) => {
    const total = getSupplierTotalSum(products);
    return applyCartDiscount(total, supplier);
  };

  // Clean all the data related to the cart
  const clearCart = (shouldClearCart?: boolean) => {
    Storage.remove(["cartSuppliers"]);
    setSelectedSuppliers([]);
    setCartTotal(0);
    setCurrentOrder(null);
    if (shouldClearCart) {
      setCartId(null);
      const cart = {
        ...cartData,
        cartId: null,
        selectedSuppliers: [],
        cartTotal: 0,
        selectedStore: null,
        selectedPaymentMethod: null,
      };
      handleUpdateCartData(cart);
    }
  };
  // Clean all the data related to the current order, so the cart can be used
  const clearCurrentOrder = () => {
    Storage.remove(["cartSuppliers"]);
    const cart = {
      ...cartData,
      currentOrder: null,
    };
    handleUpdateCartData(cart);
  };

  const clearPaymentMethods = () => {
    setPaymentMethodBundle(null);
  };

  // Clean all the data from the storage
  const clearAllData = () => {
    const emptyData = {} as CartDataModel;
    Storage.remove(["cartData", "cartSuppliers"]);
    setCartData(emptyData);
    setSelectedSuppliers([]);
    setPaymentMethodBundle(null);
    setSelectedPaymentMethod(null);
  };

  // Update stored cartData
  const handleUpdateCartData = (cart: CartDataModel) => {
    Storage.set("cartData", cart);
    setCartData(cart);
  };

  const handleSelectStore = (
    store: StoreModel | null,
    shouldClearCart = true
  ) => {
    clearCart(shouldClearCart);
    setSelectedStore(store);
  };

  const handleRemoveSupplier = (id: number, isOrder?: boolean) => {
    const suppliers = selectedSuppliers;
    const index = selectedSuppliers.findIndex((item) => item.id === id);

    if (isOrder) {
      setRemovedProductIds((oldValue) => {
        const idsToRemove: any[] =
          selectedSuppliers[index].selectedProducts
            .map((prod) => {
              if (!prod.addedProductVariants) return null;
              const variants: AddedProductVariantValues[] = Object.values(
                prod.addedProductVariants
              );
              return variants.map((f) => f.order_product_id) || null;
            })
            .filter((i) => !!i)
            .flat() || [];
        oldValue.concat(idsToRemove);
        return oldValue.concat(idsToRemove);
      });
    }
    suppliers.splice(index, 1);
    updateSuppliers(suppliers);
  };

  const handleRemoveProduct = (id: number, sku: number, isOrder?: boolean) => {
    const suppliers = selectedSuppliers;
    for (const supplier of suppliers) {
      const index = supplier.selectedProducts.findIndex(
        (item) => item.id === id
      );
      if (index >= 0) {
        const product = supplier.selectedProducts[index];
        const { addedProductVariants, values } = product;
        if (addedProductVariants) {
          if (isOrder) {
            const variant = addedProductVariants[sku];
            if (variant.order_product_id) {
              removedProductIds.push(variant.order_product_id);
            }
          }
          delete addedProductVariants[sku];
          if (values && values[`sku-${sku}`]) {
            delete values[`sku-${sku}`];
          }
          if (!Object.keys(addedProductVariants).length) {
            // Remove product if all variants are removed
            supplier.selectedProducts.splice(index, 1);
            if (!supplier.selectedProducts.length) {
              // Remove supplier if all products are removed
              return handleRemoveSupplier(supplier.id, isOrder);
            }
          }

          product.productTotal = getProductTotalSum(product);
          supplier.supplierTotal = supplierTotalSum(
            supplier.selectedProducts,
            supplier
          );
        }
        return updateSuppliers(suppliers);
      }
    }
  };

  const updateSuppliers = (suppliers: SelectedSupplier[]) => {
    Storage.set("cartSuppliers", suppliers);
    handleSetCartTotalSum(suppliers);
    setSelectedSuppliers(suppliers);
  };

  const updateSupplierProducts = (
    supplierIndex: number,
    supplier: SelectedSupplier,
    product: SelectedProduct
  ) => {
    if (!selectedSuppliers[supplierIndex]) return;
    const { selectedProducts } = supplier;
    const productIndex = selectedProducts.findIndex(
      (item) => item.id === product.id
    );

    const suppliers = selectedSuppliers;
    if (productIndex < 0) {
      // Product not found, gets stored.
      suppliers[supplierIndex].selectedProducts = [
        ...selectedProducts,
        product,
      ];
    } else {
      // Product already stored, gets updated.
      suppliers[supplierIndex].selectedProducts[productIndex] = product;
    }

    suppliers[supplierIndex].supplierTotal = supplierTotalSum(
      selectedProducts,
      supplier
    );

    return updateSuppliers(suppliers);
  };

  const handleUpdateSelectedSuppliers = (
    supplier: SupplierModel,
    product: SelectedProduct
  ) => {
    const supplierIndex = selectedSuppliers.findIndex(
      (item) => item.id === supplier.id
    );
    if (supplierIndex < 0 && supplier.id) {
      // Supplier not found, gets stored along with the product.
      const suppliers = [
        ...selectedSuppliers,
        {
          ...supplier,
          selectedProducts: [product],
          supplierTotal: product.productTotal,
        },
      ];
      return updateSuppliers(suppliers);
    }
    updateSupplierProducts(
      supplierIndex,
      selectedSuppliers[supplierIndex],
      product
    );
  };

  // Update the product variant
  const handleUpdateSelectedSupplierVariant = (
    supplier: SupplierModel,
    product: SelectedProduct,
    variant: AddedProductVariantModel,
    sku: number
  ) => {
    const _suppliers = selectedSuppliers.map((sup) => {
      const _products = sup.selectedProducts.map((prod) => {
        const _vartiant: AddedProductVariantValues = Object.values(variant)[0];
        if (product.id !== prod.id) {
          return prod;
        }

        const addedProductVariants: AddedProductVariantModel = {
          ...prod.addedProductVariants,
          [sku]: {
            ..._vartiant,
          },
        };
        // Remove variant
        if (!_vartiant?.sku) {
          delete addedProductVariants[sku];
        }
        const _prod = {
          ...prod,
          addedProductVariants,
        };
        const productTotal = getProductTotalSum(_prod);
        return { ..._prod, productTotal };
      });

      const supplierTotal =
        supplier.id === sup.id
          ? supplierTotalSum(_products, sup)
          : sup.supplierTotal;

      return {
        ...sup,
        supplierTotal,
        selectedProducts: _products,
      };
    });

    return updateSuppliers(_suppliers);
  };

  const handleSaveCart = async (
    userId: number,
    getValues?: UseFormGetValues<any>
  ) => {
    try {
      const details = mapDetailsToCart(selectedSuppliers);

      if (!cartData.selectedStore) {
        return showError(STORE_NOT_SELECTED_ERROR);
      }
      const payload: CartPostModel = {
        user: userId,
        store: cartData.selectedStore.id,
        details,
        payment_date: getValues
          ? formatDate(new Date(getValues("paymentDate")))
          : null,
        observation: getValues ? getValues("observation") : null,
        payment_method_general: cartData.selectedPaymentMethod?.id || null,
      };

      let response;
      if (cartId) {
        response = await CartService.update(cartId, payload);
      } else {
        response = await CartService.create(payload);
        if (response.data.id) {
          setCartId(response.data.id);
        }
      }
      return response;
    } catch (error) {
      throw error || new Error(CART_SAVE_ERROR);
    }
  };

  const handleUpdateObservation = (observation: string) => {
    setObservation(observation);
  };

  const handleUpdatePaymentDate = (paymentDate: Date) => {
    setPaymentDate(paymentDate);
  };

  /**
   * Load dank payment methods
   */
  useEffect(() => {
    loadPaymentMethods();
  }, [loadPaymentMethods]);

  /**
   * Retrieve the CartData from the local store and setup
   */
  useEffect(() => {
    if (!isCartInitiated) {
      const storedSuppliers = Storage.get("cartSuppliers");
      if (storedSuppliers) {
        setSelectedSuppliers(storedSuppliers);
        handleSetCartTotalSum(storedSuppliers);
      }
      const storedCart = Storage.get("cartData");
      if (storedCart) setCartData(storedCart);
      setIsCartInitiated(true);
    }
  }, [isCartInitiated]);

  /**
   * Store selectedStore
   */
  useEffect(() => {
    if (!selectedStore && !selectedPaymentMethod) return;
    const cart = {
      ...cartData,
      selectedStore,
      selectedPaymentMethod,
    };
    handleUpdateCartData(cart);
  }, [selectedStore, selectedPaymentMethod]);

  // Store CartID, if the user is loading a cart there shall be no order
  useEffect(() => {
    if (!cartId) return;
    const cart = {
      ...cartData,
      cartId,
      currentOrder: null,
    };
    handleUpdateCartData(cart);
  }, [cartId]);

  // Store current order, if the user is editing an order there shall be no cart
  useEffect(() => {
    if (!currentOrder) return;
    const cart = {
      ...cartData,
      currentOrder,
      cartId: null,
    };
    handleUpdateCartData(cart);
  }, [currentOrder]);

  // Store paymentMethodBundle
  useEffect(() => {
    if (!paymentMethodBundle) return;
    const cart = {
      ...cartData,
      paymentMethodBundle,
    };
    handleUpdateCartData(cart);
  }, [paymentMethodBundle]);

  return (
    <CartContext.Provider
      value={{
        // Cart props
        shouldLoadCart,
        cartData,
        cartTotal,
        cartId,
        isCartInitiated,
        paymentMethodBundle,
        selectedStore,
        paymentMethods,
        selectedPaymentMethod,
        selectedSuppliers,
        removedProductIds,
        currentOrder,
        // Setters
        setCurrentOrder,
        setShouldLoadCart,
        setCartId,
        handleSelectStore,
        // Actions
        showError,
        updateSuppliers,
        setSelectedPaymentMethod,
        loadSupplierPaymentMethods,
        handleChangeSupplierPaymentMethod,
        handleUpdateSelectedSupplierVariant,
        getSupplierPaymentMethod,
        handleUpdateSelectedSuppliers,
        handleChangeSuppliersPaymentMethod,
        handleRemoveSupplier,
        handleRemoveProduct,
        applyCartDiscount,
        handleSaveCart,
        observation,
        handleUpdateObservation,
        paymentDate,
        handleUpdatePaymentDate,
        // Cleaning functions
        clearCart,
        clearCurrentOrder,
        clearAllData,
        clearPaymentMethods,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart(): CartContextProps {
  const context = useContext(CartContext);
  return context;
}
