import { ButtonProps } from "@mui/material";
import { useTheme } from "styled-components";

import { buttonPrimaryStyle, ButtonErrorStyled } from "./styles";

export default ({ children, ...props }: ButtonProps) => {
  const theme = useTheme();

  return (
    <ButtonErrorStyled
      variant="contained"
      style={buttonPrimaryStyle(theme)}
      {...props}
    >
      {children}
    </ButtonErrorStyled>
  );
};
