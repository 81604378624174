import { IonPage } from "@ionic/react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import QuestionMarkCircle from "assets/svgs/icons/question_mark_circle.svg";
import DialogWithButtons from "components/DialogWithButtons";
import SimpleHeader from "components/SimpleHeader";
import Typography from "components/Typography";
import { StyledIonContent } from "pages/stores/styles";

import AccountHeader from "./components/AccountHeader";
import AccountInfo from "./components/AccountInfo";
import { useAccountController } from "./controller";
import { accountStyles } from "./styles";

const AccountView: React.FC = () => {
  const {
    theme,
    user,
    handleLogOut,
    isDialogOpen,
    handleOpenDialog,
    handleCloseDialog,
    handleGoToPage,
  } = useAccountController();

  const { logoutButton } = accountStyles();

  return (
    <IonPage>
      <DialogWithButtons
        altIcon={QuestionMarkCircle}
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        primaryButtonText="Não, voltar"
        secondaryButtonText="Sim, sair"
        onClickPrimaryButton={handleCloseDialog}
        onClickSecondaryButton={handleLogOut}
        hasAutoSizedBtns
        isDangerAction
        message={
          <Grid container>
            <Grid item mb={2} width={230}>
              <Typography
                variant={theme.typography.heading[2].highlight}
                color={theme.color.information.default}
              >
                <b>Você tem certeza que quer sair da conta?</b>
              </Typography>
            </Grid>
            <Grid item width={230}>
              <Typography
                variant={theme.typography.body[2].regular}
                color={theme.color.grayscale[8]}
              >
                Você precisará fazer o login novamente quando quiser acessar a
                sua conta.
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <SimpleHeader />
      {user ? (
        <StyledIonContent>
          <AccountHeader user={user} />
          <AccountInfo user={user} handleGoToPage={handleGoToPage} />
          <Grid my={4} ml={3}>
            <Button
              onClick={handleOpenDialog}
              color="error"
              style={logoutButton}
            >
              <b>Sair da conta</b>
            </Button>
          </Grid>
        </StyledIonContent>
      ) : null}
    </IonPage>
  );
};

export default AccountView;
