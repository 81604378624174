import { api } from "./api.service";

const MODULE_PATH = "supplier";

export default class SupplierService {
  static async getMany({
    page = 1,
    search = "",
    microregion,
    ids,
  }: SupplierGetMany) {
    const options = {
      params: {
        page,
        search,
        microregion,
        ids,
      },
    };
    return api.get(`${MODULE_PATH}/`, options);
  }

  static async getOne(id: number | string) {
    return api.get(`${MODULE_PATH}/${id}/`);
  }
}

interface SupplierGetMany {
  page?: number;
  search?: string;
  microregion?: string;
  ids?: number[];
}
