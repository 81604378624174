import {
  PRODUCT_CATEGORIES_LOAD_ERROR,
  PRODUCT_TAG_LOAD_ERROR,
} from "commons/constants/message.constants";
import {
  ProductCategoryModel,
  ProductTagModel,
} from "commons/types/product.model";
import { useCart } from "contexts/cart";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router";
import ProductService from "services/product.service";

import {
  defaultFilter,
  DefaultProductFilters,
  ProductFilter,
  ProductFilterContextProps,
  ProductFilterHashes,
  ProductFilterProviderProps,
} from "./types";

const ProductFilterContext = createContext<ProductFilterContextProps>(
  {} as ProductFilterContextProps
);

export const ProductFilterProvider = ({
  children,
}: ProductFilterProviderProps) => {
  const { hash } = useLocation();
  const { showError } = useCart();
  const [selectedFilter, setSelectedFilter] = useState<ProductFilter>(
    Object.keys(ProductFilterHashes).includes(hash)
      ? ProductFilterHashes[hash as ProductFilter]
      : ProductFilter.ALL
  );

  const [filters, setFilters] = useState<DefaultProductFilters>(defaultFilter);
  const [categories, setCategories] = useState<ProductCategoryModel[]>([]);
  const [tags, setTags] = useState<ProductTagModel[]>([]);

  const loadProductCategories = useCallback(async () => {
    try {
      const response = await ProductService.getCategories();
      setCategories(response.data || []);
    } catch (error) {
      showError(PRODUCT_CATEGORIES_LOAD_ERROR);
    }
  }, [setCategories]);

  const loadProductTags = useCallback(async () => {
    try {
      const response = await ProductService.getTags();

      setTags(response.data || []);
    } catch (error) {
      showError(PRODUCT_TAG_LOAD_ERROR);
    }
  }, [setTags]);

  const handleSearchText = useCallback((text: string) => {
    setFilters(() => {
      return {
        ...defaultFilter,
        searchText: text,
      };
    });
  }, []);

  const handleClearText = useCallback(() => {
    setFilters(() => defaultFilter);
  }, []);

  const getCleanArray = (categoryIds: number[]) => {
    return categoryIds?.filter((id) => !!id);
  };

  const handleSearchCategories = (categoryIds: number[]) => {
    const categories = getCleanArray(categoryIds);
    setFilters(() => {
      return {
        ...defaultFilter,
        selectedCategories: categories.length ? categories : [0],
      };
    });
  };

  const handleSearchTags = (ids: number[]) => {
    const tagIds = getCleanArray(ids);
    setFilters(() => {
      return {
        ...defaultFilter,
        selectedTags: tagIds.length ? tagIds : [0],
      };
    });
  };

  const setPage = (page: number) => {
    setFilters((oldFilters) => ({ ...oldFilters, page }));
  };
  const setInfiniteDisabled = (isInfiniteDisabled: boolean) => {
    setFilters((oldFilters) => ({ ...oldFilters, isInfiniteDisabled }));
  };

  useEffect(() => {
    loadProductCategories();
    loadProductTags();
  }, []);

  useEffect(() => {
    window.location.hash = ProductFilterHashes["#" + selectedFilter];
  }, [selectedFilter]);

  return (
    <ProductFilterContext.Provider
      value={{
        tags,
        categories,
        selectedFilter,
        setSelectedFilter,
        setPage,
        setInfiniteDisabled,
        // Filters
        filters,
        // ALL
        handleSearchText,
        handleClearText,
        // Categories
        handleSearchCategories,
        // Tags
        handleSearchTags,
        getCleanArray,
      }}
    >
      {children}
    </ProductFilterContext.Provider>
  );
};

export function useProductFilter(): ProductFilterContextProps {
  const context = useContext(ProductFilterContext);
  return context;
}
