import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import trashBinIcon from "assets/svgs/trash_bin.svg";
import SelectObjectInputView from "components/inputs/SelectObjectInput";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import SupplierDialogsView from "../dialogs/SupplierDialogs";
import SupplierInfoView from "../SupplierInfo";
import useSupplierCardController from "./controller";
import { SupplierCardContainer, supplierCardStyles } from "./styles";
import { SupplierCardProps } from "./types";

const SupplierCardView = ({
  handleSetSupplierPaymentMethod,
  supplier,
  control,
  addProducts,
  viewDetails,
  // Dialogs
  removeSupplier,
  supplierToRemove,
  handleCloseRemoveDialog,
  handleDoRemove,
  isMinOrderDialogOpen,
  handleCloseMinOrderDialog,
  isEditingOrder,
}: SupplierCardProps) => {
  const theme = useTheme();
  const styles = supplierCardStyles();
  const { paymentMethods } = useSupplierCardController({
    supplier,
    handleSetSupplierPaymentMethod,
  });

  return (
    <SupplierCardContainer>
      <Grid container style={styles.mainGrid} direction="column">
        <SupplierInfoView supplier={supplier} total={supplier.supplierTotal} />

        <Grid
          container
          item
          xs
          justifyContent="flex-start"
          spacing={2}
          style={styles.actionButtonsContainer}
        >
          {!isEditingOrder ? null : (
            <Grid item>
              <Button
                style={styles.primaryButton}
                variant="contained"
                onClick={() => addProducts(supplier.id)}
              >
                <Typography variant={theme.typography.body[4].highlight}>
                  Adicionar produtos
                </Typography>
              </Button>
            </Grid>
          )}
          <Grid item xs>
            <Button
              style={styles.secondaryButton}
              variant="outlined"
              onClick={() => viewDetails(supplier.id)}
            >
              <Typography variant={theme.typography.body[4].highlight}>
                Ver pedido
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs spacing={1} style={styles.selectGrid}>
          <SelectObjectInputView
            title="Condição de pagamento"
            placeholder="Selecione..."
            name={`${supplier.id}`}
            control={control}
            items={paymentMethods}
            disabled={!isEditingOrder}
          />
        </Grid>

        {isEditingOrder ? (
          <Grid item textAlign="start" style={styles.buttonContainer}>
            <Grid container alignItems="center">
              <Grid item sx={styles.icon}>
                <img src={trashBinIcon} />
              </Grid>
              <Grid item onClick={() => removeSupplier(supplier.id)}>
                <Button color="error" style={styles.removeButton}>
                  <Typography variant={theme.typography.body[4].highlight}>
                    Excluir pedido
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <SupplierDialogsView
        supplier={supplier}
        isRemoveDialogOpen={supplier.id === supplierToRemove}
        handleCloseRemoveDialog={handleCloseRemoveDialog}
        handleDoRemove={handleDoRemove}
        isMinOrderDialogOpen={isMinOrderDialogOpen}
        handleCloseMinOrderDialog={handleCloseMinOrderDialog}
      />
    </SupplierCardContainer>
  );
};

export default SupplierCardView;
