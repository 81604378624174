import { IonIcon } from "@ionic/react";
import { cartOutline } from "ionicons/icons";
import { useTheme } from "styled-components";

import { useCartButtonController } from "./controller";
import { buttonCardStyles, buttonCartStyle, StyledButton } from "./styles";

const ButtonCart = () => {
  const theme = useTheme();
  const { handleClick, buttonTitle } = useCartButtonController();
  const { iconStyle } = buttonCardStyles(theme);
  return (
    <StyledButton
      variant="outlined"
      style={buttonCartStyle(theme)}
      onClick={handleClick}
    >
      <IonIcon icon={cartOutline} color="primary" style={iconStyle} />
      {buttonTitle}
    </StyledButton>
  );
};

export default ButtonCart;
