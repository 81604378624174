import { Grid } from "@mui/material";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

import { ImageSliderContainer } from "./styles";
import { ImageSliderProps } from "./types";

const ImageSlider = ({ imageList }: ImageSliderProps) => {
  const sliderRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(sliderRef, {
    applyRubberBandEffect: true,
  });

  return (
    <ImageSliderContainer ref={sliderRef} {...events}>
      <Grid ml={2} />
      {imageList.map((image) => (
        <img key={image.image} src={image.image} />
      ))}
      <Grid mr={2} />
    </ImageSliderContainer>
  );
};

export default ImageSlider;
