import styled from "@emotion/styled";
import { Grid } from "@mui/material";
// TODO shared style, review
export const ProductFiltersContainer = styled(Grid)`
  margin-top: 10px;
  text-align: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  @media (min-width: 734px) {
    flex-direction: row;
    justify-content: center;
  }
`;
