import { yupResolver } from "@hookform/resolvers/yup";
import { useIonAlert, useIonLoading } from "@ionic/react";
import axios from "axios";
import {
  STORE_CREATE_ERROR,
  STORE_IDENTIFICATION_IN_USE,
  STORE_TYPE_LOAD_ERROR,
} from "commons/constants/message.constants";
import { StoreModel, StoreTypeModel } from "commons/types/store.model";
import {
  getAvailableCities,
  getMicroregionByCity,
} from "commons/utils/general";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { StatusCodes } from "http-status-codes";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import StoreService from "services/store.service";
import { removeCharacters } from "utils/functions";

import { GenericFormData, StoreFormModel } from "./types";
import {
  initialValues,
  initialValuesAddress,
  initialValuesContact,
  basicFormValidationSchema,
  addressFormValidationSchema,
  contactFormValidationSchema,
  STORE_PAGES,
  BRAZIL_STATES,
} from "./util";

export const useStoreCreationController = () => {
  const history = useHistory();
  const auth = useAuth();
  const { handleSelectStore } = useCart();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [createdStore, setCreatedStore] = useState<StoreModel>();
  const [cities, setCities] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState<Partial<StoreFormModel>>({});
  const [isLoading, setLoading] = useState(true);
  const [isLoadingZipcode, setIsLoadingZipcode] = useState(false);

  const [storeTypes, setStoreTypes] = useState<StoreTypeModel[]>([]);

  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const pageTotal = 3;

  const basicForm = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(basicFormValidationSchema),
  });

  const addressForm = useForm({
    defaultValues: initialValuesAddress,
    resolver: yupResolver(addressFormValidationSchema),
  });
  const contactForm = useForm({
    defaultValues: initialValuesContact,
    resolver: yupResolver(contactFormValidationSchema),
  });

  const { reset } = addressForm;

  const formTitle = () => {
    switch (page) {
      case STORE_PAGES.BASIC:
        return "Informações básicas";
      case STORE_PAGES.ADDRESS:
        return "Localização";
      case STORE_PAGES.CONTACT:
        return "Contato";
    }
    return "";
  };

  const trimData = (data: GenericFormData) => {
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === "string") {
        data[key] = (data[key] as string).trim();
      }
    });
    return data;
  };

  const loadStoreTypes = useCallback(async () => {
    try {
      const { data } = await StoreService.getStoreTypes();
      setStoreTypes(data?.results || []);
    } catch (error) {
      presentAlert({
        header: "Alerta",
        message: STORE_TYPE_LOAD_ERROR,
        buttons: ["OK"],
      });
    } finally {
      setLoading(false);
      dismiss();
    }
  }, []);

  const handleValidadeFormBasic = async () => {
    const { getValues, clearErrors, trigger } = basicForm;
    const shouldPass = await trigger();

    const registrationNumber = getValues("registration_number");

    try {
      setLoading(true);
      const response = await StoreService.verifyRegistrationStore(
        registrationNumber
      );

      if (response.status !== StatusCodes.OK) {
        throw new Error();
      }
    } catch (error) {
      basicForm.setError("registration_number", {
        message: STORE_IDENTIFICATION_IN_USE,
      });
      return false;
    } finally {
      setLoading(false);
    }

    if (shouldPass) {
      const data = getValues();
      trimData(data);
      setFormData((oldData) => {
        return { ...oldData, ...data };
      });
      clearErrors();
      return true;
    }
    return false;
  };

  const handleValidadeFormAddress = async () => {
    const { getValues, clearErrors, trigger } = addressForm;

    const shouldPass = await trigger();

    if (shouldPass) {
      const data = getValues();
      trimData(data);
      setFormData((oldData) => {
        return { ...oldData, ...data };
      });
      clearErrors();
      return true;
    }
    trigger();
    return false;
  };

  const handleValidadeFormContact = async () => {
    const { clearErrors, trigger } = contactForm;
    const shouldPass = await trigger();

    if (shouldPass) {
      clearErrors();
      return true;
    }
    trigger();
    return false;
  };

  const handleClickBack = (e: SyntheticEvent) => {
    e.preventDefault();
    if (page > 0) {
      setPage((oldPage) => oldPage - 1);
    } else {
      history.goBack();
    }
  };

  const handleClickNext = async () => {
    let isFormValid = false;
    switch (page) {
      case STORE_PAGES.BASIC:
        isFormValid = await handleValidadeFormBasic();
        break;
      case STORE_PAGES.ADDRESS:
        isFormValid = await handleValidadeFormAddress();
        break;
    }
    if (isFormValid) {
      setPage((oldPage) => oldPage + 1);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);
      if ((await handleValidadeFormContact()) && formData) {
        const contactData = contactForm.getValues();
        trimData(contactData);
        const userMicroregions = auth.user?.microregions || [];
        const _microregion = getMicroregionByCity(
          userMicroregions,
          formData.city
        );

        const payload = {
          ...formData,
          ...contactData,
          microregion: _microregion?.id,
          observation: "Criado pelo vendedor",
        };

        const response = await StoreService.create(payload);

        if (response.status !== StatusCodes.OK) {
          presentAlert({
            header: "Alerta",
            message: STORE_CREATE_ERROR,
            buttons: ["OK"],
          });
        } else {
          setCreatedStore(response?.data);
          setIsDialogOpen(true);
        }
      }
    } catch (error) {
      presentAlert({
        header: "Alerta",
        message: STORE_CREATE_ERROR,
        buttons: ["OK"],
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleGoToDetails = () => {
    if (!createdStore) return;
    history.replace(`/stores/${createdStore.id}`);
  };
  const handleGoToProducts = () => {
    if (createdStore) {
      handleSelectStore(createdStore);
      history.replace("/stores/products#todos");
    }
  };

  const lookupAddress = async (zipcode: string) => {
    if (zipcode.length === 8) {
      try {
        setIsLoadingZipcode(true);
        const response = await axios.get(
          `https://viacep.com.br/ws/${zipcode}/json/`
        );
        const state = BRAZIL_STATES.find((t) => t.id === response.data.uf);
        const city = cities.find((c) => {
          return c.toLowerCase() === response.data.localidade.toLowerCase();
        });
        reset({
          zipcode,
          city: city ? city : "",
          street: response.data.logradouro,
          state: state ? state.name : "",
          neighborhood: response.data.bairro,
        });
      } catch (error) {
        reset({
          zipcode,
          city: "",
          street: "",
          state: "",
          neighborhood: "",
        });
      } finally {
        setIsLoadingZipcode(false);
      }
      return zipcode;
    }
  };

  const handleZipcodeChange = (event: any) => {
    const unmasked = removeCharacters(event.target.value);
    lookupAddress(unmasked);
    return unmasked;
  };

  useEffect(() => {
    present({
      message: "Carregando...",
      duration: 30000,
    });
    const userMicroregions = auth.user?.microregions || [];
    const _cities = getAvailableCities(userMicroregions);
    if (_cities) {
      setCities(_cities);
    }
  }, []);

  useEffect(() => {
    loadStoreTypes();
  }, [loadStoreTypes]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  return {
    isDialogOpen,
    isLoading,
    isLoadingZipcode,
    cities,
    storeTypes,
    page,
    setPage,
    pageTotal,
    formTitle,
    basicForm,
    addressForm,
    contactForm,
    handleOnSubmit,
    handleClickNext,
    handleClickBack,
    handleCloseDialog,
    handleGoToDetails,
    handleGoToProducts,
    handleZipcodeChange,
  };
};
