import { useIonAlert } from "@ionic/react";
import {
  CARTS_LOAD_ERROR,
  REMOVE_CART_ERROR,
} from "commons/constants/message.constants";
import { CartModel } from "commons/types/cart.model";
import { useCart } from "contexts/cart";
import { useConfiguration } from "contexts/configuration";
import { StatusCodes } from "http-status-codes";
import { CardItem } from "pages/orders/types";
import { useCallback, useEffect, useState } from "react";
import CartService from "services/cart.service";

export const useDraftController = () => {
  const { presentError } = useConfiguration();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDraftId, setSelectedDraftId] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();

  const [drafts, setDrafts] = useState<CardItem[]>([]);

  const { cartData } = useCart();
  const [presentAlert] = useIonAlert();

  const loadDrafts = useCallback(async () => {
    try {
      const searchParams = {
        page,
        storeName: search,
      };
      setIsLoading(true);
      const response = await CartService.getMany(searchParams);

      if (response.status === StatusCodes.OK) {
        const _drafts: CardItem[] = response.data.results.map(
          (draft: CartModel) => ({
            id: draft.id,
            store: draft.store.fantasy_name,
            suppliers: draft.details.supplier_names,
            logo: draft.store.logo,
            isDraft: true,
          })
        );

        let canSearch = true;

        if (page === 1) {
          setDrafts(_drafts);
          canSearch = _drafts.length < (response?.data?.count || 0);
        } else if (_drafts.length && page !== 1) {
          setDrafts((prev) => prev.concat(_drafts));
          canSearch =
            drafts.concat(_drafts).length < (response?.data?.count || 0);
        }

        if (!canSearch) {
          setIsInfiniteDisabled(true);
        }
        setIsLoading(false);

        if (canSearch && scrollEvent) {
          (scrollEvent.target as HTMLIonInfiniteScrollElement).complete();
        }
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, CARTS_LOAD_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [search, page, isInfiniteDisabled]);

  const handleOpenDeleteModal = async (id: number) => {
    if (cartData?.cartId === id) {
      presentAlert({
        header: "Alerta",
        message: REMOVE_CART_ERROR,
        buttons: ["OK"],
      });
    } else {
      setSelectedDraftId(id);
      setIsModalOpen(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteDraft = async () => {
    const response = await CartService.delete(selectedDraftId);

    if (response.status === 204) {
      setIsModalOpen(false);
      loadDrafts();
    }
  };

  const handleSearchText = useCallback((text: string) => {
    setSearch(text);
  }, []);

  const handleClearText = useCallback(() => {
    setSearch("");
  }, []);

  const handleLoadData = useCallback(
    (event: CustomEvent<void>) => {
      setScrollEvent(event);
      setPage(page + 1);
    },
    [page]
  );

  useEffect(() => {
    loadDrafts();
  }, [loadDrafts, page, search]);

  return {
    drafts,
    search,
    isLoading,
    isInfiniteDisabled,
    isModalOpen,
    handleLoadData,
    handleSearchText,
    handleClearText,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleDeleteDraft,
  };
};
