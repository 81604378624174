import { MaterialStyles } from "commons/types/generics/materialStyles";

export const supplierDetailsStyles = (): MaterialStyles => ({
  container: {
    backgroundColor: "white",
    padding: 1.5,
    margin: "24px 0",
  },
  buttonsContainer: {
    margin: "12px 4px 30px 4px",
  },
  listText: {
    margin: "0 4px 16px 4px",
  },
  variantItemContainer: {
    margin: "0 4px 20px 4px",
  },
  itemContent: {
    marginTop: 3,
  },
  actionsContainer: {
    marginTop: 2,
    paddingLeft: 2,
  },
  editButtonContainer: {
    marginRight: 5,
  },
  actionIcon: {
    marginTop: 0.7,
    marginRight: 1,
  },
  editButton: {
    textTransform: "none",
    textDecoration: "unset",
    borderBottom: "1px solid #190259",
    padding: 0,
    borderRadius: 0,
    width: "auto",
    minWidth: 0,
    height: 20,
  },
});
