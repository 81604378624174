import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import { MaterialStyles } from "commons/types/generics/materialStyles";

export const ProductSelectContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const litProductItemsCreateStyles = (): MaterialStyles => ({
  mainContainer: {
    textAlign: "center",
  },
});

export const StyledChubbsGrid = styled(Grid)`
  margin-top: 30px;
  padding: 0 5px;
  text-align: center;
`;

export const StyledProductGrid = styled.div`
  margin: 8px 0px;
`;
