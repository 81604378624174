import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { useProductFilter } from "contexts/product";
import { ProductFilter } from "contexts/product/types";
import { Fragment } from "react";

import ListAllProductsView from "../ListAllProducts";
import ListCategoryProductsView from "../ListCategoryProducts";
import ListSuppliersView from "../ListSuppliers";
import ListTagProductsView from "../ListTagProducts";
import { useAllProductsController } from "./allProduct.controller";
import { useListSuppliersController } from "./controller";
import { ListProductsProps } from "./types";

/**
 * Renders a list with the corresponding filters, based on the product filter option.
 */
const ListProductsView = ({
  products,
  categories,
  tags,
  isLoading,
  isGeneralInfiniteDisabled,
  handleClickDetails,
  handleGeneralLoadData,
}: ListProductsProps) => {
  const { selectedFilter } = useProductFilter();

  const allProductsController = useAllProductsController();

  const {
    isLoading: isLoadingSuppliers,
    suppliers,
    searchText,
    handleSearchText,
    handleClearText,
    handleClickSupplier,
    handleLoadData,
    isInfiniteDisabled,
  } = useListSuppliersController();

  const ListProducts = () => {
    switch (selectedFilter) {
      case ProductFilter.ALL:
        return (
          <ListAllProductsView
            products={allProductsController.products}
            isLoading={allProductsController.isLoading}
            handleClickDetails={handleClickDetails}
            searchText={allProductsController.filters.searchText}
            handleSearchText={allProductsController.handleSearchText}
            handleClearText={allProductsController.handleClearText}
          />
        );
      case ProductFilter.CATEGORIES:
        return (
          <ListCategoryProductsView
            products={products}
            categories={categories}
            isLoading={isLoading}
            handleClickDetails={handleClickDetails}
          />
        );
      case ProductFilter.SUPPLIERS:
        return (
          <ListSuppliersView
            suppliers={suppliers}
            searchText={searchText}
            handleSearchText={handleSearchText}
            handleClearText={handleClearText}
            handleClickSupplier={handleClickSupplier}
            handleLoadData={handleLoadData}
            isInfiniteDisabled={isInfiniteDisabled}
            isLoadingSuppliers={isLoadingSuppliers}
          />
        );
      case ProductFilter.TAGS:
        return (
          <ListTagProductsView
            products={products}
            tags={tags}
            isLoading={isLoading}
            handleClickDetails={handleClickDetails}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <ListProducts />

      {selectedFilter === ProductFilter.ALL ? (
        <IonInfiniteScroll
          onIonInfinite={allProductsController.handleLoadData}
          threshold="1%"
          disabled={allProductsController.filters.isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Carregando..."
          />
        </IonInfiniteScroll>
      ) : selectedFilter !== ProductFilter.SUPPLIERS ? (
        <IonInfiniteScroll
          onIonInfinite={handleGeneralLoadData}
          threshold="1%"
          disabled={isGeneralInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Carregando..."
          />
        </IonInfiniteScroll>
      ) : null}
    </Fragment>
  );
};

export default ListProductsView;
