import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { useTheme } from "styled-components";

import { StyledIonButton, StyledTypography } from "./styles";
import { LinkProps } from "./types";

const Link = ({ handleClick, title }: LinkProps) => {
  const theme = useTheme();

  /* TODO: This inline style fixes IonButton's offset.
     A better solution would be replacing it with
     material ui's button|link */
  /* <Link href="#">Link</Link> */
  return (
    <StyledIonButton
      onClick={handleClick}
      fill="clear"
      size="small"
      strong={true}
    >
      <StyledTypography variant={theme.typography.body[4].regular}>
        {title}
      </StyledTypography>
      <IonIcon slot="end" icon={chevronForwardOutline} />
    </StyledIonButton>
  );
};

export default Link;
