import { MaterialStyles } from "commons/types/generics/materialStyles";
import { useTheme } from "styled-components";

export const sendMailStyles = (): MaterialStyles => ({
  confirmButton: {
    backgroundColor: useTheme().color.primary.default,
    minWidth: 82,
  },
  circularProgress: {
    color: useTheme().color.neutralLight.default,
  },
  textFieldContainer: {
    marginTop: 10,
  },
  dialogActions: {
    padding: 25,
  },
  readOnlytextField: {
    backgroundColor: useTheme().color.grayscale[1],
    color: useTheme().color.grayscale[8],
  },
});
