import { PRODUCTS_LOAD_ERROR } from "commons/constants/message.constants";
import { ProductModel } from "commons/types/product.model";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { useConfiguration } from "contexts/configuration";
import { useProductFilter } from "contexts/product";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProductService from "services/product.service";

export const useProductsController = () => {
  const history = useHistory();
  const auth = useAuth();

  const { setPage, setInfiniteDisabled, filters, getCleanArray } =
    useProductFilter();
  const { cartData, showError } = useCart();
  const { tags, categories } = useProductFilter();
  const { presentError } = useConfiguration();

  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleBackClick = () => {
    if (cartData.selectedStore) {
      return history.replace(`/stores/${cartData.selectedStore.id}`);
    }
    history.goBack();
  };

  const loadProducts = useCallback(async () => {
    try {
      const { page, isInfiniteDisabled, selectedCategories, selectedTags } =
        filters;

      if (isInfiniteDisabled || !auth.user) return;
      const searchParams = {
        page,
        search: "",
        microregions: auth.user.microregions.map((m) => m.id),
        categories: getCleanArray(selectedCategories),
        tags: getCleanArray(selectedTags),
      };
      const response = await ProductService.getMany(searchParams);
      const _products: ProductModel[] = response.data?.results;

      let canSearch = true;

      if (page === 1) {
        setProducts(_products);
        canSearch = _products.length < (response?.data?.count || 0);
      } else if (_products.length && page != 1) {
        setProducts((oldProducts) => oldProducts.concat(_products));
        canSearch =
          products.concat(_products).length < (response?.data?.count || 0);
      }

      if (!canSearch) {
        setInfiniteDisabled(true);
      }
      if (canSearch && scrollEvent) {
        // Complete the scroll event, so que pagination can work
        (scrollEvent.target as HTMLIonInfiniteScrollElement).complete();
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, PRODUCTS_LOAD_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [
    filters.page,
    filters.selectedCategories,
    filters.selectedTags,
    filters.isInfiniteDisabled,
    setProducts,
  ]);

  const handleClickDetails = (product: ProductModel) => {
    history.push(`/stores/supplier/${product.supplier}/product/${product.id}`);
  };

  const handleLoadData = useCallback(
    (event: CustomEvent<void>) => {
      setScrollEvent(event);
      setPage(filters.page + 1);
    },
    [filters.page]
  );

  useEffect(() => {
    setIsLoading(true);
  }, [filters.selectedCategories, filters.selectedTags]);

  useEffect(() => {
    loadProducts();
  }, [
    loadProducts,
    filters.page,
    filters.selectedCategories,
    filters.selectedTags,
    filters.isInfiniteDisabled,
  ]);

  return {
    cartData,
    products,
    // page,
    isLoading,
    isInfiniteDisabled: filters.isInfiniteDisabled,
    handleLoadData,
    handleClickDetails,
    handleBackClick,
    // ALL
    categories,
    tags,
  };
};
