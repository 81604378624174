import { yupResolver } from "@hookform/resolvers/yup";
import { useIonAlert } from "@ionic/react";
import { useMediaQuery } from "@mui/material";
import {
  CHANGE_PASSWORD_ERROR,
  OLD_PASSWORD_INVALID,
} from "commons/constants/auth.constants";
import { tabletMinWidth } from "commons/constants/configuration.constant";
import { UNEXPECTED_ERROR } from "commons/constants/message.constants";
import { ChangePasswordModel } from "commons/types/user.model";
import { useAuth } from "contexts/auth";
import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import UserService from "services/user.service";
import { useTheme } from "styled-components";

import { passwordChangeSchema } from "./utils";

export const useChangePasswordController = () => {
  const theme = useTheme();
  const history = useHistory();
  const isTablet = useMediaQuery(tabletMinWidth);
  const [presentAlert] = useIonAlert();

  const { user } = useAuth();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseDialogResetPassword = async () => {
    try {
      localStorage.removeItem("djangoTokens");
      localStorage.removeItem("jwtToken");
      setIsDialogOpen(false);
      history.go(0);
    } catch (error) {
      showError(UNEXPECTED_ERROR);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    history.push("/account");
  };

  const handleGoBack = () => {
    history.push("/account");
  };

  const showError = (message: string) => {
    presentAlert({
      header: "Alerta",
      message,
      buttons: ["OK"],
    });
  };

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ChangePasswordModel>({
    resolver: yupResolver(passwordChangeSchema),
  });

  const onSubmit = async (_data: ChangePasswordModel) => {
    try {
      if (user) {
        setIsLoading(true);
        const response = await UserService.updateUserPassword(user.id, _data);

        if (response.status !== StatusCodes.OK) {
          throw new Error(CHANGE_PASSWORD_ERROR);
        }
      }
      setIsDialogOpen(true);
    } catch (error) {
      if (error.request.status === StatusCodes.BAD_REQUEST) {
        setError("old_password", {
          type: "custom",
          message: OLD_PASSWORD_INVALID,
        });
      }
      showError(CHANGE_PASSWORD_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    theme,
    isTablet,
    isDialogOpen,
    handleCloseDialog,
    handleGoBack,
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    isLoading,
    handleCloseDialogResetPassword,
  };
};
