import SelectInputMultipleView from "components/inputs/SelectInputMultiple";
import { useProductFilter } from "contexts/product";
import { Fragment } from "react";

import ListProductItemsView from "../ListProductItems";
import { ProductFiltersContainer } from "../styles";
import { ProductSelectContainer } from "./styles";
import { ListCategoryProductsProps } from "./types";

/**
 * Renders a list of products that can be filtered by a category selector
 */
const ListCategoryProductsView = ({
  products,
  categories,
  isLoading,
  handleClickDetails,
}: ListCategoryProductsProps) => {
  const {
    filters: { selectedCategories },
    handleSearchCategories,
    getCleanArray,
  } = useProductFilter();

  return (
    <Fragment>
      <ProductFiltersContainer container>
        <ProductSelectContainer>
          <SelectInputMultipleView
            placeholder="Selecione uma ou mais categorias"
            selectedValues={selectedCategories}
            handleSelectedValues={(e) =>
              handleSearchCategories(e.target.value as number[])
            }
            items={categories}
          />
        </ProductSelectContainer>
      </ProductFiltersContainer>
      <ListProductItemsView
        showItems={!!getCleanArray(selectedCategories).length}
        products={products}
        isLoading={isLoading}
        handleClickDetails={handleClickDetails}
      />
    </Fragment>
  );
};

export default ListCategoryProductsView;
