import { useState } from "react";

export const useFormDateInputController = () => {
  const [isOpen, setOpen] = useState(false);

  const handleToggleOpen = () => setOpen(!isOpen);

  return {
    isOpen,
    handleToggleOpen,
  };
};
