import SelectInputMultipleView from "components/inputs/SelectInputMultiple";
import { useProductFilter } from "contexts/product";
import { Fragment } from "react";

import ListProductItemsView from "../ListProductItems";
import { ProductFiltersContainer } from "../styles";
import { ProductSelectContainer } from "./styles";
import { ListTagProductsProps } from "./types";

/**
 * Renders a list of products that can be filtered by a tag selector
 */
const ListTagProductsView = ({
  products,
  tags,
  isLoading,
  handleClickDetails,
}: ListTagProductsProps) => {
  const {
    filters: { selectedTags },
    handleSearchTags,
    getCleanArray,
  } = useProductFilter();

  return (
    <Fragment>
      <ProductFiltersContainer container>
        <ProductSelectContainer>
          <SelectInputMultipleView
            placeholder="Selecione uma ou mais tags"
            selectedValues={selectedTags}
            handleSelectedValues={(e) =>
              handleSearchTags(e.target.value as number[])
            }
            items={tags}
          />
        </ProductSelectContainer>
      </ProductFiltersContainer>
      <ListProductItemsView
        showItems={!!getCleanArray(selectedTags).length}
        products={products}
        isLoading={isLoading}
        handleClickDetails={handleClickDetails}
        customMessage="Os resultados de produtos irão aparecer depois de selecionar uma tag"
      />
    </Fragment>
  );
};

export default ListTagProductsView;
