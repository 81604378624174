import { ORDERS_LOAD_ERROR } from "commons/constants/message.constants";
import { OrdersListModel } from "commons/types/order.model";
import { useConfiguration } from "contexts/configuration";
import { StatusCodes } from "http-status-codes";
import { CardItem } from "pages/orders/types";
import { useCallback, useEffect, useState } from "react";
import OrderService from "services/order.service";

export const useOrderListController = (orderStatus: string[]) => {
  const { presentError } = useConfiguration();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();
  const [orders, setOrders] = useState<CardItem[]>([]);

  const loadOrders = useCallback(async () => {
    try {
      const isIdSearch = search && /^[0-9]*$/.test(search);

      const searchParams = {
        id: isIdSearch ? Number(search) : undefined,
        page,
        storeName: isIdSearch ? undefined : search,
        status: orderStatus,
      };

      const response = await OrderService.getMany(searchParams);

      if (response.status === StatusCodes.OK) {
        const results = response.data?.results;
        let canSearch = true;
        const _orders = results.map((order: OrdersListModel) => {
          return {
            id: order.id,
            store: order.fantasy_name,
            order: order.id,
            suppliers: order.supplier_names,
            totalValue: Number(order.total_sale),
            status: order.status,
            logo: order.store_logo,
          };
        });

        if (page === 1) {
          setOrders(_orders);
          canSearch = _orders.length < (response?.data?.count || 0);
        } else if (_orders.length && page !== 1) {
          setOrders((prev) => prev.concat(_orders));
          canSearch =
            orders.concat(_orders).length < (response?.data?.count || 0);
        }
        if (!canSearch) {
          setIsInfiniteDisabled(true);
        }

        if (canSearch && scrollEvent) {
          (scrollEvent.target as HTMLIonInfiniteScrollElement).complete();
        }
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, ORDERS_LOAD_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [page, search, isInfiniteDisabled]);

  const handleSearchText = useCallback((text: string) => {
    setSearch(text);
  }, []);

  const handleClearText = useCallback(() => {
    setSearch("");
  }, []);

  const handleLoadData = useCallback(
    (event: CustomEvent<void>) => {
      setScrollEvent(event);
      setPage(page + 1);
    },
    [page]
  );

  // TODO verify duplicate request in canceled order status
  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  return {
    orders,
    search,
    isLoading,
    isInfiniteDisabled,
    handleLoadData,
    handleSearchText,
    handleClearText,
  };
};
