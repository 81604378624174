import { IonIcon } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import styled from "styled-components";

const SelectInputIconBase = styled(IonIcon)`
  color: ${({ theme }) => theme.color.grayscale[8]};
  z-index: 1;
  margin: 0;
  margin-right: 14px;
  padding: 0;
  display: inline-block;
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`;

const SelectInputIconBaseRight = styled(SelectInputIconBase)`
  left: 0;
  margin-left: 32px;
`;

export const SelectInputIconLeft = () => (
  <SelectInputIconBase icon={chevronDown} />
);

export const SelectInputIconRight = ({ icon }: { icon: string }) => (
  <SelectInputIconBaseRight icon={icon} />
);
