import { IonIcon } from "@ionic/react";
import { CircularProgress, Dialog, Grid } from "@mui/material";
import ButtonErrorFilled from "components/ButtonErrorFilled";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import Typography from "components/Typography";
import { checkmarkCircleOutline } from "ionicons/icons";
import { SyntheticEvent } from "react";
import { useTheme } from "styled-components";

import { dialogStyles } from "./styles";
import { DialogWithButtonsProps } from "./types";

const DialogWithButtons = ({
  isSubmiting = false,
  onClose,
  isOpen,
  showIcon = false,
  icon,
  altIcon,
  message,
  isDangerAction,
  hasAutoSizedBtns,
  onClickPrimaryButton,
  onClickSecondaryButton,
  primaryButtonText,
  secondaryButtonText,
}: DialogWithButtonsProps) => {
  const theme = useTheme();
  const styles = dialogStyles(hasAutoSizedBtns);

  const handleClose = (e: SyntheticEvent, reason: string) => {
    if (reason && reason == "backdropClick") return;
    onClose();
  };

  const Button = isDangerAction ? ButtonErrorFilled : ButtonPrimaryFilled;

  const DefaultIcon = () => (
    <IonIcon
      icon={checkmarkCircleOutline}
      style={styles.icon}
      color="success"
    />
  );

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      fullWidth
      PaperProps={{
        style: styles.dialogPaper,
      }}
    >
      <Grid container direction="column" style={styles.gridContainer}>
        {showIcon ? (
          <Grid item xs>
            {icon ? icon : <DefaultIcon />}
          </Grid>
        ) : null}
        {/* TODO: check if altIcon is needed */}
        {altIcon ? (
          <Grid item xs>
            <img src={altIcon} alt="question_mark" />
          </Grid>
        ) : null}
        <Grid item xs style={styles.messageContainer}>
          {message}
        </Grid>
        <Grid item container xs alignItems="center">
          {primaryButtonText ? (
            <Grid item xs={!hasAutoSizedBtns} style={styles.buttonsContainer}>
              <ButtonPrimaryOutlined
                fullWidth
                onClick={onClickPrimaryButton}
                style={styles.leftButton}
                disabled={isSubmiting}
              >
                <Typography variant={theme.typography.body[3].highlight}>
                  {primaryButtonText}
                </Typography>
              </ButtonPrimaryOutlined>
            </Grid>
          ) : null}
          {secondaryButtonText ? (
            <Grid item xs={!hasAutoSizedBtns}>
              <Button
                fullWidth
                onClick={onClickSecondaryButton}
                style={styles.rightButton}
                color="error"
                disabled={isSubmiting}
              >
                {isSubmiting ? (
                  <CircularProgress size={25} />
                ) : (
                  <Typography variant={theme.typography.body[3].highlight}>
                    {secondaryButtonText}
                  </Typography>
                )}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DialogWithButtons;
