import { useIonAlert, useIonLoading } from "@ionic/react";
import {
  PRODUCT_ADD_MULTIPLE_ERROR,
  PRODUCT_LOAD_ERROR,
  SUPPLIER_LOAD_ERROR,
} from "commons/constants/message.constants";
import {
  ProductModel,
  SkusAttributesValuesModel,
} from "commons/types/product.model";
import { SupplierModel } from "commons/types/supplier.model";
import { applyDiscountTotal } from "commons/utils/cart";
import { uniqByField } from "commons/utils/general";
import { useCart } from "contexts/cart";
import { SelectedSupplier } from "contexts/cart/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router";
import ProductService from "services/product.service";
import SupplierService from "services/supplier.service";

import {
  AddedProductVariantModel,
  FormObjDataModel,
  ProductDetailsLocationState,
  ProductDetailsRouteParams,
  ProductOptions,
  ProductOptionsValues,
  ProductPreferencesModel,
} from "./types";

export const useProductDetailController = (productId: string) => {
  const { control, getValues, setValue, watch } = useForm();
  const { id, supplierId } = useParams<ProductDetailsRouteParams>();
  const { state } = useLocation<ProductDetailsLocationState>();
  const isEditingCart = state?.isEditingCart ? true : false;

  const {
    cartData,
    selectedSuppliers,
    handleUpdateSelectedSuppliers,
    handleUpdateSelectedSupplierVariant,
    paymentMethodBundle,
    selectedPaymentMethod,
    getSupplierPaymentMethod,
  } = useCart();

  const [characteristicLabel, setCharacteristicLabel] = useState("");
  const [product, setProduct] = useState<ProductModel>({} as ProductModel);
  const [supplier, setSupplier] = useState<SupplierModel>({} as SupplierModel);

  const [selectedSupplier, setSelectedSupplier] = useState<SelectedSupplier>(
    {} as SelectedSupplier
  );
  const selectedProduct = useMemo(() => {
    if (!selectedSupplier.id) return undefined;
    return selectedSupplier.selectedProducts.find(
      (item) => item.id === Number(id)
    );
  }, [id, selectedSupplier.id]);

  const [productPreferences, setProductPreferences] = useState<
    ProductPreferencesModel[]
  >([]);
  const [filterAttributeIds, setFilterAttributeIds] = useState<number[]>([]);

  const [productOptions, setProductOptions] = useState<ProductOptions[]>([]);

  const [inputOptions, setInputOptions] = useState<ProductOptions | null>(null);

  const [addedProductVariants, setAddedProductVariants] =
    useState<AddedProductVariantModel>({});

  const [isLoading, setLoading] = useState(true);
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const productImages = useMemo(() => {
    return product?.images?.map((i) => {
      return {
        image: i.images,
      };
    });
  }, [product]);
  const productTotal = useMemo(() => {
    return Object.values(addedProductVariants)?.reduce(
      (acc, curr) => acc + curr.price * curr.quantity,
      0
    );
  }, [addedProductVariants]);

  const supplierTotal = useMemo(() => {
    if (!selectedSupplier.id) return 0;
    return selectedSupplier.supplierTotal;
  }, [selectedSupplier.supplierTotal]);

  const isPaymentMethodLoaded = () => {
    const selectedPM = cartData?.selectedPaymentMethod || selectedPaymentMethod;
    if (paymentMethodBundle && paymentMethodBundle[supplierId] && selectedPM) {
      return !!paymentMethodBundle[supplierId][selectedPM.id];
    }
    return false;
  };

  const loadInfo = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ProductService.getOne(productId);
      const product: ProductModel = response?.data;

      const newPreferences = product?.preferences.map((pref) => {
        return {
          id: pref.attribute.id,
          skuId: pref.id,
          name: pref.attribute.label || pref.attribute.name,
          label: pref.attribute.label || pref.attribute.name,
          order: pref.order,
          isQuantitative: pref.is_quantitative,
          attributeValues: pref.attribute_values,
          attributeValuesOrder: pref.attribute_values_order,
        };
      });

      setProductPreferences(
        newPreferences.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          } else if (a.order > b.order) {
            return 1;
          }
          return 0;
        })
      );

      setProduct(product);
    } catch (error) {
      presentAlert({
        header: "Alerta",
        message: PRODUCT_LOAD_ERROR,
        buttons: ["OK"],
      });
    } finally {
      setLoading(false);
      dismiss();
    }
  }, [productId]);

  const loadSupplier = useCallback(async () => {
    try {
      setLoading(true);
      if (product.supplier) {
        const response = await SupplierService.getOne(product?.supplier);
        const _supplier = response?.data;
        setSupplier(_supplier || {});
      }
    } catch (error) {
      presentAlert({
        header: "Alerta",
        message: SUPPLIER_LOAD_ERROR,
        buttons: ["OK"],
      });
    } finally {
      setLoading(false);
      dismiss();
    }
  }, [product?.supplier]);

  const fetchOptions = useCallback(
    async (
      productId: number,
      attributeId: number,
      attributeValueIds?: number[]
    ) => {
      try {
        if (!productId) return;
        const _attributeValueIds = attributeValueIds
          ? attributeValueIds.filter((d) => !!d)
          : [];
        const response = await ProductService.getSkus(
          productId,
          attributeId,
          _attributeValueIds
        );
        const skus: SkusAttributesValuesModel[] = response?.data;
        const filteredSkus: SkusAttributesValuesModel[] = uniqByField(
          skus,
          "attribute_value"
        );

        const options: ProductOptionsValues[] = filteredSkus?.map((s) => {
          return {
            id: s.attribute_value_id,
            name: s.attribute_value,
            skuId: s.id,
            price: s.price,
            multiple: s.multiple,
            sku: s.sku,
          };
        });

        return options;
      } catch (error) {
        presentAlert({
          header: "Alerta",
          message: PRODUCT_LOAD_ERROR,
          buttons: ["OK"],
        });
      } finally {
        setLoading(false);
        dismiss();
      }
    },
    [productId]
  );

  /**
   * Build the product variant label based on the values of the attributes
   * ex: "Camisa X, Malha fina, Preto, P"
   */
  const getProductVariantLabel = (skuId?: number) => {
    const formValues = getValues();
    const formEntries = Object.entries(formValues);

    if (isEditingCart) {
      return state?.variant?.label || product.name;
    }

    let label = `${product.name},`;
    formEntries.forEach(([index, id]) => {
      const _index = Number(index);
      // Values from selects
      if (!isNaN(_index) && productOptions[_index]) {
        const _options = productOptions[_index].values?.find(
          (p) => p.id === id
        );
        if (_options) {
          label += ` ${_options?.name}, `;
        }
        // Values from input
      }
    });
    if (skuId) {
      const inputOption = inputOptions?.values?.find(
        (opt) => opt.skuId === skuId
      );
      if (inputOption) {
        label += ` ${inputOption?.name}`;
      }
    }
    return label;
  };

  const handleOnAdd = (sku: ProductOptionsValues) => {
    setAddedProductVariants((oldValues: AddedProductVariantModel) => {
      const index = sku.skuId;
      const oldSku = oldValues[index];
      const newQtt = getValues(`sku-${index}`) || 0;
      const qtt = Number(newQtt) + sku.multiple;
      const label = getProductVariantLabel(index);

      const values = getValues();
      const selectedOptions: number[] = [];
      const selectedSkuIds: number[] = [];

      Object.keys(values).forEach((key) => {
        if (isNaN(Number(key))) return; // ignores quantitative (sku-{id}) entries
        selectedOptions.push(values[key]);
      });

      if (inputOptions?.values) {
        inputOptions.values.forEach((value) => {
          selectedSkuIds.push(value.skuId); // sku ids of inputs that share the same select options
        });
      }

      if (qtt % sku.multiple === 0) {
        setValue(`sku-${index}`, qtt);

        return {
          ...oldValues,
          [index]: {
            ...oldSku,
            quantity: qtt,
            sku: index,
            multiple: sku.multiple,
            price: sku.price,
            label,
            selectedOptions,
            selectedSkuIds,
          },
        };
      }
      presentAlert({
        header: "Alerta",
        message: PRODUCT_ADD_MULTIPLE_ERROR,
        buttons: ["OK"],
      });
      return oldValues;
    });
  };

  const handleOnRemove = (sku: ProductOptionsValues) => {
    setAddedProductVariants((oldValues: AddedProductVariantModel) => {
      const index = sku.skuId;
      const oldSku = oldValues[index];
      const newQtt = getValues(`sku-${index}`) || 0;
      const qtt = Number(newQtt) - sku.multiple;

      const shouldRemove = qtt <= 0;
      if (qtt % sku.multiple === 0) {
        setValue(`sku-${index}`, qtt);
        if (shouldRemove) {
          delete oldValues[index];
          return {
            ...oldValues,
          };
        }
        return {
          ...oldValues,
          [index]: {
            ...oldSku,
            quantity: shouldRemove ? 0 : qtt,
            sku: index,
            multiple: sku.multiple,
            price: sku.price,
          },
        };
      }
      presentAlert({
        header: "Alerta",
        message: PRODUCT_ADD_MULTIPLE_ERROR,
        buttons: ["OK"],
      });
      return oldValues;
    });
  };

  const getCartDiscount = () => {
    const supplierDiscount =
      selectedSupplier?.selectedPaymentMethod?.discountPercent;
    const _id = cartData.selectedPaymentMethod?.id || selectedPaymentMethod?.id;
    const discountPercent = supplierDiscount
      ? supplierDiscount
      : _id &&
        paymentMethodBundle &&
        paymentMethodBundle[supplier.id] &&
        paymentMethodBundle[supplier.id][_id]
      ? paymentMethodBundle[supplier.id][_id].discountPercent
      : 1;

    return discountPercent;
  };

  const handleApplyDiscount = (price: number) => {
    return applyDiscountTotal(price, getCartDiscount());
  };

  const setProductOptionsValue = async (
    productId: number,
    _productOptions: ProductOptions[],
    position = 0,
    attributeValueIds?: number[]
  ) => {
    const characteristics = productPreferences.filter((p) => !p.isQuantitative);
    const canAdd = !position ? !_productOptions.length : true;

    if (characteristics.length && canAdd) {
      const option = characteristics[position];

      if (option) {
        const newValues = [..._productOptions];
        newValues[position] = {
          ...option,
          values: await fetchOptions(productId, option.id, attributeValueIds),
        };

        let attributeValues: any = [];
        for (const newValue of newValues) {
          if (newValue.attributeValuesOrder) {
            for (const value of newValue.attributeValuesOrder) {
              const attributeValue = newValue.values?.find(
                (v) => v.name === value.attribute
              );
              if (attributeValue) {
                attributeValues.push(attributeValue);
              }
            }
          }

          newValue.values = attributeValues;
          attributeValues = [];
        }

        setProductOptions(newValues);
      } else {
        if (attributeValueIds) {
          await setProductInputsValue(productId, attributeValueIds);
        }
      }
      // Enter in else if product have only one preference registered
    } else if (productPreferences.length === 1) {
      await setProductQuantitativeOptions(productId, position);
    }
  };

  const setProductQuantitativeOptions = async (
    productId: number,
    position: number
  ) => {
    const newValues = [];
    const option = productPreferences.find(
      ({ isQuantitative }) => isQuantitative
    );

    if (!option) return;

    newValues[position] = {
      ...option,
      values: await fetchOptions(productId, option.id),
    };

    const attributeValues: any = [];
    for (const newValue of newValues) {
      if (!newValue.attributeValuesOrder) return;
      for (const newValueOrder of newValue.attributeValuesOrder) {
        const attributeValue = newValue.values?.find(
          (value) => value.name === newValueOrder.attribute
        );
        if (attributeValue) {
          attributeValues.push(attributeValue);
        }
      }

      newValues.values = attributeValues;
    }
    setInputOptions(newValues[position]);
  };

  /**
   * Setup the properties of the inputOptions.
   * So the block with the input quantities is rendered
   * @param productId
   * @param attributeValueIds
   */
  const setProductInputsValue = async (
    productId: number,
    attributeValueIds: number[]
  ) => {
    const characteristics = productPreferences.find((p) => !!p.isQuantitative);
    if (characteristics) {
      const newValues = {
        ...characteristics,
        values: await fetchOptions(
          productId,
          characteristics.id,
          attributeValueIds
        ),
      };

      const attributeValues: any = [];
      if (newValues.attributeValuesOrder) {
        for (const newValue of newValues.attributeValuesOrder) {
          const attributeValue = newValues.values?.find(
            (v) => v.name === newValue.attribute
          );
          if (attributeValue) {
            attributeValues.push(attributeValue);
          }
        }
      }

      newValues.values = attributeValues;

      if (newValues.values) {
        setInputOptions(newValues);
      }
    }
  };

  const cleanInputValues = () => {
    if (inputOptions) {
      const newValues = {
        ...inputOptions,
        values: [],
      };
      if (newValues) {
        setInputOptions(newValues);
      }
    }
  };

  const handleUpdateFormValues = (
    name: string | undefined,
    obj: FormObjDataModel
  ) => {
    if (name && obj[name] === undefined) return;
    const position = Number(name);
    const nextPosition = position + 1;
    let newFilterAttributeIds = filterAttributeIds;
    let attributeValueId = null;
    let _productOptions = productOptions;

    const shouldClean = newFilterAttributeIds.length > Number(name);
    if (name) {
      attributeValueId = obj[name];
    }
    if (attributeValueId && !isNaN(position)) {
      if (!shouldClean) {
        newFilterAttributeIds[position] = attributeValueId;
        setFilterAttributeIds(newFilterAttributeIds);
      } else {
        // Clean form values
        setValue(`${nextPosition}`, undefined);
        _productOptions = _productOptions.slice(0, nextPosition);
        newFilterAttributeIds = newFilterAttributeIds.slice(0, position);
        newFilterAttributeIds[position] = attributeValueId;
        setFilterAttributeIds(newFilterAttributeIds);
        cleanInputValues();
      }
    }

    if (
      product?.id &&
      nextPosition &&
      nextPosition <= productPreferences.length
    ) {
      setProductOptionsValue(
        product?.id,
        _productOptions,
        nextPosition,
        newFilterAttributeIds
      );
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  /**
   * Load supplier
   **/
  useEffect(() => {
    loadSupplier();
  }, [product]);

  useEffect(() => {
    if (!isEditingCart && selectedProduct?.values) {
      // Sets last selected options and all quantitative values (stored in context)
      for (const [key, value] of Object.entries(selectedProduct.values)) {
        setValue(`${key}`, value);
      }
    }
  }, [isEditingCart, selectedProduct]);

  /**
   * Update selected supplier? TODO: check
   **/
  useEffect(() => {
    const selectedSupplier = selectedSuppliers.find(
      (item) => item.id === Number(supplierId)
    );
    if (selectedSupplier) {
      setSelectedSupplier(selectedSupplier);
    }
  }, [selectedSuppliers]);

  /**
   * Init default form's components
   **/
  useEffect(() => {
    if (!isEditingCart && selectedProduct?.addedProductVariants) {
      const {
        addedProductVariants,
        productOptions = [],
        inputOptions,
      } = selectedProduct;
      setAddedProductVariants(addedProductVariants);
      setProductOptions(productOptions);

      // last updated quantitative values (from storage)
      setInputOptions(inputOptions);
    }
  }, [isEditingCart, selectedProduct]);

  /**
   * Init edit form components
   **/
  useEffect(() => {
    const _variant = state?.variant;
    if (isEditingCart && _variant) {
      const productVariant: AddedProductVariantModel = {
        [_variant.sku]: {
          ..._variant,
        },
      };
      setAddedProductVariants(productVariant);

      setCharacteristicLabel(_variant.label || "");

      const sku = Number(_variant.sku);
      const options: ProductOptionsValues = {
        id: 1,
        skuId: sku,
        name: "",
        price: Number(_variant.price),
        multiple: Number(_variant.multiple),
        sku: sku,
      };

      const _inputOptions: ProductOptions = {
        id: sku,
        name: "",
        label: "",
        order: 1,
        isQuantitative: true,
        values: [options],
      };

      setInputOptions(_inputOptions);
    }
  }, [isEditingCart]);
  /**
   * Fetch supplier's paymentMethod if needed
   **/
  useEffect(() => {
    const _id = cartData.selectedPaymentMethod?.id || selectedPaymentMethod?.id;
    if (supplier.id && _id) {
      getSupplierPaymentMethod(supplier.id, _id, paymentMethodBundle);
    }
  }, [supplier.id, cartData.selectedPaymentMethod]);

  /**
   * Init product options
   **/
  useEffect(() => {
    if (!isEditingCart && product?.id) {
      setProductOptionsValue(product?.id, productOptions);
    }
  }, [isEditingCart, product, productOptions]);

  /**
   * Update selected suppliers based on form values
   **/
  useEffect(() => {
    if (!isEditingCart) {
      const subscription = watch((obj, { name }) => {
        handleUpdateFormValues(name, obj);
      });
      return () => subscription.unsubscribe();
    }
  }, [watch()]);

  // Update selected suppliers when adding new products
  useEffect(() => {
    if (!isEditingCart && inputOptions) {
      const values = getValues();
      handleUpdateSelectedSuppliers(supplier, {
        ...product,
        addedProductVariants,
        inputOptions,
        productOptions,
        productTotal,
        values,
      });
    }
  }, [addedProductVariants, inputOptions]);

  // Update selected suppliers when editing
  useEffect(() => {
    if (isEditingCart && inputOptions) {
      const values = getValues();
      handleUpdateSelectedSupplierVariant(
        supplier,
        {
          ...product,
          addedProductVariants,
          inputOptions,
          productOptions,
          productTotal,
          values,
        },
        addedProductVariants,
        state?.variant?.sku
      );
    }
  }, [addedProductVariants, inputOptions, isEditingCart]);

  useEffect(() => {
    if (isLoading) {
      present({
        message: "Carregando...",
      });
    } else {
      dismiss();
    }
  }, []);

  return {
    control,
    supplier,
    selectedSupplier,
    product,
    productImages,
    productOptions,
    inputOptions,
    addedProductVariants,
    isLoading,
    isPaymentMethodLoaded,
    isEditingCart,
    characteristicLabel,
    // Controls
    handleOnAdd,
    handleOnRemove,
    // Totals
    productTotal,
    supplierTotal,
    handleApplyDiscount,
  };
};
