import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useState, useMemo } from "react";

export const useFormPasswordController = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((old) => !old);
  };

  const eyeIcon = useMemo(
    () => (isPasswordVisible ? eyeOffOutline : eyeOutline),
    [isPasswordVisible]
  );

  return { isPasswordVisible, togglePasswordVisibility, eyeIcon };
};
