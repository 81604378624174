import { IonPage } from "@ionic/react";
import { Grid } from "@mui/material";
import dropIcon from "assets/svgs/icons/drop.svg";
import DialogWithButtons from "components/DialogWithButtons";
import SimpleHeader from "components/SimpleHeader";
import Typography from "components/Typography";
import { StyledIonContent } from "pages/stores/styles";
import { useTheme } from "styled-components";

import DraftListView from "./components/DraftList";
import OrderSegmentsView from "./components/OrderSegments";
import OrdersClosedView from "./components/OrderSegments/OrderClosed";
import OrdersOpenView from "./components/OrderSegments/OrderOpen";
import { useOrdersController } from "./controller";
import { orderStyles } from "./styles";
import { SEGMENT_OPTIONS } from "./types";

const OrdersView = () => {
  const theme = useTheme();
  const { header, section } = orderStyles();

  const {
    segment,
    isSubmiting,
    isSaveCartDialogOpen,
    handleGoToDetails,
    handleCloseSaveCartDialog,
    handleDoSaveCart,
    handleChangeSegment,
  } = useOrdersController();

  const OrderLists = () => {
    switch (segment) {
      case SEGMENT_OPTIONS.DRAFT:
        return <DraftListView handleGoToDetails={handleGoToDetails} />;

      case SEGMENT_OPTIONS.OPEN:
        return <OrdersOpenView handleGoToDetails={handleGoToDetails} />;

      case SEGMENT_OPTIONS.CLOSED:
        return <OrdersClosedView handleGoToDetails={handleGoToDetails} />;
    }
  };

  return (
    <IonPage>
      <SimpleHeader />
      <StyledIonContent>
        <Grid sx={header}>
          <Typography
            variant={theme.typography.heading[2].regular}
            color="white"
          >
            Histórico de <b>pedidos</b>
          </Typography>
        </Grid>
        <Grid sx={section}>
          <OrderSegmentsView
            segment={segment}
            handleChangeSegment={handleChangeSegment}
          />
        </Grid>
        {OrderLists()}
      </StyledIonContent>

      <DialogWithButtons
        hasAutoSizedBtns
        isSubmiting={isSubmiting}
        showIcon
        isOpen={isSaveCartDialogOpen}
        onClose={handleCloseSaveCartDialog}
        primaryButtonText="Voltar"
        secondaryButtonText="Continuar"
        onClickPrimaryButton={handleCloseSaveCartDialog}
        onClickSecondaryButton={handleDoSaveCart}
        icon={<img src={dropIcon} />}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].highlight}
              color={theme.color.information.default}
            >
              <b>Seu pedido em andamento será salvo como rascunho</b>
            </Typography>
            <Grid my={3} />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              Antes de continuar com este pedido, o pedido em andamento será
              salvo como rascunho.
            </Typography>
          </>
        }
      />
    </IonPage>
  );
};

export default OrdersView;
