import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { formatCurrency } from "commons/utils/format";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { productSubtotalStyles } from "./styles";
import { ProductSupplierCardProps } from "./types";

const ProductSupplierCardView = ({ supplier }: ProductSupplierCardProps) => {
  const theme = useTheme();
  const styles = productSubtotalStyles(theme);

  return (
    <Card style={styles.mainCard}>
      <Grid container direction="column" item xs spacing={2}>
        <Grid item xs>
          <Typography
            variant={theme.typography.body[2].highlight}
            color={theme.color.grayscale[10]}
          >
            Fábrica
          </Typography>
        </Grid>

        <Grid container item xs alignItems="center" spacing={2}>
          <Grid item>
            <img src={supplier.logo} style={styles.supplierImg} />
          </Grid>
          <Grid container item xs direction="column">
            <Grid item>
              <Typography
                variant={theme.typography.body[3].highlight}
                color={theme.color.grayscale[10]}
              >
                {supplier.fantasy_name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant={theme.typography.body[4].regular}
                color={theme.color.grayscale[8]}
              >
                Pedido mínimo {formatCurrency(supplier.minimum_order_total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs spacing={1}>
          <Grid item>
            <Typography
              variant={theme.typography.body[3].regular}
              color={theme.color.grayscale[7]}
            >
              Referência:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={theme.typography.subheading[3].highlight}
              color={theme.color.grayscale[7]}
            >
              #{supplier.registration_number}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductSupplierCardView;
