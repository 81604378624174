import { MaterialStyles } from "commons/types/generics/materialStyles";

export const formDateInputsStyles = (): MaterialStyles => ({
  inputContainner: {
    marginBottom: "25px",
  },
  cardContainer: {
    marginTop: "5px",
  },
});
