import { IonLabel, IonSegmentButton } from "@ionic/react";
import { StyledIonSegment } from "pages/orders/styles";
import { SEGMENT_OPTIONS } from "pages/orders/types";

import { OrderSegmentsProps } from "./types";

const OrderSegmentsView = ({
  segment,
  handleChangeSegment,
}: OrderSegmentsProps) => {
  return (
    <StyledIonSegment value={segment} color="primary" mode="md">
      <IonSegmentButton
        onClick={() => handleChangeSegment(SEGMENT_OPTIONS.DRAFT)}
        value={SEGMENT_OPTIONS.DRAFT}
      >
        <IonLabel>Rascunhos</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton
        onClick={() => handleChangeSegment(SEGMENT_OPTIONS.OPEN)}
        value={SEGMENT_OPTIONS.OPEN}
      >
        <IonLabel>Abertos</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton
        onClick={() => handleChangeSegment(SEGMENT_OPTIONS.CLOSED)}
        value={SEGMENT_OPTIONS.CLOSED}
      >
        <IonLabel>Fechados</IonLabel>
      </IonSegmentButton>
    </StyledIonSegment>
  );
};

export default OrderSegmentsView;
