import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const supplierInfoStyles = (theme: DefaultTheme): MaterialStyles => ({
  mainInfoContainer: {
    padding: "0 5px",
  },
  totalContainer: {
    padding: "5px 10px 10px 10px",
    maxWidth: 400,
  },
  supplierImg: {
    width: "60px",
    height: "60px",
    padding: 5,
    borderRadius: 8,
    border: "1px solid",
    borderColor: theme.color.grayscale[7],
  },
  alertContainer: {
    backgroundColor: "#F2B705",
    padding: "10px",
    margin: "10px 0",
    width: "100%",
  },
});
