import styled from "@emotion/styled";
import { IonContent } from "@ionic/react";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import styledComponents from "styled-components";

export const globalStyles = (): MaterialStyles => ({
  textAlignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
});

export const StyledProductCardsGrid = styled.div`
  display: grid;
  padding: 0px;
  grid-template-columns: repeat(auto-fit, minmax(156px, max-content));
  grid-gap: 16px;
  justify-content: center;
`;

export const StyledIonContent = styledComponents(IonContent)`
  --background: ${({ theme }) => theme.color.grayscale[1]};
`;
