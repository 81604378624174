import { SelectedProduct } from "contexts/cart/types";

export const applyDiscountTotal = (price: number, discountPercent: number) => {
  return price * discountPercent;
};

export const calculateDiscountPercent = (discountPercent = 0) => {
  return 1 - (discountPercent || 0) / 100;
};

export const getProductTotalSum = (product: SelectedProduct) => {
  if (!product?.addedProductVariants) return 0;
  return Object.values(product.addedProductVariants)?.reduce(
    (acc, curr) => acc + curr.price * curr.quantity,
    0
  );
};

export const getSupplierTotalSum = (products: SelectedProduct[]) => {
  return products.reduce((acc, curr) => acc + curr.productTotal, 0);
};
