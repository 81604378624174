import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const StyledGrid = styled(Grid)`
  padding: 15px 20px 15px 20px;
  margin: 20px 0;
  @media (max-width: 378px) {
    min-width: 80%;
  }
  @media (min-width: 700px) {
    min-width: 380px;
  }
`;

export const StyledContent = styled(Grid)`
  justify-content: center;
`;

export const storeCreateStyles = (theme: DefaultTheme): MaterialStyles => ({
  gridStyle: { margin: "15px 20px" },
  footerStyle: {
    textAlign: "center",
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.07)",
    backgroundColor: `${theme.color.neutralLight.default}`,
    progress: { color: "white" },
  },
});
