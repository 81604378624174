import {
  INVALID_EMAIL,
  REQUIRED_FIELD,
} from "commons/constants/form.message.constants";
import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().trim().required(REQUIRED_FIELD).email(INVALID_EMAIL),
  password: Yup.string().trim().required(REQUIRED_FIELD),
});
