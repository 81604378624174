export const LOGIN_SUCCESS = "Login realizado com sucesso";
export const LOGIN_ERROR = "Erro ao realizar login";
export const LOGIN_VERIFY_ERROR = "Erro ao verificar token";

export const USER_LOAD_ERROR = "Erro ao buscar usuário";

export const STORES_LOAD_ERROR = "Erro ao carregar lojas";
export const STORE_LOAD_ERROR = "Erro ao carregar loja";

export const PRODUCT_LOAD_ERROR = "Erro ao carregar produto";
export const PRODUCTS_LOAD_ERROR = "Erro ao carregar produtos";
export const PRODUCT_CATEGORIES_LOAD_ERROR = "Erro ao carregar categorias";
export const PRODUCT_TAG_LOAD_ERROR = "Erro ao carregar tags";

export const PRODUCT_ADD_MULTIPLE_ERROR = "Essa quantidade é inválida";

export const SUPPLIERS_LOAD_ERROR = "Erro ao carregar fábricas";
export const SUPPLIER_LOAD_ERROR = "Erro ao carregar fábrica";

export const MICROREGIONS_LOAD_ERROR = "Erro ao carregar microrregiões";
export const STORE_CREATE_ERROR = "Erro ao salvar loja";
export const STORE_TYPE_LOAD_ERROR = "Erro ao carregar ramo de atividade";

export const CARTS_LOAD_ERROR = "Erro ao carregar rascunhos";
export const ORDERS_LOAD_ERROR = "Erro ao carregar pedidos";

export const UNEXPECTED_ERROR =
  "Houve um erro inesperado, tente novamente mais tarde.";

export const REMOVE_CART_ERROR =
  "Você não pode excluir o rascunho, pois o mesmo está em andamento. Clique em alterar cliente e tente excluir o rascunho novamente.";

export const STORE_IDENTIFICATION_IN_USE =
  "Já existe uma loja cadastrada com este cnpj.";

export const SEND_MAIL_SUCCESS = "E-mail enviado com sucesso.";
export const SEND_MAIL_ERROR =
  "Houve um erro ao tentar enviar o pdf por e-mail.";
