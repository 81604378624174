import { MaterialStyles } from "commons/types/generics/materialStyles";

export const chubbsStyles = (isCentered = true): MaterialStyles => ({
  chubbsContianer: {
    padding: "24px",
    textAlign: isCentered ? "center" : "unset",
    width: isCentered ? "100%" : "40vw",
  },
  typography: {
    lineHeight: "28px",
  },
});
