import {
  ProductCategoryModel,
  ProductTagModel,
} from "commons/types/product.model";
import { Dispatch, SetStateAction } from "react";

// values MUST BE non-diacritic, lowercase, non-latin letters.
export enum ProductFilter {
  ALL = "todos",
  CATEGORIES = "categorias",
  SUPPLIERS = "fabricas",
  TAGS = "tags",
}

// Uses hashed possible filters as keys to reference the enum.
export const ProductFilterHashes = {
  [`#${ProductFilter.ALL}`]: ProductFilter.ALL,
  [`#${ProductFilter.CATEGORIES}`]: ProductFilter.CATEGORIES,
  [`#${ProductFilter.SUPPLIERS}`]: ProductFilter.SUPPLIERS,
  [`#${ProductFilter.TAGS}`]: ProductFilter.TAGS,
};

export interface ProductFilterContextProps {
  categories: ProductCategoryModel[];
  tags: ProductTagModel[];
  selectedFilter: ProductFilter;
  setSelectedFilter: Dispatch<SetStateAction<ProductFilter>>;
  setPage: (page: number) => void;
  setInfiniteDisabled: (isInfiniteDisabled: boolean) => void;
  filters: DefaultProductFilters;
  handleSearchText: (text: string) => void;
  handleClearText: () => void;
  handleSearchCategories: (ids: number[]) => void;
  handleSearchTags: (ids: number[]) => void;
  getCleanArray: (ids: number[]) => number[];
}

export interface ProductFilterProviderProps {
  children: React.ReactNode;
}

export interface DefaultProductFilters {
  searchText: string;
  selectedCategories: number[];
  selectedTags: number[];
  tags: number[];
  page: number;
  isInfiniteDisabled: boolean;
}

export const defaultFilter: DefaultProductFilters = {
  searchText: "",
  selectedCategories: [0],
  selectedTags: [0],
  tags: [],
  page: 1,
  isInfiniteDisabled: false,
};
