import { Grid } from "@mui/material";
import FormInputView from "components/FormInput";
import Typography from "components/Typography";
import RecoverSectionTitleView from "pages/recoverPassword/sharedComponents/SectionTitle";
import { useTheme } from "styled-components";

import {
  StyledFormGrid,
  StyledFormHeaderContainer,
  StyledFormHeaderGrid,
} from "../../../styles";
import { RecoverPasswordFormProps } from "./types";

const RecoverPasswordForm = ({
  register,
  errors,
  control,
}: RecoverPasswordFormProps) => {
  const theme = useTheme();

  return (
    <StyledFormGrid>
      <RecoverSectionTitleView shouldAddSpace />
      <StyledFormHeaderContainer container direction="column">
        <StyledFormHeaderGrid item xs data-test="heading">
          <Typography
            variant={theme.typography.heading[1].regular}
            color={theme.color.grayscale[11]}
          >
            Esqueceu a senha? <b>Não se preocupe!</b>
          </Typography>
        </StyledFormHeaderGrid>
      </StyledFormHeaderContainer>

      <Grid container direction="column">
        <Grid item xs mb={5}>
          <Typography
            variant={theme.typography.body[2].regular}
            color={theme.color.grayscale[8]}
          >
            Informe o seu email cadastrado e enviaremos um código para
            recuperação de senha.
          </Typography>
        </Grid>
        <FormInputView
          field="email"
          title="Email"
          placeholder="Insira seu email cadastrado"
          register={register}
          control={control}
          errors={errors}
        />
      </Grid>
    </StyledFormGrid>
  );
};

export default RecoverPasswordForm;
