import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import { SupplierModel } from "commons/types/supplier.model";
import { formatCurrency } from "commons/utils/format";
import Chubbs from "components/Chubbs";
import HeaderWithButton from "components/HeaderWithButton";
import ButtonCart from "components/HeaderWithButton/ButtonCart";
import SelectInputMultipleView from "components/inputs/SelectInputMultiple";
import Searchbar from "components/Searchbar";
import ListHeaderBig from "components/surfaces/ListHeaderBig";
import { RouteComponentProps, useLocation } from "react-router";
import { StyledProductCardsGrid } from "theme/global.styles";

import { ProductFiltersContainer } from "../components/lists/styles";
import ProductCardView from "../components/ProductCard";
import { StyledIonContent } from "../styles";
import { useProductsPerSupplierController } from "./controller";
import { StyledCardColumn, StyledSelectItem } from "./styles";

const ProductsPerSupplierView = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const { state: stateSupplier } = useLocation<SupplierModel>();

  const {
    // Main state
    products,
    supplier,
    handleClickProduct,
    // Pagination state,
    hasFirstLoaded,
    isLoading,
    isInfiniteDisabled,
    handleLoadData,
    searchText,
    handleSearchText,
    handleClearText,
    categories,
    selectedCategories,
    handleSearchCategories,
  } = useProductsPerSupplierController(match.params.id, stateSupplier);

  if (!hasFirstLoaded) {
    return (
      <IonPage>
        <HeaderWithButton rightButton={<ButtonCart />} borderless />
        <StyledIonContent />
      </IonPage>
    );
  }

  return (
    <IonPage>
      <HeaderWithButton rightButton={<ButtonCart />} borderless />
      <StyledIonContent>
        <ListHeaderBig
          imageUrl={supplier.logo}
          title={supplier.fantasy_name}
          subtitle={`Pedido mínimo: ${formatCurrency(
            supplier.minimum_order_total
          )}`}
        />
        <ProductFiltersContainer container>
          <Searchbar
            value={searchText}
            onSearch={handleSearchText}
            onClear={handleClearText}
            placeholder="Buscar produto..."
          />
          <StyledSelectItem>
            <SelectInputMultipleView
              placeholder="Selecione uma ou mais categorias"
              selectedValues={selectedCategories}
              handleSelectedValues={(e) =>
                handleSearchCategories(e.target.value as number[])
              }
              items={categories}
            />
          </StyledSelectItem>
        </ProductFiltersContainer>
        <StyledProductCardsGrid>
          {isLoading ? (
            <IonLoading isOpen={isLoading} message={"Carregando..."} />
          ) : products.length ? (
            products.map((product) => (
              <StyledCardColumn key={product.id}>
                <ProductCardView
                  product={product}
                  handleClick={handleClickProduct(product)}
                />
              </StyledCardColumn>
            ))
          ) : (
            <Chubbs variant="not-found" />
          )}
        </StyledProductCardsGrid>
        <IonInfiniteScroll
          onIonInfinite={(e: CustomEvent<void>) => handleLoadData(e)}
          threshold="1%"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Carregando..."
          />
        </IonInfiniteScroll>
      </StyledIonContent>
    </IonPage>
  );
};

export default ProductsPerSupplierView;
