import { IonAvatar } from "@ionic/react";
import { Card, Grid } from "@mui/material";
import placeholderAvatar from "assets/placeholderAvatar.svg";
import { UserLoggedInModel } from "commons/types/user.model";
import Typography from "components/Typography";
import { FC } from "react";
import { useTheme } from "styled-components";
import { getFirstName } from "utils/functions";

import { accountHeaderStyles } from "./styles";

const AccountHeader: FC<{ user: UserLoggedInModel }> = ({ user }) => {
  const theme = useTheme();
  const { cardStyle, avatar, subText, textContainer } =
    accountHeaderStyles(theme);

  return (
    <Card sx={cardStyle}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <IonAvatar style={avatar}>
            <img src={user?.avatar || placeholderAvatar} alt="avatar" />
          </IonAvatar>
        </Grid>
        <Grid item sx={textContainer}>
          <Grid container>
            <Grid item mb={1}>
              <Typography
                variant={theme.typography.body[1].highlight}
                color={theme.color.primary.shade}
              >
                <b>Olá, {user && user.name ? getFirstName(user.name) : "-"}!</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant={theme.typography.body[4].regular}
                color={theme.color.grayscale[8]}
                style={subText}
              >
                Aqui você poderá ver e editar os dados vinculados a sua conta.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AccountHeader;
