import { ChangePasswordModel, UserUpdateModel } from "commons/types/user.model";

import { api } from "./api.service";

const MODULE_PATH = "user";

export default class UserService {
  static async getFullData(email: string) {
    const options = {
      params: {
        email,
      },
    };
    return api.get(`${MODULE_PATH}/full-data/`, options);
  }
  static async updateUserData(id: number, data: UserUpdateModel) {
    return api.put(`${MODULE_PATH}/${id}/`, data);
  }
  static async updateUserPassword(id: number, data: ChangePasswordModel) {
    return api.put(`${MODULE_PATH}/${id}/change-password/`, data);
  }
}
