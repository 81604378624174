import { IonPage } from "@ionic/react";
import SimpleHeader from "components/SimpleHeader";
import { StyledIonContent } from "pages/stores/styles";

import RecoverButtonView from "../sharedComponents/RecoverButton";
import { StyledMainGrid, StyledFooterGrid } from "../styles";
import { useRecoverPasswordController } from "./controller";
import RecoverPasswordForm from "./localComponents/RecoverPasswordForm";

const RecoverPasswordView: React.FC = () => {
  const { control, register, handleSubmit, errors, onSubmit, isLoading } =
    useRecoverPasswordController();

  return (
    <IonPage>
      <SimpleHeader />
      <StyledIonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledMainGrid container>
            <RecoverPasswordForm
              register={register}
              errors={errors}
              control={control}
            />
          </StyledMainGrid>
        </form>

        <StyledFooterGrid container>
          <RecoverButtonView
            buttonText="Enviar email"
            onSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />
        </StyledFooterGrid>
      </StyledIonContent>
    </IonPage>
  );
};

export default RecoverPasswordView;
