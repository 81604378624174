import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import { formatCurrency } from "commons/utils/format";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { ProductDetailsButton, productInputStyles } from "./styles";
import { ProductQuantityInputProps } from "./types";

const ProductQuantityInputView = ({
  title,
  wasAdded,
  price,
  quantity,
  multiple,
  position,
  itemsQuantity,
  handleOnAdd,
  handleOnRemove,
  handleApplyDiscount,
}: ProductQuantityInputProps) => {
  const theme = useTheme();
  const isLast = position >= itemsQuantity - 1;

  const styles = productInputStyles(theme, isLast);
  return (
    <Grid container direction="column" style={styles.mainContainer}>
      <Grid item xs style={styles.title}>
        <Typography
          variant={theme.typography.body[2].regular}
          color={theme.color.grayscale[7]}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs mb={1}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={2} sm={1.7} textAlign="start">
            <ProductDetailsButton disabled={!wasAdded} onClick={handleOnRemove}>
              -
            </ProductDetailsButton>
          </Grid>
          <Grid item xs>
            <Card style={styles.input}>
              <Grid container>
                <Grid item xs>
                  <Typography
                    variant={theme.typography.body[2].regular}
                    color={theme.color.grayscale[7]}
                  >
                    {quantity || "00"}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={2} sm={1.7} textAlign="end">
            <ProductDetailsButton onClick={handleOnAdd}>+</ProductDetailsButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs style={styles.price}>
        <Typography
          variant={theme.typography.body[3].regular}
          color={theme.color.grayscale[10]}
        >
          Preço por unidade: {formatCurrency(handleApplyDiscount(price))}
        </Typography>
      </Grid>
      <Grid item xs style={styles.multiple}>
        <Typography
          variant={theme.typography.body[4].regular}
          color={theme.color.grayscale[7]}
        >
          (múltiplo: {multiple})
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductQuantityInputView;
