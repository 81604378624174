import { MaterialStyles } from "commons/types/generics/materialStyles";
import { DefaultTheme } from "styled-components";

export const listHeaderBigStyles = (theme: DefaultTheme): MaterialStyles => ({
  containerStyle: {
    borderBottom: `2px solid ${theme.color.secondary.default}`,
    background: theme.color.neutralLight.default,
  },
  cardStyle: {
    display: "flex",
    border: "none",
    boxShadow: "none",
    margin: "10px 20px",
    backgroundColor: "transparent",
  },
  imageStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
    borderRadius: "8px",
  },
  titleStyle: {
    display: "block",
    color: theme.color.grayscale[10],
    lineHeight: "1.5rem",
  },
  subtitleStyle: {
    display: "block",
    color: theme.color.grayscale[8],
    lineHeight: "1.25rem",
  },
  textAreaSyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "13px",
    maxWidth: "60%",
  },
});
