import { MaterialStyles } from "commons/types/generics/materialStyles";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import styled, { useTheme } from "styled-components";

export const orderDetailsStyles = (isTablet: boolean): MaterialStyles => ({
  detailsContainer: {
    padding: "20px 20px 0 40px",
  },
  footer: {
    padding: "20px",
  },
  buttonContainer: {
    paddingTop: isTablet ? "unset" : "20px",
    flexDirection: isTablet ? "row-reverse" : "row",
  },
  totalLabel: {
    textAlign: `${isTablet ? "left" : "left"}`,
    paddingLeft: `${isTablet ? "20px" : "unset"}`,
  },
  totalPrice: {
    textAlign: `${isTablet ? "left" : "right"}`,
  },
  progress: { color: "white" },
});

export const CartSubmitButton = styled(ButtonPrimaryFilled)(() => {
  const theme = useTheme();
  return {
    ":disabled": {
      backgroundColor: `${theme.color.grayscale[4]} !important`,
      color: "white !important",
      border: "none",
    },
  };
});
