import { Card, Grid, TextFieldProps } from "@mui/material";
import { FormInputProps } from "components/FormInput/types";
import Typography from "components/Typography";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useTheme } from "styled-components";
import { globalStyles } from "theme/global.styles";

import FormErrorMessageView from "../FormErrorMessage";
import { FormCustomField, formInputsStyles } from "../FormInput/styles";
const { fullWidth } = globalStyles();

const StyledTextField = (props: TextFieldProps) => (
  <FormCustomField {...props} style={fullWidth} />
);

const FormNumberInputView = ({
  field: formField,
  control,
  title,
  placeholder,
  errors,
  format,
  onChange,
}: FormInputProps) => {
  const theme = useTheme();
  const styles = formInputsStyles(theme);

  return (
    <Grid container direction="column" style={styles.inputContainner}>
      <Grid item xs mb={1}>
        <Typography
          variant={theme.typography.body[2].regular}
          color={theme.color.grayscale[8]}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs style={styles.cardContainer}>
        <Card>
          <Controller
            render={({ field }) => (
              <NumberFormat
                customInput={StyledTextField}
                format={format}
                placeholder={placeholder}
                variant="outlined"
                value={field.value}
                onValueChange={(v) => field.onChange(v.value)}
                inputProps={{
                  style: styles.input,
                }}
                error={!!errors[formField]}
                onChange={onChange}
              />
            )}
            control={control}
            name={formField}
          />
        </Card>
      </Grid>

      {!!errors[formField] ? (
        <FormErrorMessageView errors={errors} field={formField} />
      ) : null}
    </Grid>
  );
};

export default FormNumberInputView;
