import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import { DefaultTheme, useTheme } from "styled-components";

export const ProductInputField = styled(TextField)(() => {
  const theme = useTheme();

  return {
    "& input::placeholder": {
      color: theme.color.grayscale[7],
      opacity: 1,
      ...theme.typography.body[2].regular,
    },
  };
});

export const ProductDetailsButton = styled(ButtonPrimaryFilled)(() => {
  const theme = useTheme();
  return {
    minWidth: "unset",
    width: "46px",
    height: 46,
    ":disabled": {
      backgroundColor: theme.color.grayscale[2],
      color: "white",
      border: "none",
    },
  };
});

export const productInputStyles = (
  theme: DefaultTheme,
  isLast?: boolean
): MaterialStyles => ({
  mainContainer: {
    textAlign: "center",
    borderBottom: isLast ? "unset" : `1px solid ${theme.color.grayscale[4]}`,
    paddingBottom: 10,
    marginBottom: 10,
  },
  title: {
    marginBottom: 5,
  },
  input: {
    padding: 12,
    height: 46,
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
  },
  price: {
    margin: 5,
  },
  multiple: {
    marginBottom: 5,
  },
});
