import { api } from "./api.service";

const MODULE_PATH = "product";

interface StoreGetMany {
  page: number;
  search?: string;
  microregions: Array<number>;
  categories?: Array<number>;
  tags: Array<number>;
  supplier?: number;
}

export default class ProductService {
  static async getMany({
    page = 1,
    search = "",
    supplier,
    microregions,
    tags,
    categories,
  }: StoreGetMany) {
    const options = {
      params: {
        page,
        microregions,
        search,
        categories,
        tags,
        supplier,
      },
    };
    return api.get(`${MODULE_PATH}/`, options);
  }

  static async getOne(id: string) {
    return api.get(`${MODULE_PATH}/${id}/`);
  }

  static async getCategories(search?: string) {
    const options = {
      params: {
        search,
      },
    };
    return api.get(`product-category/`, options);
  }
  static async getTags(search?: string) {
    const options = {
      params: {
        search,
      },
    };
    return api.get(`product-tag/`, options);
  }

  static async getSkus(
    productId: number,
    attributeId: number,
    attributeValueId?: number[] | null
  ) {
    const options = {
      params: {
        product_id: productId,
        attribute_id: attributeId,
        attribute_values_id: attributeValueId,
      },
    };
    return api.get(`${MODULE_PATH}/skus/`, options);
  }
}
