import styledMui from "@emotion/styled";
import { IonContent } from "@ionic/react";
import { Grid } from "@mui/material";
import styled, { useTheme } from "styled-components";

export const StyledIonContent = styled(IonContent)`
  --background: ${({ theme }) => theme.color.grayscale[1]};
`;

export const FilterContainer = styledMui(Grid)`
  text-align: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  @media (min-width: 734px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const CardsContainer = styledMui(Grid)`
  align-items: flex-start;
  text-align: center;
  padding: 18px;
  padding-bottom: 80px;
  @media (min-width: 734px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const ButtonContainer = styledMui(Grid)`
  background: ${() => useTheme().color.neutralLight.default};
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  padding: 16px 20px;
  text-align: center;
  transform: translate(-50%, 0);
  position: fixed;
  left: 50%;
  bottom: 0;
`;
