import { IonFooter, IonPage } from "@ionic/react";
import { CircularProgress, Grid } from "@mui/material";
import BackButtonHeader from "components/BackButtonHeader";
import ButtonPrimaryFilled from "components/ButtonPrimaryFilled";
import ButtonPrimaryOutlined from "components/ButtonPrimaryOutlined";
import DialogWithButtons from "components/DialogWithButtons";
import Typography from "components/Typography";
import { StyledIonContent } from "pages/microregions/styles";

import ChangePasswordForm from "./components/ChangePasswordForm";
import { useChangePasswordController } from "./controller";
import { changePasswordStyles } from "./styles";

const ChangePasswordView: React.FC = () => {
  const {
    theme,
    isTablet,
    isDialogOpen,
    handleGoBack,
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    isLoading,
    handleCloseDialogResetPassword,
  } = useChangePasswordController();

  const { footerStyle, progress } = changePasswordStyles();

  return (
    <IonPage>
      <DialogWithButtons
        showIcon
        hasAutoSizedBtns
        isOpen={isDialogOpen}
        onClose={handleCloseDialogResetPassword}
        secondaryButtonText="Continuar"
        onClickSecondaryButton={handleCloseDialogResetPassword}
        message={
          <>
            <Typography
              variant={theme.typography.heading[2].highlight}
              color={theme.color.success.default}
            >
              <b>Senha alterada com sucesso!</b>
            </Typography>
            <br />
            <Typography
              variant={theme.typography.body[2].regular}
              color={theme.color.grayscale[8]}
            >
              Para manter a segurança, vamos pedir para você entrar na conta
              novamente.
            </Typography>
          </>
        }
      />
      <BackButtonHeader />
      <StyledIonContent>
        <Grid item mx={3} my={4}>
          <Typography
            variant={theme.typography.subheading[3].regular}
            color={theme.color.grayscale[7]}
          >
            Alteração de senha
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent={isTablet ? "center" : "normal"}
            width="100%"
          >
            <ChangePasswordForm
              register={register}
              errors={errors}
              control={control}
              isTablet={isTablet}
            />
          </Grid>
        </form>
      </StyledIonContent>
      <IonFooter collapse="fade" style={footerStyle}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          py={2}
          px={3}
          spacing={1}
          direction={isTablet ? "row-reverse" : "row"}
        >
          <Grid item xs={12} sm={6} textAlign={isTablet ? "start" : "center"}>
            <ButtonPrimaryFilled
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress style={progress} size={25} />
              ) : (
                "Alterar senha"
              )}
            </ButtonPrimaryFilled>
          </Grid>
          <Grid item xs={12} sm={6} textAlign={isTablet ? "end" : "center"}>
            <ButtonPrimaryOutlined onClick={handleGoBack}>
              Cancelar
            </ButtonPrimaryOutlined>
          </Grid>
        </Grid>
      </IonFooter>
    </IonPage>
  );
};

export default ChangePasswordView;
