import { IonPage } from "@ionic/react";
import SimpleHeader from "components/SimpleHeader";
import { StyledIonContent } from "pages/stores/styles";

import RecoverButtonView from "../sharedComponents/RecoverButton";
import { StyledMainGrid } from "../styles";
import { useConfirmRecoverPasswordController } from "./controller";
import ConfirmRecoverPasswordForm from "./localComponents/ConfirmRecoverPasswordForm";

const ConfirmRecoveryPasswordView: React.FC = () => {
  const { control, register, handleSubmit, errors, onSubmit, isLoading } =
    useConfirmRecoverPasswordController();

  return (
    <IonPage>
      <SimpleHeader />
      <StyledIonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledMainGrid container>
            <ConfirmRecoverPasswordForm
              register={register}
              errors={errors}
              control={control}
            />
          </StyledMainGrid>
        </form>
        <RecoverButtonView
          buttonText="Redefinir senha"
          onSubmit={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </StyledIonContent>
    </IonPage>
  );
};

export default ConfirmRecoveryPasswordView;
