import { MaterialStyles } from "commons/types/generics/materialStyles";

export const recoverButtonStyles = (): MaterialStyles => ({
  button: {
    textAlign: "center",
    position: "absolute",
    bottom: "10vh",
    padding: "0 20px",
    width: "100%",
  },
});
