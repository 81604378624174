import { useCart } from "contexts/cart";
import { SelectedPaymentMethod } from "contexts/cart/types";
import { useCallback, useEffect, useMemo, useState } from "react";

import { SupplierCardControllerProps } from "./types";

const useSupplierCardController = ({
  supplier,
  handleSetSupplierPaymentMethod,
}: SupplierCardControllerProps) => {
  const {
    cartData,
    handleChangeSupplierPaymentMethod,
    selectedSuppliers,
    paymentMethodBundle,
  } = useCart();
  const [didInitiate, setDidInitiate] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<SelectedPaymentMethod[]>(
    []
  );

  const supplierPaymentMethodId = useMemo(() => {
    return supplier.selectedPaymentMethod?.id;
  }, [supplier.selectedPaymentMethod?.id]);

  const initPaymentMethods = useCallback(async () => {
    try {
      if (
        cartData.paymentMethodBundle &&
        cartData.paymentMethodBundle[supplier.id]
      ) {
        const values = Object.values(cartData.paymentMethodBundle[supplier.id]);
        if (values.length) {
          setPaymentMethods(values);
        }
      }
    } catch (error) {
      throw error;
    }
  }, [cartData.paymentMethodBundle]);

  // Init payment method select options
  useEffect(() => {
    if (cartData?.paymentMethodBundle) {
      initPaymentMethods();
    }
  }, [cartData.paymentMethodBundle]);

  // Init payment method select value
  useEffect(() => {
    if (supplierPaymentMethodId && !didInitiate && paymentMethods.length) {
      handleSetSupplierPaymentMethod(supplier.id, supplierPaymentMethodId);
      if (!cartData.currentOrder) {
        handleChangeSupplierPaymentMethod(
          selectedSuppliers,
          supplier.id,
          supplierPaymentMethodId,
          paymentMethodBundle
        );
      }
      setDidInitiate(true);
    } else if (cartData.cartId && !didInitiate && paymentMethods.length) {
      handleSetSupplierPaymentMethod(
        supplier.id,
        cartData.selectedPaymentMethod?.id
      );
      if (!cartData.currentOrder) {
        if (cartData.selectedPaymentMethod?.id) {
          handleChangeSupplierPaymentMethod(
            selectedSuppliers,
            supplier.id,
            cartData.selectedPaymentMethod.id,
            paymentMethodBundle
          );
        }
      }
      setDidInitiate(true);
    }
  }, [supplierPaymentMethodId, paymentMethods.length]);

  return { paymentMethods };
};
export default useSupplierCardController;
