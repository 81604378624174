import { Grid } from "@mui/material";
import FormPasswordInputView from "components/FormPasswordInput";
import Typography from "components/Typography";
import { useTheme } from "styled-components";

import { changePasswordStyles } from "../../styles";
import { ChangePasswordFormProps } from "./types";

const ChangePasswordForm = ({
  register,
  errors,
  control,
  isTablet,
}: ChangePasswordFormProps) => {
  const theme = useTheme();
  const { header } = changePasswordStyles();

  return (
    <Grid mx={3} width={isTablet ? 488 : "100%"}>
      <Grid container direction="column" mb={3}>
        <Grid item sx={header}>
          <Typography
            variant={theme.typography.heading[2].regular}
            color={theme.color.grayscale[9]}
          >
            Preencha os campos abaixo para <b>alterar sua senha</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" mb={1}>
        <FormPasswordInputView
          field="old_password"
          title="Senha atual"
          placeholder="Digite sua senha"
          register={register}
          control={control}
          errors={errors}
        />

        <Grid my={2.3} />

        <FormPasswordInputView
          field="new_password"
          title="Nova senha"
          placeholder="Digite uma nova senha"
          register={register}
          control={control}
          errors={errors}
        />
        <FormPasswordInputView
          field="confirm_password"
          title="Confirmação de senha"
          placeholder="Repita sua senha"
          register={register}
          control={control}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
};

export default ChangePasswordForm;
