import { IonIcon, IonToolbar } from "@ionic/react";
import styled from "styled-components";

import { BackButtonLinkProps } from "./types";

export const StyledIonToolbar = styled(IonToolbar)`
  padding: 4px 4px;
  margin-top: 10px;
`;

export const StyledBorderlessIonToolbar = styled(StyledIonToolbar)`
  --border-width: 0 !important;
`;

export const StyledIonIcon = styled(IonIcon)`
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 8px;
`;

export const BackButtonLink = styled.a<BackButtonLinkProps>`
  font-size: ${({ variant }) => variant.fontSize}px;
  font-weight: ${({ variant }) => variant.fontWeight};
  line-height: ${({ variant }) => variant.lineHeight}px;
  color: ${({ color }) => color};
  text-decoration: none;
`;
