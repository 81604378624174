import { ORDER_STATUS } from "commons/types/order.model";

export enum SEGMENT_OPTIONS {
  DRAFT = "draft",
  OPEN = "open",
  CLOSED = "closed",
}

export const openOrderStatus = [
  ORDER_STATUS.SENT_TO_THE_OFFICE,
  ORDER_STATUS.IN_PROGRESS,
];

export const closedOrderStatus = [
  ORDER_STATUS.CANCELED,
  ORDER_STATUS.ORDER_IN_SUPPLIER,
  ORDER_STATUS.BILLED,
];

export interface CardItem {
  id: number;
  store: string;
  order?: number;
  suppliers: string[];
  totalValue?: number;
  status?: ORDER_STATUS;
  logo: string;
  isDraft?: boolean;
  email: string;
}
