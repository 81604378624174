import { Grid } from "@mui/material";
import { useProductFilter } from "contexts/product";
import { ProductFilter } from "contexts/product/types";
import {
  earthOutline,
  gridOutline,
  pricetagOutline,
  ribbonOutline,
} from "ionicons/icons";

import ProductFilterButtonView from "../ProductFilterButton";
import {
  ProductFiltersContainer,
  productFilterContainerStyles,
} from "./styles";

const ProductFiltersView = () => {
  const { selectedFilter, setSelectedFilter } = useProductFilter();
  const styles = productFilterContainerStyles();

  return (
    <ProductFiltersContainer>
      <Grid style={styles.flex}>
        <Grid item xs>
          <ProductFilterButtonView
            label="Fábricas"
            icon={ribbonOutline}
            filter={ProductFilter.SUPPLIERS}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        </Grid>

        <Grid item xs>
          <ProductFilterButtonView
            label="Categorias"
            icon={gridOutline}
            filter={ProductFilter.CATEGORIES}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        </Grid>

        <Grid item xs>
          <ProductFilterButtonView
            label="Tags"
            icon={pricetagOutline}
            filter={ProductFilter.TAGS}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        </Grid>

        <Grid item xs>
          <ProductFilterButtonView
            label="Todos"
            icon={earthOutline}
            filter={ProductFilter.ALL}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        </Grid>
      </Grid>
    </ProductFiltersContainer>
  );
};

export default ProductFiltersView;
