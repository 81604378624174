import { useIonAlert, useIonLoading } from "@ionic/react";
import {
  PRODUCTS_LOAD_ERROR,
  PRODUCT_CATEGORIES_LOAD_ERROR,
  SUPPLIER_LOAD_ERROR,
} from "commons/constants/message.constants";
import {
  ProductCategoryModel,
  ProductModel,
} from "commons/types/product.model";
import { SupplierModel } from "commons/types/supplier.model";
import { useAuth } from "contexts/auth";
import { useCart } from "contexts/cart";
import { useConfiguration } from "contexts/configuration";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProductService from "services/product.service";
import SupplierService from "services/supplier.service";

export const useProductsPerSupplierController = (
  supplierId: string,
  stateSupplier?: SupplierModel
) => {
  // Outer hooks
  const auth = useAuth();
  const history = useHistory();
  const { presentError } = useConfiguration();
  const { showError } = useCart();

  // Main state
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [supplier, setSupplier] = useState<SupplierModel>({} as SupplierModel);
  // Search & pagination state
  const [hasFirstLoaded, setFirstLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [categories, setCategories] = useState<ProductCategoryModel[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([0]);
  const [scrollEvent, setScrollEvent] = useState<CustomEvent<void>>();
  // Ionic Hooks
  const [present, dismiss] = useIonLoading();

  const loadSuppliers = useCallback(async () => {
    try {
      if (stateSupplier?.id && stateSupplier?.logo) {
        setSupplier(stateSupplier);
      } else {
        const response = await SupplierService.getOne(supplierId);
        const data: SupplierModel = response?.data;
        setSupplier(data);
      }
    } catch (error) {
      showError(SUPPLIER_LOAD_ERROR);
    }
  }, [supplierId]);

  const loadProducts = useCallback(async () => {
    try {
      if (isInfiniteDisabled || !auth.user) return;
      const searchParams = {
        page,
        search: searchText,
        supplier: Number(supplierId),
        microregions: auth.user.microregions.map((region) => region.id),
        categories: getCleanArray(selectedCategories),
        tags: [],
      };
      const response = await ProductService.getMany(searchParams);
      const _products: ProductModel[] = response.data?.results;

      let canSearch = true;

      if (page === 1) {
        setProducts(_products);
        canSearch = _products.length < (response?.data?.count || 0);
      } else if (_products.length && page != 1) {
        setProducts((oldProducts) => oldProducts.concat(_products));
        canSearch =
          products.concat(_products).length < (response?.data?.count || 0);
      }
      if (!canSearch) {
        setIsInfiniteDisabled(true);
      }
      if (canSearch && scrollEvent) {
        // Complete the scroll event, so que pagination can work
        (scrollEvent.target as HTMLIonInfiniteScrollElement).complete();
      }
    } catch (error) {
      presentError(error?.response?.data?.detail, PRODUCTS_LOAD_ERROR);
    } finally {
      setIsLoading(false);
      setFirstLoaded(true);
    }
  }, [page, searchText, selectedCategories, isInfiniteDisabled, setProducts]);

  const loadProductCategories = useCallback(async () => {
    try {
      const response = await ProductService.getCategories();

      setCategories(response.data || []);
    } catch (error) {
      showError(PRODUCT_CATEGORIES_LOAD_ERROR);
    } finally {
      dismiss();
    }
  }, [setCategories]);

  const handleSearchText = (text: string) => setSearchText(() => text);

  const handleClearText = () => setSearchText(() => "");

  const getCleanArray = (categoryIds: number[]) => {
    return categoryIds?.filter((id) => !!id);
  };

  const handleSearchCategories = (categoryIds: number[]) => {
    const categories = getCleanArray(categoryIds);
    setSelectedCategories(() => (categories.length ? categories : [0]));
  };

  const handleClickProduct = (product: ProductModel) => () => {
    history.push(`/stores/supplier/${supplier.id}/product/${product.id}`);
  };

  const handleLoadData = useCallback(
    (event: CustomEvent<void>) => {
      setScrollEvent(event);
      setPage(page + 1);
    },
    [page]
  );

  useEffect(() => {
    if (isLoading) {
      present({
        message: "Carregando...",
        duration: 30000,
      });
    } else {
      dismiss();
    }
  }, [isLoading]);

  useEffect(() => {
    loadSuppliers();
  }, [loadSuppliers]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts, page, searchText, selectedCategories, isInfiniteDisabled]);

  useEffect(() => {
    loadProductCategories();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsInfiniteDisabled(false);
  }, [searchText, selectedCategories]);

  return {
    // Main state
    supplier,
    products,
    handleClickProduct,
    // Pagination state,
    isLoading,
    hasFirstLoaded,
    isInfiniteDisabled,
    handleLoadData,
    page,
    searchText,
    handleSearchText,
    handleClearText,
    categories,
    selectedCategories,
    handleSearchCategories,
  };
};
