import { IonGrid } from "@ionic/react";
import styled from "styled-components";

const StyledSubheader = styled(IonGrid)`
  padding: 33px 35px 53px 20px;
  margin-bottom: -28px;
  font-size: 24px;
  @media (min-width: 700px) {
    text-align: center;
  }
`;

export const StyledSubheaderLight = styled(StyledSubheader)`
  background-color: ${({ theme }) => theme.color.grayscale[1]};
  color: ${({ theme }) => theme.color.grayscale[10]};
  padding: 33px 35px 8px 20px;
  margin-bottom: 0px;
  @media (min-width: 700px) {
    margin-bottom: 10px;
  }
`;

export const StyledSubheaderDark = styled(StyledSubheader)`
  background-color: ${({ theme }) => theme.color.neutralDark.default};
  color: ${({ theme }) => theme.color.neutralLight.default};
`;
