import Searchbar from "components/Searchbar";
import { Fragment } from "react";

import ListProductItemsView from "../ListProductItems";
import { ProductFiltersContainer } from "../styles";
import { ListAllProductsProps } from "./types";

/**
 * Renders a list of products that can be filtered by name or code
 */
const ListAllProductsView = ({
  products,
  isLoading,
  handleClickDetails,
  searchText,
  handleSearchText,
  handleClearText,
}: ListAllProductsProps) => {
  return (
    <Fragment>
      <ProductFiltersContainer container>
        <Searchbar
          value={searchText}
          onSearch={handleSearchText}
          onClear={handleClearText}
          placeholder="Buscar produto..."
        />
      </ProductFiltersContainer>
      <ListProductItemsView
        showItems={true}
        products={products}
        isLoading={isLoading}
        handleClickDetails={handleClickDetails}
      />
    </Fragment>
  );
};

export default ListAllProductsView;
