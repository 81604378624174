import { useIonAlert } from "@ionic/react";
import { INVALID_EMAIL } from "commons/constants/form.message.constants";
import { UNEXPECTED_ERROR } from "commons/constants/message.constants";
import { useMemo, useState } from "react";
import { useLocation } from "react-router";
import AuthService from "services/auth.service";

export const useResendRecoverPasswordController = () => {
  const [presentAlert] = useIonAlert();
  const { state } = useLocation<{ email: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const censoredEmail = useMemo(() => {
    const { email } = state;
    const censorChar = "*";
    const separatorChar = "@";
    const separatorIndex = email.indexOf(separatorChar);
    const secondPart = email.substring(2, separatorIndex - 1);
    const censored = email.replace(
      secondPart,
      censorChar.repeat(secondPart.length)
    );

    return censored;
  }, [state.email]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const email = state.email;
      if (!email) throw new Error(INVALID_EMAIL);

      await AuthService.recoverPassword(email);
    } catch (error) {
      presentAlert({
        header: "Erro",
        message: UNEXPECTED_ERROR,
        buttons: ["OK"],
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
    isLoading,
    censoredEmail,
  };
};
