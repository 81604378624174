import styled from "@emotion/styled";
import { MaterialStyles } from "commons/types/generics/materialStyles";
import { useTheme } from "styled-components";

export const StepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ ownerState }) => {
  const theme = useTheme();

  return {
    color: theme.color.grayscale[2],
    display: "flex",
    height: 22,
    alignItems: "center",
    ...((ownerState.active || ownerState.completed) && {
      color: theme.color.primary.default,
    }),
    "& .custom-step-icon-circle": {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.color.grayscale[2],
    },
    "& .custom-step-icon-circle.active": {
      width: 24,
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.color.primary.default,
    },
    "& .custom-step-icon-circle.checked": {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.color.primary.default,
    },
  };
});

export const customStepperStyles = (): MaterialStyles => ({
  stepperText: { textAlign: "center", marginTop: "5px" },
});
