/* eslint-disable @typescript-eslint/naming-convention */
import { IonIcon } from "@ionic/react";
import { Card, Grid, IconButton, InputAdornment } from "@mui/material";
import Typography from "components/Typography";
import { useTheme } from "styled-components";
import { globalStyles } from "theme/global.styles";

import FormErrorMessageView from "../FormErrorMessage";
import { formInputsStyles, FormCustomField } from "./styles";
import { FormInputProps } from "./types";

const FormInputView = ({
  field,
  title,
  placeholder,
  register,
  type = "text",
  errors,
  endIcon,
  endBtnAction,
  ...props
}: FormInputProps) => {
  const theme = useTheme();
  const { fullWidth } = globalStyles();
  const styles = formInputsStyles(theme);

  return (
    <Grid container direction="column" style={styles.inputContainner}>
      <Grid item xs mb={1}>
        <Typography
          variant={theme.typography.body[2].regular}
          color={theme.color.grayscale[8]}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs style={styles.cardContainer}>
        <Card>
          <FormCustomField
            {...props}
            type={type}
            placeholder={placeholder}
            {...register(field)}
            style={fullWidth}
            inputProps={{
              style: (props.rows || 1) > 1 ? fullWidth : styles.input,
            }}
            InputProps={{
              endAdornment: endIcon ? (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={endBtnAction}>
                    <IonIcon
                      icon={endIcon}
                      color={!!errors[field] ? "danger" : undefined}
                    />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            error={!!errors[field]}
            data-test={field}
          />
        </Card>
      </Grid>

      {!!errors[field] ? (
        <FormErrorMessageView errors={errors} field={field} />
      ) : null}
    </Grid>
  );
};

export default FormInputView;
